import { DraftStrategyChanges, Strategy } from 'shared/src/types';
import { MediaPlan, MediaPlanChanges, MediaPlanUpdate } from 'shared/src/media-buy-types';
import { LineItem, NewLineItemDraft, PartialLineItem } from 'shared/src/line-item-types';

export type MediaPlanDirty = {
  [K in keyof MediaPlan]?: { old: MediaPlan[K]; new: MediaPlan[K] | undefined } | null;
};

export type UpdatedMediaPlanState = {
  type: 'update';
  original: MediaPlan;
  dirty: MediaPlanDirty;
};

export type NewMediaPlanState = {
  type: 'new';
};

export type UnchangedMediaPlanState = {
  type: 'unchanged';
};

export type MediaPlanChangeState =
  | UpdatedMediaPlanState
  | NewMediaPlanState
  | UnchangedMediaPlanState;

export type CombinedMediaPlan = MediaPlan & {
  state: MediaPlanChangeState;
};

export type LineItemDirty = {
  [K in keyof LineItem]?: { old: LineItem[K]; new: LineItem[K] | undefined } | null;
};

export type UpdatedLineItemState = {
  type: 'update';
  original: LineItem;
  dirty: LineItemDirty;
};

export type NewLineItemState = {
  type: 'new';
};

export type UnchangedLineItemState = {
  type: 'unchanged';
};

export type LineItemChangeState = UpdatedLineItemState | NewLineItemState | UnchangedLineItemState;

export type CombinedLineItem = PartialLineItem & {
  state: LineItemChangeState;
  media_plans: Array<CombinedMediaPlan>;
};

export type CombinedStrategy = Omit<Strategy, 'line_items'> & {
  line_items: Array<CombinedLineItem>;
  changes: DraftStrategyChanges;
};

export function combineStrategy(
  strategy: Strategy,
  changes: DraftStrategyChanges
): CombinedStrategy {
  const updatedLineItems = strategy.line_items.map(lineItem =>
    combineLineItemUpdate(lineItem, changes)
  );
  const newLineItems = Object.values(changes.line_items).filter(item => item.type === 'new');

  const orderedLineItems = orderUpdatedItemsWithNewItems(
    updatedLineItems,
    newLineItems,
    changes.media_plans
  );

  return {
    ...strategy,
    line_items: orderedLineItems,
    changes
  };
}

export function orderUpdatedItemsWithNewItems(
  updatedItems: CombinedLineItem[],
  newLineItems: NewLineItemDraft[],
  mediaBuys: DraftStrategyChanges['media_plans']
) {
  let sortedItems = [...updatedItems];

  for (const changeItem of newLineItems) {
    const newLineItem = mapNewLineItem(changeItem, mediaBuys);

    // If the position of the new item is not defined or is a 'bottom' type, add it to the end of the list
    if (!changeItem.position || changeItem.position?.type === 'bottom') {
      sortedItems.push(newLineItem);
    } else {
      // Otherwise find the item that the new item should be positioned after
      const { itemId } = changeItem.position;

      // look for item duplicated to position immediately beneath it
      const itemIdIdx = sortedItems.findIndex(item => item.id === itemId);

      if (itemIdIdx > -1) {
        // item was singularly duplicated, so position immediately beneath duplicated item
        sortedItems = sortedItems.toSpliced(itemIdIdx + 1, 0, newLineItem);
      } else {
        // duplicate index was not found, line item may have been deleted, so put at bottom of list
        sortedItems.push(newLineItem);
      }
    }
  }

  return sortedItems;
}

function combineLineItemUpdate(
  lineItem: LineItem,
  changes: DraftStrategyChanges
): CombinedLineItem {
  const update = changes.line_items[lineItem.id];
  if (update && update.type === 'new')
    throw new Error('A server line item cannot also be created locally');
  return {
    ...lineItem,
    ...(update ? update.data : {}),
    state: update
      ? { type: 'update', original: lineItem, dirty: calcDirtyLineItems(lineItem, update.data) }
      : { type: 'unchanged' },
    media_plans: [
      ...lineItem.media_plans?.map(plan => combineMediaPlan(plan, changes)),
      ...mapNewMediaPlans(lineItem.id, changes.media_plans)
    ]
  };
}

function calcDirtyLineItems(lineItem: LineItem, update: PartialLineItem): LineItemDirty {
  // tactic and unit_price_type can be reset to undefined

  return {
    ...('name' in update ? { name: { old: lineItem.name, new: update.name } } : {}),
    ...('channel' in update ? { channel: { old: lineItem.channel, new: update.channel } } : {}),
    ...('tactic' in update ? { tactic: { old: lineItem.tactic, new: update.tactic } } : {}),
    ...('unit_price_type' in update
      ? { unit_price_type: { old: lineItem.unit_price_type, new: update.unit_price_type } }
      : {}),
    ...('geo' in update ? { geo: { old: lineItem.geo, new: update.geo } } : {}),
    ...('targeting' in update
      ? { targeting: { old: lineItem.targeting, new: update.targeting } }
      : {}),
    ...('ad_formats' in update
      ? { ad_formats: { old: lineItem.ad_formats, new: update.ad_formats } }
      : {}),
    ...('audience' in update ? { audience: { old: lineItem.audience, new: update.audience } } : {}),
    ...('price' in update ? { price: { old: lineItem.price, new: update.price } } : {}),
    ...('target_margin' in update
      ? { target_margin: { old: lineItem.target_margin, new: update.target_margin } }
      : {}),
    ...('unit_price' in update
      ? { unit_price: { old: lineItem.unit_price, new: update.unit_price } }
      : {}),
    ...('start_date' in update
      ? { start_date: { old: lineItem.start_date, new: update.start_date } }
      : {}),
    ...('end_date' in update ? { end_date: { old: lineItem.end_date, new: update.end_date } } : {}),
    ...('pacing_type' in update
      ? { pacing_type: { old: lineItem.pacing_type, new: update.pacing_type } }
      : {}),
    ...('pacing_details' in update
      ? { pacing_details: { old: lineItem.pacing_details, new: update.pacing_details } }
      : {}),
    ...('media_traders' in update
      ? { media_traders: { old: lineItem.media_traders, new: update.media_traders } }
      : {}),
    ...('media_platforms' in update
      ? { media_platforms: { old: lineItem.media_platforms, new: update.media_platforms } }
      : {}),
    ...('is_deleted' in update
      ? { is_deleted: { old: lineItem.is_deleted, new: update.is_deleted } }
      : {})
  };
}

function mapNewLineItem(
  newLineItem: NewLineItemDraft,
  mediaPlans: DraftStrategyChanges['media_plans']
): CombinedLineItem {
  return {
    ...newLineItem.data,
    state: { type: 'new' },
    media_plans: mapNewMediaPlans(newLineItem.data.id, mediaPlans)
  };
}

function mapNewMediaPlans(
  lineItemId: string,
  mediaPlans: Record<string, MediaPlanChanges>
): CombinedMediaPlan[] {
  return Object.values(mediaPlans)
    .filter(change => change.type === 'new')
    .filter(change => change.data.line_item_id === lineItemId)
    .map(newMediaPlan => ({ ...newMediaPlan.data, state: { type: 'new' }, dirty: {} }));
}

function combineMediaPlan(mediaPlan: MediaPlan, changes: DraftStrategyChanges): CombinedMediaPlan {
  const update = changes.media_plans[mediaPlan.id];

  if (update && update.type === 'new')
    throw new Error('A media buy cannot also be created locally');

  return {
    ...mediaPlan,
    ...(update ? update.data : {}),
    state: update
      ? {
          type: 'update',
          original: mediaPlan,
          dirty: calcDirtyMediaPlan(mediaPlan, update.data)
        }
      : { type: 'unchanged' }
  };
}

function calcDirtyMediaPlan(mediaPlan: MediaPlan, update: MediaPlanUpdate): MediaPlanDirty {
  // tactic and unit_price_type can be reset to undefined

  return {
    ...('is_deleted' in update
      ? { is_deleted: { old: mediaPlan.is_deleted, new: update.is_deleted } }
      : {}),
    ...('name' in update ? { name: { old: mediaPlan.name, new: update.name } } : {}),
    ...('budget' in update ? { budget: { old: mediaPlan.budget, new: update.budget } } : {}),
    ...('target_unit_cost' in update
      ? { target_unit_cost: { old: mediaPlan.target_unit_cost, new: update.target_unit_cost } }
      : {}),
    ...('media_buy' in update
      ? { media_buy: { old: mediaPlan.media_buy, new: update.media_buy } }
      : {})
  };
}
