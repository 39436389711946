import React from 'react';

export function AllCampaignsIcon() {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.3506 17.8024C26.9318 16.8631 26.6416 15.6305 25.7023 15.0493C24.763 14.4681 23.5304 14.7583 22.9492 15.6976L26.3506 17.8024ZM20.2465 20.353L20.9675 22.2185H20.9675L20.2465 20.353ZM14.4023 20.7104L13.9172 22.6507H13.9172L14.4023 20.7104ZM9.53251 17.6745L11.0772 16.4041L9.53251 17.6745ZM9.53251 7.32553L7.98781 6.05514L9.53251 7.32553ZM14.4023 4.28963L14.8873 6.22992H14.8873L14.4023 4.28963ZM20.2465 4.64703L20.9675 2.78149L20.9675 2.78149L20.2465 4.64703ZM22.9492 15.6976C22.1859 16.931 20.9904 17.9213 19.5256 18.4874L20.9675 22.2185C23.2083 21.3525 25.1084 19.8099 26.3506 17.8024L22.9492 15.6976ZM19.5256 18.4874C18.0596 19.054 16.4266 19.1549 14.8873 18.7701L13.9172 22.6507C16.2522 23.2344 18.7278 23.0841 20.9675 22.2185L19.5256 18.4874ZM14.8873 18.7701C13.3487 18.3854 12.0147 17.5439 11.0772 16.4041L7.98781 18.9449C9.49208 20.7739 11.5815 22.0667 13.9172 22.6507L14.8873 18.7701ZM11.0772 16.4041C10.142 15.2669 9.6499 13.8942 9.6499 12.5H5.6499C5.6499 14.8481 6.48129 17.1131 7.98781 18.9449L11.0772 16.4041ZM9.6499 12.5C9.6499 11.1058 10.142 9.73306 11.0772 8.59592L7.98781 6.05514C6.48129 7.88695 5.6499 10.1519 5.6499 12.5H9.6499ZM11.0772 8.59592C12.0147 7.45606 13.3487 6.61457 14.8873 6.22992L13.9172 2.34935C11.5815 2.93328 9.49208 4.22605 7.98781 6.05514L11.0772 8.59592ZM14.8873 6.22992C16.4266 5.8451 18.0596 5.94601 19.5256 6.51256L20.9675 2.78149C18.7278 1.91594 16.2522 1.7656 13.9172 2.34935L14.8873 6.22992ZM19.5256 6.51256C20.9904 7.07867 22.1859 8.06898 22.9492 9.30241L26.3506 7.19759C25.1084 5.19011 23.2083 3.64749 20.9675 2.78149L19.5256 6.51256Z"
        fill="#1F2937"
      />
      <path
        d="M6 27H26"
        stroke="#1B9EFE"
        strokeWidth="4"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function AllLineItemsIcon() {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 5C28 3.89543 27.1046 3 26 3C24.8954 3 24 3.89543 24 5H28ZM24 11V13H28V11H24ZM24 5V11H28V5H24Z"
        fill="#1F2937"
      />
      <path
        d="M8 5V3H4V5H8ZM6 21H4C4 22.1046 4.89543 23 6 23V21ZM26 23C27.1046 23 28 22.1046 28 21C28 19.8954 27.1046 19 26 19V23ZM4 5V21H8V5H4ZM6 23H26V19H6V23Z"
        fill="#1F2937"
      />
      <path
        d="M6 27H26"
        stroke="#1B9EFE"
        strokeWidth="4"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function AllPlatformBuysIcon() {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 27H26"
        stroke="#1B9EFE"
        strokeWidth="4"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M4 21V23H8V21H4ZM8 5C8 3.89543 7.10457 3 6 3C4.89543 3 4 3.89543 4 5H8ZM8 21V5H4V21H8Z"
        fill="#1F2937"
      />
      <path
        d="M6 5.02554C9.29031 5.02554 16.5 5.02554 19.8462 5.02554C25 5.02554 26 8 26 9.49997C26 10.9999 25 13.9744 19.8462 13.9744C16.5 13.9744 8.5 14 6 13.9744"
        stroke="#1F2937"
        strokeWidth="4"
      />
    </svg>
  );
}

export function CampaignIcon() {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.7007 22.0524C28.2819 21.1131 27.9917 19.8805 27.0524 19.2993C26.1131 18.7181 24.8805 19.0083 24.2993 19.9476L27.7007 22.0524ZM20.8196 25.2388L21.5405 27.1043L20.8196 25.2388ZM13.944 25.6593L14.429 23.719H14.429L13.944 25.6593ZM8.21483 22.0876L6.67013 23.358H6.67013L8.21483 22.0876ZM8.21483 9.91239L6.67013 8.642L8.21483 9.91239ZM13.944 6.34075L13.4589 4.40046H13.4589L13.944 6.34075ZM20.8196 6.76121L20.0986 8.62674H20.0986L20.8196 6.76121ZM24.2993 19.9476C23.3591 21.467 21.8904 22.6808 20.0986 23.3733L21.5405 27.1043C24.1083 26.112 26.2815 24.3459 27.7007 22.0524L24.2993 19.9476ZM20.0986 23.3733C18.3056 24.0662 16.3102 24.1893 14.429 23.719L13.4589 27.5995C16.1357 28.2688 18.9739 28.0962 21.5405 27.1043L20.0986 23.3733ZM14.429 23.719C12.5486 23.2489 10.9124 22.2191 9.75953 20.8172L6.67013 23.358C8.38985 25.4491 10.7813 26.9302 13.4589 27.5995L14.429 23.719ZM9.75953 20.8172C8.60888 19.4181 8 17.7244 8 16H4C4 18.6783 4.94817 21.2642 6.67013 23.358L9.75953 20.8172ZM8 16C8 14.2756 8.60888 12.5819 9.75953 11.1828L6.67013 8.642C4.94817 10.7358 4 13.3217 4 16H8ZM9.75953 11.1828C10.9124 9.78095 12.5486 8.75114 14.429 8.28103L13.4589 4.40046C10.7813 5.06985 8.38985 6.55095 6.67013 8.642L9.75953 11.1828ZM14.429 8.28103C16.3102 7.81075 18.3056 7.93382 20.0986 8.62674L21.5405 4.89568C18.9739 3.90376 16.1357 3.73125 13.4589 4.40046L14.429 8.28103ZM20.0986 8.62674C21.8904 9.31922 23.3591 10.533 24.2993 12.0524L27.7007 9.94759C26.2815 7.65415 24.1083 5.88803 21.5405 4.89568L20.0986 8.62674Z"
        fill="#1F2937"
      />
    </svg>
  );
}

export function LineItemIcon({ width = 16, height = 16 }: { width?: number; height?: number }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 3C14 2.44772 13.5523 2 13 2C12.4477 2 12 2.44772 12 3H14ZM12 7V8H14V7H12ZM12 3V7H14V3H12Z"
        fill="#1F2937"
      />
      <path
        d="M4 3V2H2V3H4ZM3 13H2C2 13.5523 2.44772 14 3 14V13ZM13 14C13.5523 14 14 13.5523 14 13C14 12.4477 13.5523 12 13 12V14ZM2 3V13H4V3H2ZM3 14H13V12H3V14Z"
        fill="#1F2937"
      />
    </svg>
  );
}

export function PlatformBuyIcon({ width = 14, height = 14 }: { width?: number; height?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none">
      <path
        d="M0 11V12H2V11H0ZM2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1H2ZM2 11V1H0V11H2Z"
        fill="#4B5563"
      />
      <path
        d="M1 1.01606C2.64515 1.01606 6.25 1.01606 7.92308 1.01606C10.5 1.01606 11 2.8751 11 3.81258C11 4.75006 10.5 6.6091 7.92308 6.6091C6.25 6.60911 2.25 6.6251 1 6.6091"
        stroke="#4B5563"
        strokeWidth="2"
      />
    </svg>
  );
}

export function MediaPlanIcon({ width = 16, height = 16 }: { width?: number; height?: number }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 13V14H4V13H2ZM3 3L3.89443 2.55279C3.68702 2.13797 3.22162 1.9202 2.77025 2.02675C2.31888 2.13331 2 2.53622 2 3H3ZM8 13L7.10557 13.4472C7.27496 13.786 7.62123 14 8 14C8.37877 14 8.72504 13.786 8.89443 13.4472L8 13ZM13 3H14C14 2.53622 13.6811 2.13331 13.2298 2.02675C12.7784 1.9202 12.313 2.13797 12.1056 2.55279L13 3ZM12 13C12 13.5523 12.4477 14 13 14C13.5523 14 14 13.5523 14 13H12ZM4 13V3H2V13H4ZM2.10557 3.44721L7.10557 13.4472L8.89443 12.5528L3.89443 2.55279L2.10557 3.44721ZM8.89443 13.4472L13.8944 3.44721L12.1056 2.55279L7.10557 12.5528L8.89443 13.4472ZM12 3V13H14V3H12Z"
        fill="#1F2937"
      />
    </svg>
  );
}
