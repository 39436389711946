import React from 'react';
import Modal from '../../components/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkSlash, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { AlertButton, SecondaryButton } from '../../components/button';

type Props = {
  onConfirm: () => void;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  names: string[];
};

export function MediaPlansUnlinkModal({ open, setOpen, onConfirm, names }: Props) {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      header={
        <div className="flex flex-col gap-3 rounded-t-2xl bg-red-100 py-6 text-center text-2xl font-semibold text-red-600">
          <FontAwesomeIcon icon={faLinkSlash} />
          Are you sure you want to unlink?
        </div>
      }
      content={
        <div className="p-4 text-sm text-gray-700">
          <UnlinkMessage names={names} />
        </div>
      }
      footer={
        <Footer
          onConfirm={onConfirm}
          onClose={() => setOpen(false)}
          confirmLabel="Confirm Unlinking"
        />
      }
    />
  );
}

export function MediaPlansDeleteModal({ open, setOpen, onConfirm, names }: Props) {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      header={
        <div className="flex flex-col gap-3 rounded-t-2xl bg-red-100 py-6 text-center text-2xl font-semibold text-red-600">
          <FontAwesomeIcon icon={faTrashCan} />
          Are you sure you want to delete?
        </div>
      }
      content={
        <div className="p-4 text-sm text-gray-700">
          <DeleteMessage names={names} />
        </div>
      }
      footer={
        <Footer
          onConfirm={onConfirm}
          onClose={() => setOpen(false)}
          confirmLabel="Confirm delete"
        />
      }
    />
  );
}

function UnlinkMessage({ names }: { names: string[] }) {
  return (
    <div>
      <div className="font-semibold">
        {names.length > 1
          ? `Unlink ${names.length} platform buys from Media Plan`
          : `Unlink platform buy from Media Plan: ${names[0]}`}
      </div>
      {names.length > 1 && names.length < 10 && (
        <ul className="list-disc pl-6">
          {names.map((name, idx) => (
            <li key={idx}>{name}</li>
          ))}
        </ul>
      )}
      <div>
        The platform buy will still remain on the platform, and can be re-linked to another Media
        Plan
      </div>
    </div>
  );
}

function DeleteMessage({ names }: { names: string[] }) {
  return (
    <div>
      <div className="font-semibold">
        {names.length > 1 ? `Delete ${names.length} Media Plans` : `Delete Media Plan: ${names[0]}`}
      </div>
      {names.length > 1 && names.length < 10 && (
        <ul className="list-disc pl-6">
          {names.map((name, idx) => (
            <li key={idx}>{name}</li>
          ))}
        </ul>
      )}
      <div>Confirm you want to delete this Media Plan</div>
    </div>
  );
}

type FooterProps = {
  onConfirm: () => void;
  confirmLabel: string;
  onClose: () => void;
};

function Footer({ onConfirm, confirmLabel, onClose }: FooterProps) {
  return (
    <div className="flex gap-4 p-4">
      <SecondaryButton className="grow justify-around" onClick={onClose}>
        Cancel
      </SecondaryButton>
      <AlertButton className="grow justify-around" onClick={onConfirm}>
        {confirmLabel}
      </AlertButton>
    </div>
  );
}
