import * as React from 'react';
import { Fragment, ReactNode } from 'react';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import clsx from 'clsx';

export type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  header: ReactNode;
  content: ReactNode;
  footer: ReactNode | null;
  className?: string;
  onClose?: () => void;
  width?: 'default' | 'wide';
};

export default function Modal({
  open,
  setOpen,
  content,
  header,
  footer = null,
  className,
  onClose,
  width = 'default'
}: Props) {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          onClose && onClose();
          setOpen(false);
        }}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-15"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-15">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div className="fixed inset-0 z-10 w-screen">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-15 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel
                className={clsx(
                  'relative transform overflow-y-scroll rounded-2xl bg-white text-left shadow-xl transition-all',
                  className
                )}>
                <div
                  className={clsx('h-full flex-col', width === 'wide' ? `w-[889px]` : 'w-[510px]')}>
                  {header}
                  <div
                    className={clsx(
                      'overflow-y-scroll',
                      width === 'wide' ? 'h-[60vh]' : 'max-h-[60vh]'
                    )}>
                    {content}
                  </div>
                  {footer}
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
