import React, { ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

type Theme = 'blue' | 'red' | 'yellow';

type Props = {
  icon: IconProp;
  message: ReactNode;
  theme: Theme;
};
export function PlatformBuyStatus({ icon, message, theme }: Props) {
  return (
    <div className={clsx('flex w-full rounded-l-lg', getSecondaryBackgroundColor(theme))}>
      <div className={clsx('flex items-center pl-1.5 pr-1', getIconColor(theme))}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div
        className={clsx(
          'w-full rounded-l-lg px-2 py-1.5 text-xs text-gray-800',
          getPrimaryBackgroundColor(theme)
        )}>
        {message}
      </div>
    </div>
  );
}

function getPrimaryBackgroundColor(theme: Theme): string {
  switch (theme) {
    case 'blue':
      return 'bg-blue-200';
    case 'red':
      return 'bg-red-200';
    case 'yellow':
      return 'bg-yellow-200';
  }
}

function getSecondaryBackgroundColor(theme: Theme): string {
  switch (theme) {
    case 'blue':
      return 'bg-blue-100';
    case 'red':
      return 'bg-red-100';
    case 'yellow':
      return 'bg-yellow-100';
  }
}

function getIconColor(theme: Theme): string {
  switch (theme) {
    case 'blue':
      return 'text-blue-900';
    case 'red':
      return 'text-red-600';
    case 'yellow':
      return 'text-gray-900';
  }
}
