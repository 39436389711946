import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { CombinedLineItem, CombinedMediaPlan } from '../../store/strategy-combiner';
import {
  CellMouseOutEvent,
  CellMouseOverEvent,
  CellStyle,
  ColDef,
  ColGroupDef,
  ModuleRegistry,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy
} from '@ag-grid-community/core';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { AgGridReact, CustomCellRendererProps } from '@ag-grid-community/react';
import { defaultColDef, formatCurrency } from '../../components/table-utils';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { useStore } from '../../store/store';
import { findMetrics, hasMetrics } from '../line-items/line-items-table-utils';
import { MediaBuyMetrics } from '../../metrics/metrics-types';
import {
  CellRendererProps,
  DeliveredUnitsRenderer,
  HeaderComponent,
  MediaPlanBudgetRenderer,
  MediaPlanTargetMarginRenderer,
  MediaSpendRenderer,
  MetricCellRenderer,
  PlatformBuyNameRenderer,
  PlatformEntityRenderer,
  TargetUnitCostRenderer,
  TargetUnitsRenderer,
  ViewButtonCellRenderer
} from './media-buys-table-cell-components';
import { UnitPriceType } from '../../../../shared/src/line-item-channels';
import { formatPercentage, getUnitName } from '../../../../shared/src/line-item-utils';
import { generateMediaPlansTotalRow } from './media-plans-table-utils';
import { isZeroValue, UnitValueEditor } from '../shared/common-table-components';
import { cpm } from 'shared/src/channel-tactic-rates';
import { updateBudget, updateName, updateTargetUnitCost } from './media-plans-update-helper';
import { MediaPlanUpdate } from 'shared/src/media-buy-types';
import { useFlagEnabled } from '../../utils/feature-flags';
import { MediaPlansActions } from './media-plans-actions';

ModuleRegistry.registerModules([RowGroupingModule]);

// rb: we're not really treating the strategy as an aggregate
export type MediaPlansTableData = CombinedMediaPlan & {
  button: '';
  unit_price_type: UnitPriceType;
  deliveryPacing: number;
  spendPacing: number;
  margin: number;
  targetMargin: number;
  totalTargetUnits: number;
  deliveredUnits: number;
  mediaSpend: number;
  marginPerformance: number;
  currentTargetUnits: number;
  currentPlannedSpend: number;
  revenue: number;
  totalTargetRevenue: number;
  isTotal: boolean;
  numPlatforms: number;
  actions: string;
};

type Props = {
  lineItem: CombinedLineItem;
  colorMap: Record<string, string>;
  metrics: MediaBuyMetrics[];
  onLinkPlatformBuyClick: (plan: CombinedMediaPlan) => void;
  lineItemMetrics: MediaBuyMetrics | null;
};

const autoSizeStrategy:
  | SizeColumnsToFitGridStrategy
  | SizeColumnsToFitProvidedWidthStrategy
  | SizeColumnsToContentStrategy = {
  type: 'fitCellContents'
};

export function MediaPlansTable({
  lineItem,
  metrics,
  colorMap,
  lineItemMetrics,
  onLinkPlatformBuyClick
}: Props) {
  const setHighlightedGraph = useStore(state => state.setHighlightedGraph);
  const gridRef = useRef<AgGridReact<MediaPlansTableData>>(null);
  const { media_plans, unit_price_type } = lineItem;
  const isLinkingEnabled = useFlagEnabled('platform-buy-linking');

  const onCellMouseOut = useCallback(
    (e: CellMouseOutEvent<MediaPlansTableData>) => {
      if (!e.event || !e.data) return;
      if (!e.node.isHovered()) setHighlightedGraph(null);
    },
    [setHighlightedGraph]
  );

  const onCellMouseOver = useCallback(
    (e: CellMouseOverEvent<MediaPlansTableData>) => {
      if (!e.event || !e.data) return;
      if (e.node.isHovered()) {
        setHighlightedGraph(e.data.media_buy?.id);
      }
    },
    [setHighlightedGraph]
  );

  const mediaBuysTableData: MediaPlansTableData[] = useMemo(
    () =>
      media_plans
        .filter(mediaPlan => !mediaPlan.is_deleted)
        .map(plan => ({
          ...plan,
          ...findMetrics(plan.id, metrics),
          unit_price_type: unit_price_type || cpm,
          isTotal: false,
          numPlatforms: 0,
          lineItem,
          button: '',
          actions: ''
        })),
    [media_plans, lineItem, metrics, unit_price_type]
  );

  const totalsRow = useMemo(
    () => generateMediaPlansTotalRow(mediaBuysTableData),
    [mediaBuysTableData]
  );

  useEffect(() => {
    setTimeout(() => gridRef.current?.api?.autoSizeAllColumns(), 250);
  }, [mediaBuysTableData]);

  const onMouseLeave = useCallback(() => {
    setTimeout(() => setHighlightedGraph(null), 500);
  }, [setHighlightedGraph]);

  return (
    <div className="flex h-full w-full flex-col">
      <div
        className="ag-theme-quartz"
        style={{
          height: mediaBuysTableData.length < 4 ? '100%' : 'calc(.33 * (100vh - 150px))', // let the table take up roughly a third of the available height
          width: '100%'
        }}
        onMouseLeave={onMouseLeave}>
        <AgGridReact
          gridOptions={{ headerHeight: 36, rowHeight: 36 }}
          rowSelection={{ mode: 'multiRow' }}
          selectionColumnDef={{ pinned: 'left', cellStyle: { maxWidth: 50 } }}
          rowData={mediaBuysTableData}
          ref={gridRef}
          columnDefs={getColumnDefs(
            colorMap,
            hasMetrics(metrics),
            lineItem,
            isLinkingEnabled,
            lineItemMetrics,
            onLinkPlatformBuyClick
          )}
          defaultColDef={defaultColDef}
          domLayout={mediaBuysTableData.length < 4 ? 'autoHeight' : 'normal'}
          getRowStyle={({ data }) => ({
            ...(data.isTotal ? { background: '#f0f0f0' } : {})
          })}
          pinnedBottomRowData={[totalsRow]}
          onCellMouseOver={onCellMouseOver}
          onCellMouseOut={onCellMouseOut}
          autoSizeStrategy={autoSizeStrategy}
        />
      </div>
    </div>
  );
}

function getColumnDefs(
  colorMap: Record<string, string>,
  showMetrics: boolean,
  lineItem: CombinedLineItem,
  linkingEnabled: boolean | undefined,
  lineItemMetrics: MediaBuyMetrics | null,
  setPlanToLink: (plan: CombinedMediaPlan) => void
): (ColDef<MediaPlansTableData> | ColGroupDef<CombinedMediaPlan>)[] {
  const unitPriceTypeAbbr = getUnitName(lineItem.unit_price_type?.name || 'CPM').abbr;
  return [
    {
      field: 'button',
      cellRendererParams: { colorMap },
      cellRenderer: ViewButtonCellRenderer,
      headerName: '',
      cellStyle: { padding: 0 },
      maxWidth: 70,
      pinned: 'left',
      resizable: false,
      suppressMovable: true,
      suppressAutoSize: true
    },
    {
      field: 'name',
      headerName: 'Plan Name',
      editable: ({ data }) => !!linkingEnabled && !data?.isTotal,
      onCellValueChanged: update => updateName(update, lineItem),
      maxWidth: 700,
      initialWidth: 600,
      cellStyle: ({ data }) => cellStyle(data, 'name')
    },
    {
      field: 'media_buy.media_platform_id',
      headerName: 'Platform Entity',
      cellRenderer: PlatformEntityRenderer
    },
    {
      field: 'budget',
      headerName: 'Plan Budget',
      cellDataType: 'number',
      editable: ({ data }) => !!linkingEnabled && !data?.isTotal,
      cellRenderer: MediaPlanBudgetRenderer,
      cellRendererParams: { lineItemBudget: lineItemMetrics?.budget },
      onCellValueChanged: update => updateBudget(update, lineItem),
      cellStyle: ({ data }) => ({ ...validStyling(data, 'budget'), ...numericalCellStyle }),
      valueFormatter: params => formatCurrency(params.data?.budget || 0),
      valueSetter: params => {
        if (isZeroValue(params.newValue)) return false;
        params.data.budget = params.newValue;
        return true;
      }
    },
    {
      field: 'target_unit_cost',
      headerName: `Plan ${lineItem.unit_price_type?.name || 'CPM'} `,
      cellDataType: 'number',
      editable: ({ data }) => !!linkingEnabled && !data?.isTotal,
      cellEditor: UnitValueEditor,
      cellRenderer: (props: CellRendererProps<number>) => (
        <TargetUnitCostRenderer
          {...props}
          lineItemTarget={lineItemMetrics?.adjustedTargetUnitCost}
        />
      ),
      onCellValueChanged: update => updateTargetUnitCost(update, lineItem),
      cellStyle: ({ data }) => ({
        ...validStyling(data, 'target_unit_cost'),
        ...numericalCellStyle
      }),
      valueSetter: params => {
        if (isZeroValue(params.newValue)) return false;
        params.data.target_unit_cost = params.newValue;
        return true;
      }
    },
    {
      field: 'targetMargin',
      headerName: 'Plan Margin',
      cellDataType: 'number',
      cellRenderer: (props: CellRendererProps<number>) => (
        <MediaPlanTargetMarginRenderer
          {...props}
          lineItemTargetMargin={lineItemMetrics?.targetMargin}
        />
      ),
      cellStyle: () => numericalCellStyle,
      valueFormatter: params => formatPercentage(params.data?.targetMargin || 0)
    },
    {
      field: 'totalTargetUnits',
      cellDataType: 'number',
      headerName: `Plan ${unitPriceTypeAbbr}`,
      headerComponent: HeaderComponent,
      cellRenderer: TargetUnitsRenderer,
      cellRendererParams: { lineItemTotalTargetUnits: lineItemMetrics?.totalTargetUnits },
      cellStyle: () => numericalCellStyle,
      valueFormatter: params => Math.round(params.data?.totalTargetUnits || 0).toLocaleString()
    },
    {
      field: 'media_buy.name',
      headerName: 'Platform Buy Name',
      cellRenderer: PlatformBuyNameRenderer,
      cellRendererParams: { onAddClick: (plan: CombinedMediaPlan) => setPlanToLink(plan) },
      maxWidth: 700
    },
    {
      field: 'deliveryPacing',
      headerName: 'Plan Delivery Pacing',
      hide: !showMetrics,
      cellRenderer: MetricCellRenderer
    },
    {
      field: 'spendPacing',
      headerName: 'Plan Spend Pacing',
      hide: !showMetrics,
      cellRenderer: MetricCellRenderer
    },
    {
      field: 'margin',
      headerName: 'Plan Margin (actual)',
      hide: !showMetrics,
      cellRenderer: MetricCellRenderer
    },
    {
      field: 'deliveredUnits',
      headerName: `${unitPriceTypeAbbr} Delivered`,
      headerComponent: HeaderComponent,
      cellDataType: 'number',
      cellRenderer: DeliveredUnitsRenderer,
      cellStyle: () => numericalCellStyle,
      valueFormatter: params => Math.round(params.data?.deliveredUnits || 0).toLocaleString()
    },
    {
      field: 'mediaSpend',
      cellDataType: 'number',
      cellRenderer: MediaSpendRenderer,
      cellStyle: () => numericalCellStyle
    },
    {
      field: 'actions',
      cellRenderer: ({ data }: CustomCellRendererProps<MediaPlansTableData>) =>
        data && !data.isTotal && <MediaPlansActions data={data} lineItem={lineItem} />,
      cellStyle: { padding: 0, border: 'none' },
      minWidth: 93,
      headerName: '',
      resizable: false,
      pinned: 'right',
      suppressHeaderFilterButton: true,
      suppressHeaderContextMenu: true,
      suppressMovable: true
    }
  ];
}

const numericalCellStyle = { textAlign: 'right' };

function cellStyle(
  data: CombinedMediaPlan | undefined,
  key: keyof Omit<MediaPlanUpdate, 'line_item_id'>
) {
  if (!data || data.state.type === 'unchanged') return {};
  if (data.state.type === 'new' || data.state.dirty[key]) return editedStyle;
  return {};
}

function validStyling(
  data: CombinedMediaPlan | undefined,
  key: 'budget' | 'target_unit_cost'
): CellStyle {
  if (!data || data.state.type === 'unchanged') return {};
  return {
    ...(data.state.type === 'new' || data.state.dirty[key] ? editedStyle : {}),
    ...(data[key] > 0 ? {} : errorStyle)
  };
}

const editedStyle = {
  borderBottom: '1px solid #F59460',
  backgroundColor: 'rgba(255, 240, 233, 0.5)'
};

const errorStyle = {
  backgroundColor: 'rgba(255,233,233,0.5)',
  borderBottom: '2px solid red'
};
