import React, { useLayoutEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRetweet, faChevronRight, faListUl } from '@fortawesome/pro-light-svg-icons';
import { clsx } from 'clsx';
import { Tooltip } from 'react-tooltip';

type Props = {
  name: string;
  numChanges: number;
  numErrors: number;
  discardFn: () => unknown;
  content: React.ReactNode;
  tooltipContent: React.ReactNode;
  tooltipId: string;
  type: 'new' | 'update';
};

export function PendingChangeContainer({
  name,
  numChanges,
  numErrors,
  discardFn,
  content,
  tooltipContent,
  tooltipId,
  type
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isOpen]);

  return (
    <div className="mb-3 rounded-lg border border-gray-200 px-4 py-4">
      <div className="flex items-center justify-between">
        <div onClick={() => setIsOpen(prev => !prev)} className="flex cursor-pointer items-center">
          <FontAwesomeIcon
            icon={faChevronRight}
            className={clsx('mr-2 transition-all', isOpen ? 'rotate-90' : 'rotate-0')}
          />
          <div className="max-w-64 truncate font-medium">{name}</div>
          {type === 'update' ? (
            <div
              data-tooltip-id={tooltipId}
              className="ml-2 flex items-center rounded-full bg-orange-100 px-2 py-1 text-sm text-orange-600">
              <FontAwesomeIcon icon={faArrowsRetweet} className="mr-2" />
              <div>
                {numChanges} update{numChanges > 1 ? 's' : ''}
              </div>
            </div>
          ) : (
            <div
              data-tooltip-id={tooltipId}
              className="ml-2 flex items-center rounded-full bg-blue-100 px-2 py-1 text-sm text-blue-700">
              <FontAwesomeIcon icon={faListUl} className="mr-2" />
              <div>{numChanges} new</div>
            </div>
          )}
          <Tooltip
            id={tooltipId}
            opacity={1}
            place="right"
            style={{ backgroundColor: '#111827', borderRadius: '4px' }}>
            {tooltipContent}
          </Tooltip>
        </div>
        <div
          className="text-sm font-light text-red-500 hover:cursor-pointer hover:underline"
          onClick={discardFn}>
          Discard
        </div>
      </div>
      <div
        style={{
          maxHeight: isOpen ? `${contentHeight}px` : '0px',
          marginTop: isOpen ? '8px' : '0px'
        }}
        className="overflow-y-scroll transition-all duration-500 ease-in-out">
        <div ref={contentRef} className="pt-4">
          {content}
        </div>
      </div>
    </div>
  );
}
