import React from 'react';
import { MediaPlansTableData } from './media-plans-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faLinkSlash,
  faMagnifyingGlassPlus,
  faTrashCan,
  IconDefinition
} from '@fortawesome/pro-light-svg-icons';
import { MediaPlansDeleteModal, MediaPlansUnlinkModal } from './media-plans-action-modals';
import { CombinedLineItem } from '../../store/strategy-combiner';
import { setIsDeleted, unlinkPlatformBuy } from './media-plans-update-helper';
import { Tooltip } from 'react-tooltip';
import { generateDeeplink } from './platform-buy-utils';
import { clsx } from 'clsx';

type Props = {
  data: MediaPlansTableData;
  lineItem: CombinedLineItem;
};

export function MediaPlansActions(props: Props) {
  const { data } = props;
  return (
    <div className="">
      <div className="shadow-l-lg fixed right-0 h-full w-16 border-l-[1px] border-gray-200 bg-white px-2">
        <div className="flex h-full w-full items-center justify-between">
          {data.media_buy && (
            <>
              <ViewOnPlatformButton data={data} />
              <UnlinkPlatformBuyButton {...props} />
            </>
          )}
          {data.media_buy == null && (
            <>
              <LinkPlatformBuyButton {...props} />
              <DeleteMediaPlanButton {...props} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function ViewOnPlatformButton({ data }: { data: MediaPlansTableData }) {
  const link = data.media_buy ? generateDeeplink(data.media_buy) : undefined;
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <ActionButton
        id="view-on-platform-button"
        disabled={link === undefined}
        onClick={() => {}}
        icon={faArrowUpRightFromSquare}
        iconColor="text-blue-600"
        tooltipContent="View on platform"
      />
    </a>
  );
}

function UnlinkPlatformBuyButton({ data, lineItem }: Props) {
  const [modalOpen, setModalOpen] = React.useState(false);

  function unlinkPlatform() {
    unlinkPlatformBuy(data, lineItem);
  }

  return (
    <>
      <ActionButton
        id="unlink-platform-buy-button"
        disabled={data.media_buy === null}
        onClick={() => setModalOpen(true)}
        icon={faLinkSlash}
        iconColor="text-rose-800"
        tooltipContent="Unlink Platform Buy"
      />
      <MediaPlansUnlinkModal
        open={modalOpen}
        setOpen={setModalOpen}
        onConfirm={unlinkPlatform}
        names={data.name ? [data.name] : ['No name entered']}
      />
    </>
  );
}

function LinkPlatformBuyButton({ data }: Props) {
  // TODO[mk] - reuse existing modal to add a new media plan
  return (
    <>
      <ActionButton
        id="delete-media-plan-button"
        disabled={data.media_buy != null}
        onClick={() => {}}
        icon={faMagnifyingGlassPlus}
        iconColor="text-blue-600"
        tooltipContent="Link Platform Buy"
      />
    </>
  );
}

function DeleteMediaPlanButton({ data, lineItem }: Props) {
  const [modalOpen, setModalOpen] = React.useState(false);

  function deleteMediaPlan() {
    setIsDeleted(data, lineItem);
  }

  return (
    <>
      <ActionButton
        id="delete-media-plan-button"
        disabled={data.media_buy != null}
        onClick={() => setModalOpen(true)}
        icon={faTrashCan}
        iconColor="text-red-600"
        tooltipContent="Delete Media Plan"
      />
      <MediaPlansDeleteModal
        open={modalOpen}
        setOpen={setModalOpen}
        onConfirm={deleteMediaPlan}
        names={data.name ? [data.name] : ['No name entered']}
      />
    </>
  );
}

type ActionButtonProps = {
  id: string;
  disabled: boolean;
  onClick: () => void;
  icon: IconDefinition;
  iconColor: string;
  tooltipContent: React.ReactNode;
};

function ActionButton({
  id,
  disabled,
  onClick,
  icon,
  iconColor,
  tooltipContent
}: ActionButtonProps) {
  return (
    <div className="">
      <button
        id={id}
        disabled={disabled}
        onClick={onClick}
        className={clsx('flex w-4 items-center disabled:text-gray-400', iconColor)}>
        <FontAwesomeIcon icon={icon} />
      </button>
      <Tooltip
        anchorSelect={`#${id}`}
        place="bottom"
        positionStrategy="fixed"
        style={{ zIndex: 9999 }}
        delayShow={500}>
        {tooltipContent}
      </Tooltip>
    </div>
  );
}
