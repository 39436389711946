import React, { useEffect, useRef } from 'react';
import { CustomCellEditorProps } from '@ag-grid-community/react';
import { UnitPriceType } from 'shared/src/line-item-channels';

interface HasUnitPriceType {
  unit_price_type: UnitPriceType;
}

export type CellEditorProps<T> = CustomCellEditorProps<HasUnitPriceType, T>;

export function UnitValueEditor({ data, value, onValueChange }: CellEditorProps<number>) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  function handleChange(value: string) {
    const num = parseFloat(value);
    if (isNaN(num)) {
      onValueChange(undefined);
    } else {
      onValueChange(data.unit_price_type?.name === 'CPM' ? num / 1000 : num);
    }
  }

  const displayValue = unitValue(data, value);

  return (
    <div className="relative flex h-full w-full items-center rounded-[2px] border-[1px] border-[#2096f3] bg-white">
      <div className="absolute left-3">$</div>
      <input
        className="w-full rounded-none border-0 border-none pl-6 text-sm outline-none focus:ring-0"
        ref={inputRef}
        type="number"
        value={displayValue === 0 ? displayValue : displayValue || ''}
        onChange={({ target }) => handleChange(target.value)}
      />
    </div>
  );
}

function unitValue(data: HasUnitPriceType, value: number | null | undefined) {
  if (value == null) return null;
  return data.unit_price_type?.name === 'CPM' ? value * 1000 : value;
}

export function isZeroValue(value: number | null) {
  return value === 0 || value === null;
}
