import { format, isValid, parse } from 'date-fns';
import { UTCDate } from '@date-fns/utc';
import { TZDate } from '@date-fns/tz';

export function formatDateString(dateString: string | null | undefined): string {
  if (!dateString) return '-';
  const date = new TZDate(dateString, 'UTC');
  if (isValid(date)) return format(date, 'MMM do yyyy');
  console.warn('Invalid date ' + dateString + ' found');
  return '';
}

export function formatDate(
  date: UTCDate | null | undefined,
  formatStr: string = 'MMM do yyyy'
): string {
  return !date ? '-' : format(new TZDate(date, 'UTC'), formatStr);
}

export function isValidDate(dateString: string) {
  return isValid(parse(dateString, 'yyyy-MM-dd', new UTCDate()));
}

export function UTCYesterday(): UTCDate {
  const yesterday = new UTCDate();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
}
