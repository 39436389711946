import { PostHogConfig } from 'posthog-js';

export const isProd = window.location.host.includes('prod.mediatool');
export const isStaging = window.location.host.includes('staging.mediatool');
export const isLocal = window.location.host.includes('localhost:300');

// TODO [ac] we can have a separate key for the production system and determine that via the url.
//  This is the recommended way to manage multiple environments
//  https://posthog.com/tutorials/multiple-environments
export const posthogApiKey = 'phc_fKnThQKaqqsgeVucRqLS0jotj1aNHyC9XrU6LBWvybe';

export const posthogConfig: Partial<PostHogConfig> = {
  api_host: 'https://us.i.posthog.com',
  disable_session_recording: false,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: { password: true }
  },
  bootstrap: {
    featureFlags: {
      'all-media-buys-page': true
    }
  }
};
