import { HeroMetric, HeroMetricMessage, HeroMetricsContainer } from '../../components/hero-metrics';
import React from 'react';
import { MediaBuyMetrics } from '../../metrics/metrics-types';
import { getUnitName } from 'shared/src/line-item-utils';

type Props = { metrics: MediaBuyMetrics };

export function MediaBuyHeroMetrics({ metrics }: Props) {
  const {
    deliveredUnits,
    currentTargetUnits,
    mediaSpend,
    currentPlannedSpend,
    currentMargin,
    targetMargin,
    priceType,
    deliveryPacing,
    spendPacing,
    customPacing
  } = metrics;

  return (
    <HeroMetricsContainer>
      {customPacing && (
        <HeroMetricMessage
          message="This line item uses a custom pacing schedule. "
          additionalMessage="Pacing metrics and visualizations may be incorrect."
        />
      )}
      <div className="flex justify-between gap-1">
        <HeroMetric
          primary={{ name: 'Delivery Pacing', value: deliveryPacing }}
          other={[
            {
              type: 'number',
              name: `${getUnitName(priceType).full} delivered`,
              value: deliveredUnits
            },
            { type: 'number', name: 'On-pace target', value: currentTargetUnits }
          ]}
        />
        <HeroMetric
          primary={{ name: 'Spend Pacing', value: spendPacing }}
          other={[
            { type: 'currency', name: `Media spend`, value: mediaSpend },
            { type: 'currency', name: 'On-pace spend', value: currentPlannedSpend }
          ]}
        />
        <HeroMetric
          primary={{ name: 'Margin', value: currentMargin }}
          other={[
            {
              type: 'margin',
              value: currentMargin,
              target: targetMargin
            },
            { type: 'percentage', name: 'Target Margin', value: targetMargin }
          ]}
        />
      </div>
    </HeroMetricsContainer>
  );
}
