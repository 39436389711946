import React from 'react';
import { CombinedLineItem } from '../../store/strategy-combiner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import {
  arePacingIntervalsValid,
  equalToTwoDecimalPlaces,
  isPacingPriceValid
} from './pacing-helpers';
import { LineItem } from 'shared/src/line-item-types';
import { formatCurrency } from '../../components/table-utils';

type Props = { lineItem: CombinedLineItem };

export function LineItemErrors({ lineItem }: Props) {
  const pricingValid = isPacingTotalValid(lineItem);
  const pacingDatesValid = arePacingDatesValid(lineItem);
  const unitPriceValid = isUnitPriceValid(lineItem);
  const requireFieldsSet = areRequiredFieldsSet(lineItem);

  if (pricingValid && pacingDatesValid && requireFieldsSet && unitPriceValid) return null;

  return (
    <div className="mt-2 flex w-96 flex-col rounded-md bg-red-100 p-2 px-2 font-light">
      {!pricingValid && <LineItemError message={invalidPacingMessage(lineItem)} />}
      {!requireFieldsSet && <LineItemError message="Missing required fields" />}
      {!unitPriceValid && <LineItemError message="Unit Price must be set and non zero" />}
      {!pacingDatesValid && (
        <LineItemError message="Custom Pacing Schedule contains overlapping dates" />
      )}
    </div>
  );
}

function LineItemError({ message }: { message: string }) {
  return (
    <div className="my-1 flex">
      <FontAwesomeIcon className="mr-2 text-red-500" icon={faTriangleExclamation} />
      <div className="text-xs">{message}</div>
    </div>
  );
}

export function hasLineItemErrors(lineItem: CombinedLineItem) {
  return (
    !isPacingTotalValid(lineItem) ||
    !arePacingDatesValid(lineItem) ||
    !isUnitPriceValid(lineItem) ||
    !areRequiredFieldsSet(lineItem)
  );
}

function arePacingDatesValid(lineItem: CombinedLineItem) {
  return lineItem.pacing_type === 'custom' && lineItem.pacing_details
    ? arePacingIntervalsValid(lineItem.pacing_details)
    : true;
}

export function areRequiredFieldsSet(lineItem: CombinedLineItem) {
  const keys = Object.keys(
    LineItem.omit({
      media_plans: true,
      pacing_details: true,
      media_platforms: true,
      is_deleted: true
    }).shape
  );
  return keys.every(key => lineItem[key as keyof LineItem] != null);
}

function isPacingTotalValid(lineItem: CombinedLineItem) {
  if (!lineItem.pacing_details) return true;
  return isPacingPriceValid(lineItem.pacing_details, lineItem.price || 0);
}

function isUnitPriceValid(lineItem: CombinedLineItem) {
  return !(lineItem.unit_price == undefined || lineItem.unit_price === 0);
}

function invalidPacingMessage(lineItem: CombinedLineItem) {
  if (!lineItem.pacing_details || lineItem.price === undefined) return 'No Errors';
  const { blocks } = lineItem.pacing_details;
  const totalPrice = blocks.reduce((acc, segment) => acc + segment.price, 0);
  if (equalToTwoDecimalPlaces(totalPrice, lineItem.price)) return 'No Errors';
  return totalPrice > lineItem.price
    ? `Pacing budget (${formatCurrency(totalPrice)}) is higher than line item budget (${formatCurrency(lineItem.price)})`
    : `Line item budget (${formatCurrency(totalPrice)}) is higher than the pacing budget (${formatCurrency(lineItem.price)})`;
}
