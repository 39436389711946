import { State, ToRemove } from './store';
import { CreateMediaPlanOptions, DraftStrategyChanges } from 'shared/src/types';
import { v4 as uuid } from 'uuid';
import { LineItem, LineItemChangeDraft, PartialLineItem } from 'shared/src/line-item-types';
import { CombinedLineItem } from './strategy-combiner';
import { tsOmit } from 'shared/src/object-utils';
import { cloneDeep, isEqual } from 'lodash';
import { MediaPlan, MediaPlanChanges, MediaPlanUpdate } from 'shared/src/media-buy-types';

const DEFAULT_STRATEGY = { line_items: {}, media_plans: {} };

// Functions in here operate on the state passed from the store
// This state is an immer object that allows direct mutations but these happen on a draft
// object and zustand takes care of publishing it and updating react components that
// depend on it

export function addLineItem(state: State, lineItem: PartialLineItem) {
  let strategy = state.campaigns[lineItem.strategy_id];
  if (!strategy) {
    state.campaigns[lineItem.strategy_id] = { line_items: {}, media_plans: {} };
    strategy = state.campaigns[lineItem.strategy_id];
  }
  const lineItemId = uuid();
  strategy.line_items[lineItemId] = {
    type: 'new',
    data: { ...lineItem, id: lineItemId }
  };
  const lineItemData = strategy.line_items[lineItemId].data;
  if (lineItemData.pacing_type === 'custom' && !lineItemData.pacing_details) {
    lineItemData.pacing_details = { blocks: [] };
  }
}

export function updateLineItem(state: State, update: PartialLineItem, original?: LineItem) {
  const { strategy_id } = update;
  if (!state.campaigns[strategy_id]) {
    state.campaigns[strategy_id] = { line_items: {}, media_plans: {} };
  }
  const strategy = state.campaigns[update.strategy_id];
  const lineItem = strategy.line_items[update.id];
  if (lineItem) {
    strategy.line_items[update.id].data = { ...lineItem.data, ...update };
  } else {
    strategy.line_items[update.id] = { type: 'update', data: { ...update } };
  }
  removeUnchangedValues(strategy.line_items, update.id, original);
}

export function removeEntities(state: State, strategyId: string, toRemove: ToRemove) {
  const changes = state.campaigns[strategyId];
  if (!changes) return;
  const { media_plans, line_items } = changes;
  const { lineItemIds, mediaPlanIds } = toRemove;
  if (mediaPlanIds) {
    for (const id of mediaPlanIds) {
      delete media_plans[id];
    }
  }
  if (lineItemIds) {
    for (const id of lineItemIds) {
      delete line_items[id];
    }
  }
}

export function resetChanges(state: State, strategyId: string) {
  state.campaigns[strategyId] = { line_items: {}, media_plans: {} };
}

export function getStrategy(state: State, strategyId: string): DraftStrategyChanges {
  return state.campaigns[strategyId] || DEFAULT_STRATEGY;
}

export function addMediaPlan(state: State, options: CreateMediaPlanOptions) {
  const { strategy_id, ...planOptions } = options;
  let strategy = state.campaigns[strategy_id];
  if (!strategy) {
    state.campaigns[strategy_id] = { line_items: {}, media_plans: {} };
    strategy = state.campaigns[strategy_id];
  }
  strategy.media_plans[planOptions.id] = { type: 'new', data: planOptions };
}

export function updateMediaPlan(
  state: State,
  update: MediaPlanUpdate,
  lineItem: CombinedLineItem,
  original?: MediaPlan
) {
  const { strategy_id } = lineItem;
  let strategy = state.campaigns[strategy_id];
  if (!strategy) {
    state.campaigns[strategy_id] = { line_items: {}, media_plans: {} };
    strategy = state.campaigns[strategy_id];
  }
  const mediaPlan = strategy.media_plans[update.id];
  if (mediaPlan) {
    strategy.media_plans[update.id].data = { ...mediaPlan.data, ...update };
  } else {
    strategy.media_plans[update.id] = { type: 'update', data: { ...update } };
  }
  removeUnchangedMediaPlanValues(strategy.media_plans, update.id, original);
}

export function duplicateLineItems(
  state: State,
  lineItems: CombinedLineItem[],
  idMapping: Record<string, string>
) {
  if (lineItems.length === 0) return;
  const strategyId = lineItems[0].strategy_id;
  let strategy = state.campaigns[strategyId];
  if (!strategy) {
    state.campaigns[strategyId] = { line_items: {}, media_plans: {} };
    strategy = state.campaigns[strategyId];
  }
  for (const lineItem of lineItems) {
    const lineItemId = idMapping[lineItem.id] || uuid();
    strategy.line_items[lineItemId] = {
      type: 'new',
      data: {
        ...cloneDeep(tsOmit(lineItem, ['id', 'state', 'media_plans'])),
        id: lineItemId
      },
      // if duplicating multiple items at once, we'll want them to be positioned at the bottom of the list
      // if duplicating a single item, we'll want it to be positioned immediately beneath the item being duplicated
      position: lineItems.length > 1 ? { type: 'bottom' } : { type: 'after', itemId: lineItem.id }
    };
  }
}

function removeUnchangedValues(
  lineItems: Record<string, LineItemChangeDraft>,
  id: string,
  original?: LineItem
) {
  const lineItem = lineItems[id];
  if (!lineItem || !original) return;
  const update = lineItem.data;
  for (const key in tsOmit(update, ['id', 'strategy_id'])) {
    if (isEqual(update[key as keyof PartialLineItem], original[key as keyof PartialLineItem])) {
      delete update[key as keyof PartialLineItem];
    }
  }
  if (Object.keys(tsOmit(update, ['id', 'strategy_id'])).length === 0) {
    delete lineItems[id];
  }
}

function removeUnchangedMediaPlanValues(
  lineItems: Record<string, MediaPlanChanges>,
  id: string,
  original?: MediaPlan
) {
  const lineItem = lineItems[id];
  if (!lineItem || !original) return;
  const update = lineItem.data;
  for (const key in tsOmit(update, ['id', 'line_item_id'])) {
    if (
      isEqual(
        update[key as keyof Omit<MediaPlanUpdate, 'line_item_id'>],
        original[key as keyof Omit<MediaPlanUpdate, 'line_item_id'>]
      )
    ) {
      delete update[key as keyof Omit<MediaPlanUpdate, 'line_item_id'>];
    }
  }
  if (Object.keys(tsOmit(update, ['id', 'line_item_id'])).length === 0) {
    delete lineItems[id];
  }
}
