import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { client } from '../../utils/trpc-client';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  KeyCreatorParams,
  ModuleRegistry,
  SortModelItem,
  StatusPanelDef,
  ValueFormatterParams
} from '@ag-grid-community/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { AgGridReact, CustomCellRendererProps } from '@ag-grid-community/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '../shared/page-title';
import { allTactics, allUnitPriceTypes, Channel } from 'shared/src/line-item-channels';
import {
  CellRendererProps,
  ChannelCellRenderer,
  EmptyPacingDetails,
  EstimatedUnitsRenderer,
  getValueString,
  MediaBudgetRenderer,
  NullPacingDetails,
  TargetMarginCellRenderer,
  TargetMarginTooltip,
  UnitCostsRenderer,
  UnitPriceCellRenderer,
  UnitPriceTooltip
} from './line-item-table-cell-components';
import { formatDate } from 'shared/src/date-utils';
import { useStore } from '../../store/store';
import {
  LineItemListRequest,
  LineItemListRow,
  LineItemsFilterTypes,
  LineItemSort,
  LineItemSortColumn,
  lineItemSortColumns
} from 'shared/src/line-item-types';
import { useChannelRatesContext } from '../strategy/channel-rates-context';
import { calcAdjustedUnitCost, calcEstimatedUnits } from 'shared/src/line-item-utils';
import { getCumulativeSpend } from './pacing-helpers';
import { SearchRow } from '../../components/search-bar';
import { autoSizeStrategy } from './line-items-table';
import { showErrorToast } from '../../components/error-toast';
import { defaultColDef, formatCurrency } from '../../components/table-utils';
import { AllLineItemsIcon } from '../../components/nav-icons';
import { getAllLineItemUrlParams, storeAllLineItemUrlParams } from '../../utils/url-utils';
import { TotalStatusPanel } from '../shared/totals-status-panel';
import type { RowClickedEvent } from '@ag-grid-community/core/dist/types/src/events';
import { useCaptureListingPageView } from '../../utils/posthog/analytics';

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  MenuModule,
  SetFilterModule,
  StatusBarModule,
  FiltersToolPanelModule
]);

export type ExtendedListItemListRow = LineItemListRow & {
  pacing_graph: number[];
  estimated_units: number | undefined;
  unit_cost: number | undefined;
};

export function AllLineItems() {
  const [searchText, setSearchText] = useState<string>('');
  const [gridApi, setGridApi] = useState<GridApi<LineItemListRow> | null>(null);
  const { channels } = useChannelRatesContext();
  const columnDefs: ColDef<ExtendedListItemListRow>[] = useMemo(
    () => createColumnDefs(channels),
    [channels]
  );
  const navigate = useNavigate();
  useCaptureListingPageView('line_item');

  function handleRowClick(clickEvent: RowClickedEvent<ExtendedListItemListRow>) {
    const { event, data } = clickEvent;
    if (!data) return;
    const url = `/campaigns/${data.campaign_id}/strategy/lineitems/${data.id}/overview`;
    if (isMetaKeyPressed(event)) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  }

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption('serverSideDatasource', {
        getRows: async params => {
          const parsedFilters = LineItemsFilterTypes.safeParse(params.request.filterModel);
          if (!parsedFilters.success) {
            console.error('Failed to parse line items filter model', parsedFilters.error);
            return;
          }
          try {
            const sort = getCurrentSort(params.request.sortModel);
            const filters = parsedFilters.data;
            const page = params.request.endRow ? Math.ceil(params.request.endRow / 50) : 1;
            const requestParams = { page, sort, filters, search: searchText };
            const { line_items, total } = await client.lineItems.query(requestParams);
            params.success({ rowData: transformRows(line_items), rowCount: total });
            storeAllLineItemUrlParams({ filters, sort });
          } catch (error) {
            console.error('Failed to fetch line items', error);
            showErrorToast();
            return;
          }
        }
      });
    }
  }, [gridApi, searchText]);

  function clearFilters() {
    if (gridApi) {
      gridApi.setFilterModel(null);
      gridApi.applyColumnState({ defaultState: { sort: null } });
    }
  }

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
    const urlParams = getAllLineItemUrlParams();
    if (urlParams) {
      const { filters, sort } = urlParams;
      params.api.setFilterModel(filters);
      if (sort) params.api.applyColumnState({ state: [sort], defaultState: { sort: null } });
    } else {
      params.api.setFilterModel(defaultFilters());
    }
  }, []);

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [{ statusPanel: TotalStatusPanel }]
    };
  }, []);

  return (
    <div className="flex h-full w-full flex-col p-12">
      <PageTitle title="Bravo | Line Items" />
      <SearchRow
        icon={<AllLineItemsIcon />}
        title="All Line Items"
        placeholder="Search By Line Item"
        submitSearch={setSearchText}
      />
      <div
        className="mb-1 flex h-4 cursor-pointer items-center justify-end text-sm text-blue-400 underline"
        onClick={clearFilters}>
        Clear Filters
      </div>
      <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowBuffer={0}
          rowModelType={'serverSide'}
          cacheBlockSize={50}
          cacheOverflowSize={2}
          isExternalFilterPresent={() => true}
          infiniteInitialRowCount={100}
          maxBlocksInCache={10}
          sideBar="filters"
          autoSizeStrategy={autoSizeStrategy}
          onGridReady={onGridReady}
          statusBar={statusBar}
          onRowClicked={handleRowClick}
        />
      </div>
    </div>
  );
}

function transformRows(rows: LineItemListRow[]): ExtendedListItemListRow[] {
  return rows
    .filter(lineItem => !lineItem.is_deleted)
    .map(lineItem => ({
      ...lineItem,
      pacing_graph: generateGraph(lineItem),
      estimated_units: calcEstimatedUnits(lineItem),
      unit_cost: calcAdjustedUnitCost(lineItem)
    }));
}

function generateGraph(lineItem: LineItemListRow): number[] {
  const { pacing_details } = lineItem;
  if (
    !pacing_details ||
    pacing_details.blocks.length === 0 ||
    !lineItem.start_date ||
    !lineItem.end_date
  )
    return [];
  return getCumulativeSpend(lineItem.start_date, lineItem.end_date, pacing_details.blocks).map(
    day => day.cumulativeTotal
  );
}

function createColumnDefs(channels: Channel[]): ColDef<ExtendedListItemListRow>[] {
  const tactics = allTactics(channels);
  const unitPriceTypes = allUnitPriceTypes(channels);

  return [
    {
      field: 'campaign_number',
      headerName: 'Campaign Id',
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value !== undefined) {
          return props.value;
        } else {
          return <FontAwesomeIcon icon={faSpinner} className="animate-rotate text-blue-500" />;
        }
      },
      suppressHeaderMenuButton: true
    },
    {
      field: 'campaign_name',
      headerName: 'Campaign Name',
      maxWidth: 500,
      cellRenderer: cellRendererCmp('campaign_name')
    },
    {
      field: 'name',
      maxWidth: 500,
      cellRenderer: cellRendererCmp('name')
    },
    {
      field: 'price',
      headerName: 'Price',
      cellDataType: 'number',
      valueFormatter: params => formatCurrency(params.data?.price || 0),
      filter: 'agNumberColumnFilter',
      filterParams: {
        allowedCharPattern: '\\d\\-\\,',
        filterOptions: ['equals', 'greaterThan', 'lessThan', 'inRange'],
        maxNumConditions: 1
      }
    },
    {
      field: 'channel',
      keyCreator: params => params.value.name,
      valueFormatter: params => params.value?.name,
      comparator: (a, b) => a.name.localeCompare(b.name),
      cellRenderer: ChannelCellRenderer,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: channels,
        keyCreator: (params: KeyCreatorParams) => params.value.id,
        valueFormatter: (params: ValueFormatterParams) => params.value.name
      }
    },
    {
      field: 'tactic',
      keyCreator: params => params.value.id,
      comparator: (a, b) => a.name.localeCompare(b.name),
      cellRenderer: cellRendererCmp('tactic'),
      filter: 'agSetColumnFilter',
      filterParams: {
        values: tactics,
        keyCreator: (params: KeyCreatorParams) => params.value.id,
        valueFormatter: (params: ValueFormatterParams) => params.value.name
      }
    },
    {
      field: 'num_media_buys',
      headerName: 'Linked Media Plans',
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      filterParams: {
        allowedCharPattern: '\\d\\-\\,',
        filterOptions: ['equals', 'greaterThan', 'lessThan', 'inRange'],
        maxNumConditions: 1
      }
    },
    {
      field: 'unit_price_type',
      headerName: 'Unit Price Type',
      sortable: false,
      keyCreator: params => params.value.id,
      comparator: (a, b) => a.name.localeCompare(b.name),
      minWidth: 200,
      cellRenderer: cellRendererCmp('unit_price_type'),
      filter: 'agSetColumnFilter',
      filterParams: {
        values: unitPriceTypes,
        keyCreator: (params: KeyCreatorParams) => params.value.id,
        valueFormatter: (params: ValueFormatterParams) => params.value.name
      }
    },
    {
      field: 'unit_price',
      headerName: 'Unit Price',
      cellDataType: 'number',
      tooltipComponent: UnitPriceTooltip,
      tooltipComponentParams: { channels },
      tooltipField: 'unit_price',
      cellRenderer: (params: CellRendererProps<number>) => (
        <UnitPriceCellRenderer channels={channels} {...params} />
      )
    },
    {
      field: 'target_margin',
      headerName: 'Target Margin',
      cellDataType: 'number',
      tooltipComponent: TargetMarginTooltip,
      tooltipComponentParams: { channels },
      tooltipField: 'target_margin',
      cellRenderer: (params: CellRendererProps<number>) => (
        <TargetMarginCellRenderer channels={channels} {...params} />
      )
    },
    {
      field: 'estimated_units',
      headerName: 'Estimated Units',
      cellDataType: 'number',
      cellRenderer: EstimatedUnitsRenderer
    },
    {
      field: 'unit_cost',
      headerName: 'Unit Cost',
      cellRenderer: UnitCostsRenderer
    },
    {
      field: 'media_budget',
      headerName: 'Media Budget',
      cellRenderer: MediaBudgetRenderer
    },
    {
      field: 'geo',
      cellRenderer: cellRendererCmp('geo'),
      sortable: false
    },
    {
      field: 'targeting',
      cellRenderer: cellRendererCmp('targeting'),
      sortable: false
    },
    {
      field: 'audience',
      cellRenderer: cellRendererCmp('audience'),
      sortable: false
    },
    {
      field: 'ad_formats',
      headerName: 'Ad Formats',
      cellRenderer: cellRendererCmp('ad_formats'),
      sortable: false
    },
    {
      field: 'media_platforms',
      headerName: 'Media Platforms',
      cellRenderer: cellRendererCmp('media_platforms')
    },
    {
      cellDataType: 'date',
      field: 'start_date',
      headerName: 'Start date',
      valueFormatter: params => formatDate(params.data?.start_date),
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset'],
        filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
        maxNumConditions: 1
      }
    },
    {
      cellDataType: 'date',
      field: 'end_date',
      headerName: 'End date',
      valueFormatter: params => formatDate(params.data?.end_date),
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset'],
        filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
        maxNumConditions: 1
      }
    },
    {
      field: 'pacing_type',
      headerName: 'Pacing Type',
      filter: 'agSetColumnFilter',
      filterParams: { values: ['lifetime', 'monthly', 'custom'] },
      sortable: false
    },
    {
      field: 'pacing_graph',
      headerName: 'Pacing Details',
      sortable: false,
      minWidth: 200,
      cellRendererParams: { sparklineOptions: { type: 'area' } },
      cellRendererSelector: ({ data }) => {
        if (data?.pacing_details?.blocks.length === 0) return { component: EmptyPacingDetails };
        if (data?.pacing_details) return { component: 'agSparklineCellRenderer' };
        else return { component: NullPacingDetails };
      },
      onCellClicked: event => {
        if (event.data?.id && event.data?.pacing_type === 'custom')
          useStore.getState().setLineItemPacing({
            lineItemId: event.data.id,
            open: true
          });
      }
    },
    {
      field: 'media_traders',
      headerName: 'Media Traders',
      sortable: false,
      valueFormatter: params => {
        return params.data?.media_traders?.map(mt => mt.name).join(',') || '';
      },
      comparator: (a, b) => {
        if (a.length === 0 && b.length === 0) return 0;
        if (a.length === 0) return -1;
        if (b.length === 0) return 1;
        return a[0].name.localeCompare(b[0].name);
      },
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains', 'notContains'],
        maxNumConditions: 1
      }
    }
  ];
}

function getCurrentSort(sortModel: SortModelItem[]): LineItemSort | undefined {
  if (sortModel.length === 0) return undefined;
  const { sort, colId } = sortModel[0];
  if (lineItemSortColumns.includes(colId)) {
    // TODO[mk]: properly refine type
    return { colId: colId as LineItemSortColumn, sort };
  } else return undefined;
}

function defaultFilters(): LineItemListRequest {
  return { page: 1, filters: {} };
}

function cellRendererCmp(key: keyof LineItemListRow) {
  return (props: CustomCellRendererProps<LineItemListRow>) => {
    return getValueString(props.data?.[key]) || <div className="text-blue-500">-</div>;
  };
}

function isMetaKeyPressed(event: Event | null | undefined): boolean {
  return event instanceof PointerEvent && (event.metaKey || event.ctrlKey);
}
