export function equalToTwoPlaces(
  value1: number | null | undefined,
  value2: number | null | undefined
): boolean {
  // True if both are null, false if one is null but not the other
  if (value1 == null) {
    return value2 == null;
  } else if (value2 == null) {
    return false;
  }

  // Compare the two values to two decimal places
  return value1.toFixed(2) !== value2.toFixed(2);
}
