import { MediaPlansTableData } from './media-plans-table';
import { uniq } from 'lodash';
import { cpm } from 'shared/src/channel-tactic-rates';

export function generateMediaPlansTotalRow(tableData: MediaPlansTableData[]): MediaPlansTableData {
  const totalTargetCost = tableData
    .map(row => row.totalTargetUnits * row.target_unit_cost)
    .reduce(sum, 0);
  const totalRevenue = tableData.map(row => row.revenue).reduce(sum, 0);
  const numPlatforms = uniq(
    tableData.map(row => row.media_buy?.media_platform_id).filter(Boolean)
  ).length;
  const totalBudget = tableData.map(row => row.budget).reduce(sum, 0);
  const totalTargetUnits = tableData.map(row => row.totalTargetUnits).reduce(sum, 0);
  const currentTargetUnits = tableData.map(row => row.currentTargetUnits).reduce(sum, 0);
  const currentPlannedSpend = tableData.map(row => row.currentPlannedSpend).reduce(sum, 0);
  const target_unit_cost = totalTargetUnits ? totalTargetCost / totalTargetUnits : 0;
  const totalSpend = tableData.map(row => row.mediaSpend).reduce(sum, 0);
  const deliveredUnits = tableData.map(row => row.deliveredUnits).reduce(sum, 0);
  const totalTargetRevenue = tableData.map(row => row.totalTargetRevenue).reduce(sum, 0);
  const targetMargin = totalTargetRevenue
    ? (totalTargetRevenue - totalBudget) / totalTargetRevenue
    : 0;
  const deliveryPacing = deliveredUnits / currentTargetUnits;
  const spendPacing = totalSpend / currentPlannedSpend;
  const margin = (totalRevenue - totalSpend) / totalRevenue;
  const marginPerformance = margin / targetMargin;

  return {
    id: '__TOTAL_ROW__',
    name: '',
    budget: totalBudget,
    target_unit_cost,
    button: '',
    deliveryPacing,
    spendPacing,
    margin,
    marginPerformance,
    targetMargin,
    totalTargetUnits,
    deliveredUnits,
    currentTargetUnits,
    currentPlannedSpend,
    mediaSpend: totalSpend,
    revenue: totalRevenue,
    totalTargetRevenue,
    state: { type: 'unchanged' },
    isTotal: true,
    numPlatforms,
    unit_price_type: tableData[0]?.unit_price_type || cpm,
    is_deleted: false,
    actions: ''
  };
}

function sum(a: number, b: number) {
  return a + b;
}
