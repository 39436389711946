import { HeroMetric, HeroMetricMessage, HeroMetricsContainer } from '../../components/hero-metrics';
import React from 'react';
import { CampaignMetrics } from '../../metrics/metrics-types';

type Props = { metrics: CampaignMetrics };

export function CampaignHeroMetrics({ metrics }: Props) {
  const {
    deliveredRevenue,
    currentPlannedRevenue,
    mediaSpend,
    currentPlannedSpend,
    currentMargin,
    targetMargin,
    deliveryPacing,
    spendPacing,
    customPacing
  } = metrics;

  return (
    <HeroMetricsContainer>
      {customPacing && (
        <HeroMetricMessage
          message="At least one line item in this campaign uses a custom pacing schedule. "
          additionalMessage="Pacing metrics may be incorrect."
        />
      )}
      <div className="flex justify-between gap-1">
        <HeroMetric
          primary={{ name: 'Delivery Pacing', value: deliveryPacing }}
          other={[
            { type: 'currency', name: `Delivered`, value: deliveredRevenue },
            { type: 'currency', name: 'On-pace target', value: currentPlannedRevenue }
          ]}
        />
        <HeroMetric
          primary={{ name: 'Spend Pacing', value: spendPacing }}
          other={[
            { type: 'currency', name: `Media spend`, value: mediaSpend },
            { type: 'currency', name: 'On-pace spend', value: currentPlannedSpend }
          ]}
        />
        <HeroMetric
          primary={{ name: 'Margin', value: currentMargin }}
          other={[
            {
              type: 'margin',
              value: currentMargin,
              target: targetMargin
            },
            { type: 'percentage', name: 'Target Margin', value: targetMargin }
          ]}
        />
      </div>
    </HeroMetricsContainer>
  );
}
