import React from 'react';

export function NoPlatformBuysImage() {
  return (
    <svg
      width="247"
      height="180"
      viewBox="0 0 247 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8267_656797)">
        <path
          d="M247.859 165.397H0.419795C0.179912 165.397 0 165.216 0 164.976C0 164.736 0.179912 164.556 0.419795 164.556H247.919C248.158 164.556 248.338 164.736 248.338 164.976C248.338 165.216 248.098 165.397 247.859 165.397Z"
          fill="#D1D3D4"
        />
        <path
          d="M235.085 9.72059V134.646C235.085 139.871 230.827 144.135 225.609 144.135H22.6687C17.4513 144.135 13.1934 139.931 13.1934 134.706V9.78065C13.1934 4.55542 17.3913 0.291163 22.6087 0.291163H22.6687H225.609C230.827 0.231103 235.085 4.43531 235.085 9.72059Z"
          fill="white"
        />
        <path
          d="M225.609 144.556H22.6686C17.2113 144.556 12.7734 140.111 12.7734 134.646V9.7207C12.7734 4.25523 17.2113 -0.189209 22.6686 -0.189209H225.609C231.066 -0.189209 235.504 4.25523 235.504 9.7207V134.646C235.504 140.111 231.066 144.556 225.609 144.556ZM22.6686 0.651633C17.691 0.651633 13.613 4.73572 13.613 9.7207V134.646C13.613 139.631 17.6311 143.715 22.6686 143.715H225.609C230.587 143.715 234.665 139.631 234.665 134.646V9.7207C234.665 4.73572 230.647 0.651633 225.609 0.651633H22.6686Z"
          fill="#231F20"
        />
        <path
          d="M235.085 9.72069V16.5075H13.1934V9.72069C13.1934 4.49547 17.4513 0.231201 22.6687 0.231201C22.6687 0.231201 22.6687 0.231201 22.7287 0.231201H225.669C230.827 0.231201 235.085 4.43541 235.085 9.72069Z"
          fill="#231F20"
        />
        <path
          d="M235.085 16.8678H13.1933C13.0134 16.8678 12.8335 16.6877 12.8335 16.5075V9.7207C12.8335 4.25523 17.2114 -0.12915 22.6687 -0.12915H225.609C231.067 -0.12915 235.444 4.25523 235.444 9.7207V16.5075C235.444 16.6877 235.264 16.8678 235.085 16.8678ZM13.6131 16.0871H234.665V9.7207C234.665 4.67566 230.587 0.651633 225.609 0.591573H22.6687C17.6311 0.591573 13.6131 4.67566 13.5531 9.7207L13.6131 16.0871Z"
          fill="#231F20"
        />
        <path
          d="M103.509 16.5074L95.533 3.41431H52.774L43.8383 16.5074H13.1934V36.3873H235.085V16.5074H103.509Z"
          fill="#3F9FFD"
        />
        <path
          d="M235.085 36.8077H13.1932C12.9533 36.8077 12.7734 36.6275 12.7734 36.3873V16.4473C12.7734 16.2071 12.9533 16.0269 13.1932 16.0269H43.5983L52.414 3.17407C52.474 3.05395 52.5939 2.9939 52.7738 2.9939H95.4729C95.5928 2.9939 95.7728 3.05395 95.8327 3.17407L103.749 16.0269H235.085C235.324 16.0269 235.504 16.2071 235.504 16.4473V36.3873C235.504 36.6275 235.324 36.8077 235.085 36.8077ZM13.673 35.9669H234.665V16.8678H103.509C103.389 16.8678 103.209 16.8077 103.149 16.6876L95.293 3.83473H53.0137L44.198 16.6876C44.1381 16.8077 44.0181 16.8678 43.8382 16.8678H13.613L13.673 35.9669Z"
          fill="#231F20"
        />
        <path
          d="M23.5084 10.6214C24.767 10.6214 25.7873 9.59963 25.7873 8.33916C25.7873 7.07869 24.767 6.05688 23.5084 6.05688C22.2498 6.05688 21.2295 7.07869 21.2295 8.33916C21.2295 9.59963 22.2498 10.6214 23.5084 10.6214Z"
          fill="#3F9FFD"
        />
        <path
          d="M31.1241 10.6214C32.3827 10.6214 33.403 9.59963 33.403 8.33916C33.403 7.07869 32.3827 6.05688 31.1241 6.05688C29.8655 6.05688 28.8452 7.07869 28.8452 8.33916C28.8452 9.59963 29.8655 10.6214 31.1241 10.6214Z"
          fill="white"
        />
        <path
          d="M38.7408 10.6214C39.9994 10.6214 41.0197 9.59963 41.0197 8.33916C41.0197 7.07869 39.9994 6.05688 38.7408 6.05688C37.4822 6.05688 36.4619 7.07869 36.4619 8.33916C36.4619 9.59963 37.4822 10.6214 38.7408 10.6214Z"
          fill="white"
        />
        <path d="M210.856 77.0481H42.7588V95.6667H210.856V77.0481Z" fill="white" />
        <path
          d="M210.856 96.0869H42.7587C42.5188 96.0869 42.3389 95.9067 42.3389 95.6665V77.0479C42.3389 76.8076 42.5188 76.6274 42.7587 76.6274H210.856C211.096 76.6274 211.276 76.8076 211.276 77.0479V95.6665C211.276 95.9067 211.096 96.0869 210.856 96.0869ZM43.1785 95.2461H210.436V77.5283H43.1785V95.2461Z"
          fill="#231F20"
        />
        <path
          d="M188.907 96.0869C188.667 96.0869 188.487 95.9067 188.487 95.6665C188.547 88.6995 188.667 78.189 188.487 77.228C188.487 77.168 188.487 77.1079 188.487 77.0479C188.487 76.8076 188.727 76.6274 188.967 76.6274C189.087 76.6274 189.147 76.6875 189.207 76.7476C189.387 76.9277 189.567 77.1079 189.327 95.6665C189.327 95.9067 189.147 96.0869 188.907 96.0869Z"
          fill="#231F20"
        />
        <path
          d="M46.357 21.1321H213.195C215.174 21.1321 216.794 22.7537 216.794 24.7357V28.3994C216.794 30.3813 215.174 32.003 213.195 32.003H46.357C44.378 32.003 42.7588 30.3813 42.7588 28.3994V24.7357C42.7588 22.7537 44.378 21.1321 46.357 21.1321Z"
          fill="white"
        />
        <path
          d="M208.998 28.9999C208.938 28.9999 208.818 28.9999 208.758 28.9399C208.638 28.8197 208.578 28.6996 208.578 28.5194L208.878 26.7176L207.558 25.4564C207.378 25.2762 207.378 25.0359 207.558 24.8558C207.618 24.7957 207.738 24.7356 207.798 24.7356L209.597 24.4954L210.437 22.8738C210.497 22.7537 210.677 22.6335 210.797 22.6335C210.977 22.6335 211.097 22.7537 211.157 22.8738L211.996 24.4954L213.795 24.7356C214.035 24.7957 214.215 24.9759 214.155 25.2161C214.155 25.3362 214.095 25.3963 214.035 25.4564L212.716 26.7176L213.016 28.5194C213.076 28.6996 212.956 28.8197 212.836 28.9399C212.716 29.06 212.536 29.06 212.416 28.9999L210.797 28.1591L209.177 28.9999C209.058 28.9999 208.998 28.9999 208.998 28.9999ZM208.758 25.3963L209.597 26.2371C209.717 26.3573 209.717 26.4774 209.717 26.5975L209.537 27.7987L210.617 27.2582C210.737 27.1981 210.857 27.1981 210.977 27.2582L212.056 27.7987L211.876 26.5975C211.876 26.4774 211.876 26.2972 211.996 26.2371L212.836 25.3963L211.636 25.2161C211.516 25.2161 211.396 25.096 211.336 24.9759L210.797 23.8948L210.257 24.9759C210.197 25.096 210.077 25.2161 209.957 25.2161L208.758 25.3963Z"
          fill="#D1D3D4"
        />
        <path
          d="M229.867 23.4143H223.57C223.33 23.4143 223.15 23.2342 223.15 22.9939C223.15 22.7537 223.33 22.5735 223.57 22.5735H229.867C230.107 22.5735 230.287 22.7537 230.287 22.9939C230.287 23.1741 230.107 23.4143 229.867 23.4143Z"
          fill="#231F20"
        />
        <path
          d="M229.867 26.1773H223.57C223.33 26.1773 223.15 25.9971 223.15 25.7568C223.15 25.5166 223.33 25.3364 223.57 25.3364H229.867C230.107 25.3364 230.287 25.5166 230.287 25.7568C230.287 25.9971 230.107 26.1773 229.867 26.1773Z"
          fill="#231F20"
        />
        <path
          d="M229.867 29H223.57C223.33 29 223.15 28.8198 223.15 28.5796C223.15 28.3394 223.33 28.1592 223.57 28.1592H229.867C230.107 28.1592 230.287 28.3394 230.287 28.5796C230.287 28.8198 230.107 29 229.867 29Z"
          fill="#231F20"
        />
        <path
          d="M110.525 13.5043C110.285 13.5043 110.105 13.3242 110.105 13.0839V6.4773C110.105 6.23706 110.285 6.05688 110.525 6.05688C110.765 6.05688 110.945 6.23706 110.945 6.4773V13.0839C110.945 13.3242 110.765 13.5043 110.525 13.5043Z"
          fill="#D1D3D4"
        />
        <path
          d="M113.884 10.2012H107.227C106.987 10.2012 106.807 10.021 106.807 9.78077C106.807 9.54053 106.987 9.36035 107.227 9.36035H113.884C114.124 9.36035 114.303 9.54053 114.303 9.78077C114.303 10.021 114.064 10.2012 113.884 10.2012Z"
          fill="#D1D3D4"
        />
        <path
          d="M24.7075 27.6184H18.7704C18.5305 27.6184 18.3506 27.4383 18.3506 27.198C18.3506 26.9578 18.5305 26.7776 18.7704 26.7776H24.7075C24.9474 26.7776 25.1273 26.9578 25.1273 27.198C25.1273 27.4383 24.9474 27.6184 24.7075 27.6184Z"
          fill="#231F20"
        />
        <path
          d="M20.3298 30.4413C20.2099 30.4413 20.0899 30.3812 20.03 30.2611L17.7511 27.4383C17.6311 27.2581 17.6311 27.0779 17.7511 26.8978L20.2099 24.0149C20.3898 23.8347 20.6297 23.8347 20.8096 23.9548C20.9895 24.135 20.9895 24.3752 20.8696 24.5554L18.6506 27.138L20.6896 29.6605C20.8096 29.8407 20.8096 30.0809 20.6297 30.2611C20.5097 30.3812 20.4498 30.4413 20.3298 30.4413Z"
          fill="#231F20"
        />
        <path
          d="M33.9432 27.6184H27.9462C27.7063 27.6184 27.5264 27.4383 27.5264 27.198C27.5264 26.9578 27.7063 26.7776 27.9462 26.7776H33.9432C34.1831 26.7776 34.363 26.9578 34.363 27.198C34.363 27.4383 34.1831 27.6184 33.9432 27.6184Z"
          fill="#231F20"
        />
        <path
          d="M32.3237 30.4415C32.0839 30.4415 31.904 30.2613 31.904 30.0211C31.904 29.9009 31.9639 29.8409 32.0239 29.7808L34.0629 27.2583L31.844 24.6757C31.6641 24.4955 31.724 24.2553 31.904 24.0751C32.0839 23.8949 32.3237 23.955 32.5037 24.1352L34.9625 27.018C35.0824 27.1982 35.0824 27.3784 34.9625 27.5586L32.6836 30.3814C32.5636 30.3814 32.4437 30.4415 32.3237 30.4415Z"
          fill="#231F20"
        />
        <path
          d="M198.622 88.8798C196.404 88.8798 194.544 87.078 194.544 84.7958C194.544 82.5135 196.344 80.7117 198.622 80.7117C200.841 80.7117 202.7 82.5135 202.7 84.7958C202.7 85.8768 202.281 86.8979 201.501 87.6786C200.781 88.4594 199.702 88.8798 198.622 88.8798ZM198.622 81.6126C197.783 81.6126 196.943 81.9729 196.344 82.5735C195.744 83.1741 195.384 84.015 195.384 84.8558C195.384 85.6967 195.744 86.5375 196.344 87.1381C197.603 88.3393 199.642 88.3393 200.901 87.1381C202.161 85.8768 202.161 83.8348 200.901 82.5735C200.302 81.9129 199.522 81.6126 198.622 81.6126Z"
          fill="#231F20"
        />
        <path
          d="M205.339 92.0029C205.219 92.0029 205.099 91.9429 205.04 91.8828L200.902 87.7387C200.722 87.5585 200.722 87.3182 200.902 87.1381C201.081 86.9579 201.321 86.9579 201.501 87.1381L205.639 91.2822C205.819 91.4624 205.819 91.7026 205.639 91.8828C205.579 91.9429 205.459 92.0029 205.339 92.0029Z"
          fill="#231F20"
        />
        <path
          d="M101.65 112.543H146.388C148.127 112.543 149.566 113.985 149.566 115.727V121.552C149.566 123.294 148.127 124.736 146.388 124.736H101.65C99.9105 124.736 98.4712 123.294 98.4712 121.552V115.727C98.4712 113.985 99.9105 112.543 101.65 112.543Z"
          fill="#3F9FFD"
        />
        <path
          d="M117.182 86.7776H50.9745C50.7346 86.7776 50.5547 86.5974 50.5547 86.3572C50.5547 86.117 50.7346 85.9368 50.9745 85.9368H117.182C117.422 85.9368 117.602 86.117 117.602 86.3572C117.602 86.5974 117.422 86.7776 117.182 86.7776Z"
          fill="#D1D3D4"
        />
        <path
          d="M63.1282 82.6296L55.498 88.3564L59.6339 93.8834L67.2641 88.1565L63.1282 82.6296Z"
          fill="white"
        />
        <path
          d="M59.6707 94.2852C59.5508 94.2852 59.4309 94.2251 59.3109 94.105L55.1729 88.5795C55.053 88.3993 55.053 88.1591 55.2329 87.9789L62.8492 82.2732C63.0291 82.1531 63.269 82.153 63.4489 82.3332L67.5869 87.8588C67.6468 87.9188 67.7068 88.0389 67.6468 88.1591C67.6468 88.2792 67.5869 88.3392 67.4669 88.4594L59.8507 94.1651C59.8507 94.2251 59.7307 94.2852 59.6707 94.2852ZM56.1325 88.3993L59.7907 93.2642L66.7473 88.099L63.0891 83.2341L56.1325 88.3993Z"
          fill="#231F20"
        />
        <path
          d="M56.2277 89.2544L46.918 96.2419L49.6513 99.8945L58.961 92.907L56.2277 89.2544Z"
          fill="#231F20"
        />
        <path
          d="M49.6557 100.291C49.5357 100.291 49.4158 100.231 49.3558 100.171L46.6571 96.5675C46.5972 96.5075 46.5972 96.3874 46.5972 96.2672C46.5972 96.1471 46.6571 96.0871 46.7171 96.027L56.0126 89.06C56.1925 88.9399 56.4324 88.9399 56.5523 89.1201L59.3109 92.7838C59.3709 92.8438 59.3709 92.9639 59.3709 93.0841C59.3709 93.2042 59.3109 93.2642 59.251 93.3243L49.9555 100.291C49.7756 100.291 49.7156 100.291 49.6557 100.291ZM47.4368 96.3273L49.7156 99.3904L58.4114 92.8438L56.1325 89.7808L47.4368 96.3273Z"
          fill="#231F20"
        />
        <path
          d="M65.4275 84.9158C60.39 78.189 61.7693 68.5794 68.486 63.5344C75.2027 58.4893 84.798 59.8707 89.8355 66.5974C94.8731 73.3242 93.4937 82.9338 86.777 87.9788C80.0603 93.0239 70.465 91.6425 65.4275 84.9158ZM92.7741 64.3752C86.5371 56.0269 74.663 54.2851 66.3271 60.5914C57.9911 66.8377 56.252 78.7296 62.5489 87.0779C68.7859 95.4263 80.66 97.168 88.9959 90.8617C97.3319 84.6155 99.011 72.7836 92.7741 64.3752Z"
          fill="#3F9FFD"
        />
        <path
          d="M77.6618 95.1261C76.7023 95.1261 75.8027 95.066 74.9031 94.9459C64.3483 93.4444 57.0919 83.6546 58.5911 73.084C60.0904 62.5134 69.8656 55.2462 80.4204 56.7477C90.9753 58.2492 98.2317 68.039 96.7324 78.6095C95.3531 88.039 87.2571 95.066 77.6618 95.1261ZM77.6018 57.2882C67.4068 57.2882 59.1309 65.6366 59.1908 75.8468C59.2508 86.057 67.5268 94.3453 77.7218 94.2852C87.9168 94.2852 96.1927 85.9369 96.1327 75.7266C96.1327 71.7627 94.8134 67.8588 92.4146 64.6756C88.9363 59.9909 83.419 57.2282 77.6018 57.2882ZM77.6618 91.4624C72.7442 91.4624 68.0665 89.12 65.1279 85.2161C59.9105 78.2492 61.2898 68.3993 68.2464 63.2342C75.203 58.069 85.0382 59.3903 90.1956 66.3573C95.4131 73.3243 94.0338 83.1741 87.0772 88.3393C84.3785 90.3212 81.0801 91.4624 77.6618 91.4624ZM65.7876 84.6756C70.7052 91.2221 80.0006 92.6035 86.5374 87.6786C93.0742 82.7537 94.4536 73.4444 89.536 66.8978C84.6184 60.3513 75.3229 58.9699 68.7861 63.8948C62.1894 68.8197 60.87 78.1291 65.7876 84.6756Z"
          fill="#231F20"
        />
        <path
          d="M22.4886 119.21L20.8094 116.928C20.1497 116.027 20.3297 114.766 21.2292 114.045L46.117 95.3662C47.0166 94.7055 48.276 94.8857 48.9956 95.7866L50.6748 98.0689C51.3345 98.9698 51.1546 100.231 50.255 100.952L25.3672 119.63C24.4077 120.291 23.1483 120.111 22.4886 119.21Z"
          fill="white"
        />
        <path
          d="M24.108 120.471C23.9881 120.471 23.8681 120.471 23.7482 120.471C23.0885 120.351 22.4888 120.051 22.129 119.51L20.4498 117.228C20.03 116.687 19.9101 116.027 19.97 115.426C20.09 114.766 20.3898 114.165 20.9296 113.805L45.8174 95.1259C46.3571 94.7655 46.9568 94.5854 47.6165 94.6454C48.2762 94.7655 48.8759 95.0658 49.2357 95.6064L50.9149 97.8887C51.3347 98.4292 51.4546 99.0899 51.3946 99.6905C51.2747 100.351 50.9748 100.952 50.4351 101.312L25.5473 119.931C25.1875 120.231 24.6477 120.411 24.108 120.471ZM47.3166 95.4262C46.9568 95.4262 46.597 95.5463 46.3571 95.7265L21.4693 114.405C21.1095 114.645 20.8696 115.066 20.8096 115.486C20.7497 115.907 20.8696 116.327 21.1095 116.687L22.7887 118.97C23.3284 119.69 24.3479 119.811 25.0675 119.27L49.9553 100.591C50.3152 100.351 50.5551 99.9307 50.615 99.5103C50.675 99.0899 50.5551 98.6694 50.3152 98.3091L48.636 96.0268C48.3961 95.6664 47.9763 95.4262 47.5565 95.3661L47.3166 95.4262Z"
          fill="#231F20"
        />
        <path
          d="M227.588 134.466C227.648 133.264 227.408 132.123 226.868 131.042C226.389 130.261 225.729 129.661 225.309 128.88C224.65 127.499 225.129 125.877 224.949 124.315C224.829 123.054 224.23 121.853 223.33 120.952C222.551 120.171 221.531 119.571 220.751 118.73C219.492 117.348 219.132 115.426 218.413 113.685C217.393 111.222 215.654 109.18 213.435 107.799C214.814 110.562 211.696 114.045 213.075 116.748C213.495 117.589 214.395 118.249 214.694 119.15C215.474 121.372 212.835 123.715 213.675 125.937C214.095 127.018 215.234 127.679 215.774 128.7C216.493 130.081 215.954 131.763 216.014 133.384C216.074 135.547 217.393 137.529 219.012 139.03C219.972 139.991 221.111 140.772 222.131 141.613C223.39 142.634 223.93 143.715 225.069 142.093C226.688 139.811 227.528 137.168 227.588 134.466Z"
          fill="#231F20"
        />
        <path
          d="M228.968 135.727C228.548 134.045 228.248 132.243 228.968 130.622C229.747 128.82 231.666 127.559 231.906 125.637C232.026 124.375 231.426 123.054 231.666 121.793C231.906 120.531 232.926 119.691 233.765 118.73C235.684 116.568 236.884 113.865 237.064 110.922C238.263 112.543 239.522 114.165 240.182 116.087C240.842 118.009 240.842 120.231 239.702 121.913C239.403 122.333 239.103 122.754 238.923 123.234C238.563 124.495 239.582 125.637 240.122 126.838C240.782 128.219 240.602 129.841 239.762 131.042C238.623 132.423 236.524 132.964 235.984 134.646C235.864 135.246 235.804 135.907 235.804 136.568C235.564 138.85 233.945 140.772 231.786 141.372C230.467 141.673 230.527 141.192 230.287 140.051C229.927 138.61 229.327 137.168 228.968 135.727Z"
          fill="#231F20"
        />
        <path
          d="M224.83 123.595C223.93 120.351 223.51 116.988 222.551 113.745C221.711 111.222 220.692 108.76 219.492 106.417L223.51 108.099C224.77 108.64 226.209 109.3 226.809 110.562C227.888 112.724 225.969 115.547 227.108 117.709C227.468 118.49 228.188 119.03 228.668 119.691C229.867 121.372 229.687 123.655 229.028 125.577C228.728 126.417 227.528 130.682 226.449 129.481C226.029 129 225.969 127.198 225.789 126.598C225.429 125.637 225.129 124.616 224.83 123.595Z"
          fill="#231F20"
        />
        <path
          d="M226.928 166.538C226.149 161.252 223.63 142.634 223.63 142.454L224.469 142.333C224.469 142.514 226.988 161.132 227.768 166.418L226.928 166.538Z"
          fill="#231F20"
        />
        <path
          d="M228.008 171.522H227.168C227.228 165.216 229.867 141.192 231.366 139.571L231.966 140.111C230.827 141.432 228.068 164.616 228.008 171.522Z"
          fill="#231F20"
        />
        <path
          d="M229.387 170.381L228.547 170.321C229.147 156.808 226.269 128.399 226.269 128.099L227.108 128.039C227.108 128.339 229.987 156.808 229.387 170.381Z"
          fill="#231F20"
        />
        <path
          d="M220.152 151.402H235.085L234.065 168.339C233.885 171.282 231.726 173.565 229.087 173.565H226.209C223.57 173.565 221.411 171.282 221.231 168.339L220.152 151.402Z"
          fill="white"
        />
        <path
          d="M229.027 173.985H226.149C223.33 173.985 220.931 171.523 220.751 168.34L219.732 151.403C219.732 151.162 219.912 150.982 220.152 150.922C220.152 150.922 220.152 150.922 220.212 150.922H235.144C235.264 150.922 235.384 150.982 235.444 151.042C235.504 151.102 235.564 151.222 235.564 151.343L234.545 168.279C234.245 171.523 231.846 173.985 229.027 173.985ZM220.572 151.823L221.591 168.34C221.771 171.042 223.75 173.144 226.149 173.144H229.027C231.426 173.144 233.405 171.042 233.585 168.34L234.605 151.823H220.572Z"
          fill="#231F20"
        />
        <path
          d="M41.9189 106.237L46.5367 110.982C46.5367 110.982 53.0735 101.252 52.9535 100.111C52.7736 98.069 52.9535 95.9669 53.4333 93.9849C53.4333 93.8648 53.4933 93.7446 53.6132 93.6846C53.8531 93.5645 54.093 93.8648 54.2129 94.105C54.4528 94.7657 54.4528 95.4263 54.6327 96.087C55.6522 95.4864 56.6717 94.7657 57.6912 94.105C59.3704 93.0239 58.8906 97.1681 58.7107 97.7086C58.3509 99.2101 57.6312 101.012 56.3119 101.913C56.3119 101.913 48.4557 119.45 46.7166 119.27C44.9774 119.09 38.2607 111.462 38.2607 111.462L41.9189 106.237Z"
          fill="white"
        />
        <path
          d="M46.7168 119.691C44.8577 119.511 39.1005 113.084 37.961 111.763C37.8411 111.583 37.8411 111.402 37.961 111.222L41.5593 105.997C41.6193 105.877 41.7392 105.817 41.8591 105.817C41.9791 105.817 42.099 105.877 42.219 105.937L46.4769 110.321C49.4754 105.817 52.5339 100.832 52.5339 100.111C52.354 98.009 52.5339 95.907 53.0137 93.8649C53.0736 93.6247 53.1936 93.3844 53.3735 93.2643C53.5534 93.1442 53.7933 93.1442 53.9732 93.2042C54.2731 93.3244 54.5129 93.6247 54.6329 93.925C54.7528 94.2853 54.8128 94.6457 54.8728 95.006C54.8728 95.1262 54.9327 95.2463 54.9327 95.3664C55.4125 95.0661 55.8923 94.7658 56.372 94.4054L57.5115 93.6847C57.8713 93.3844 58.3511 93.3844 58.7709 93.6247C59.8503 94.4055 59.1906 97.7088 59.1906 97.7688C58.8308 99.2103 58.1112 101.072 56.7319 102.153C53.6734 108.76 48.5159 119.631 46.7168 119.691ZM38.8006 111.462C41.3794 114.345 45.6373 118.73 46.7168 118.85C47.7363 118.55 52.1741 109.901 55.8923 101.733C55.9522 101.673 55.9522 101.613 56.0122 101.553C57.2716 100.652 57.9313 98.9099 58.2311 97.5886C58.411 96.8078 58.531 94.6457 58.1112 94.3454C58.1112 94.3454 57.9912 94.3454 57.8113 94.4655L56.7319 95.1862C56.0722 95.6066 55.4125 96.0871 54.7528 96.4475C54.6329 96.5075 54.5129 96.5075 54.393 96.4475C54.2731 96.3874 54.2131 96.3274 54.1531 96.2072C54.0332 95.9069 53.9732 95.5466 53.9132 95.2463C53.8533 94.946 53.7933 94.6457 53.7333 94.3454C53.3135 96.2673 53.1336 98.1892 53.3135 100.111C53.4335 101.312 48.3959 108.94 46.8367 111.282C46.7767 111.402 46.6568 111.462 46.5368 111.462C46.4169 111.462 46.297 111.402 46.177 111.342L41.9191 106.958L38.8006 111.462Z"
          fill="#231F20"
        />
        <path
          d="M11.5141 174.586C11.5141 174.586 9.8949 178.97 10.6145 179.39C11.3342 179.811 25.5472 179.391 24.6477 178.55C23.7481 177.709 20.0299 174.826 20.0299 174.826"
          fill="white"
        />
        <path
          d="M14.0928 179.991C12.1737 179.991 10.6745 179.931 10.4346 179.751C9.77492 179.33 10.0148 177.528 11.1542 174.405C11.2142 174.165 11.4541 174.045 11.694 174.165C11.9339 174.225 12.0538 174.465 11.9339 174.706C11.2142 176.628 10.7345 178.549 10.9144 179.03C12.1138 179.33 21.829 179.03 24.0479 178.489C22.8485 177.408 19.85 175.186 19.79 175.186C19.6101 175.066 19.5501 174.766 19.73 174.585C19.85 174.405 20.1498 174.345 20.3298 174.525C20.5097 174.646 24.1679 177.408 25.0075 178.249C25.1874 178.429 25.2473 178.73 25.0674 178.91C24.4677 179.631 18.2308 179.991 14.0928 179.991Z"
          fill="#231F20"
        />
        <path
          d="M21.7092 91.4624C21.0495 91.5224 20.3898 91.5825 19.5502 91.6425C18.7106 91.7026 17.8711 91.6425 17.6911 90.9218C17.5712 90.3212 18.2309 89.6605 17.931 89.12C17.8711 88.9999 17.7511 88.8197 17.5712 88.7597C14.9325 86.2972 14.6926 82.2131 17.0914 79.4503C16.9115 79.5104 16.6716 79.4503 16.6117 79.2101C16.6117 79.2101 16.6117 79.2101 16.6117 79.15C16.5517 78.9699 16.6117 78.7296 16.7316 78.5494C17.1514 77.8287 17.8111 77.2882 18.5307 76.9278C20.8096 75.7266 23.3884 75.3062 25.9671 75.6065C27.8861 75.8467 30.4049 76.8678 30.5848 79.15C30.6448 79.9909 30.165 80.7717 29.3254 81.072C29.3254 83.5344 28.3659 85.3963 27.1065 87.4383C26.2669 88.8197 25.0675 89.9608 23.6882 90.8017C23.1485 91.102 22.4288 91.3422 21.7092 91.4624Z"
          fill="white"
        />
        <path
          d="M19.1903 92.0631C18.1708 92.0631 17.4512 91.6426 17.3312 90.982C17.2712 90.6216 17.3312 90.2613 17.5111 89.9009C17.6311 89.6006 17.691 89.4204 17.6311 89.3003C17.5711 89.2402 17.5111 89.1201 17.4512 89.0601C14.8124 86.5976 14.3927 82.5736 16.4916 79.6306C16.3717 79.5105 16.3117 79.3904 16.3117 79.2703C16.2517 78.97 16.3117 78.6096 16.4916 78.3093C16.9714 77.5285 17.6311 76.9279 18.4107 76.5676C20.7495 75.3664 23.4482 74.8859 26.0869 75.1862C28.4258 75.4264 30.8846 76.7477 31.0645 79.1501C31.1245 80.051 30.6447 80.9519 29.8051 81.3724C29.7451 83.8949 28.6657 85.8769 27.5262 87.6787C26.6267 89.1201 25.4272 90.3213 23.988 91.1622C23.3283 91.5225 22.6086 91.7628 21.829 91.8829C21.4092 91.9429 21.0494 91.9429 20.6296 92.003C20.3297 92.003 20.0299 92.0631 19.73 92.0631H19.1903ZM17.1513 78.97C17.2712 78.97 17.3912 79.03 17.4512 79.1501C17.5711 79.3303 17.5711 79.5105 17.4512 79.6907C15.2322 82.2733 15.4721 86.1171 17.9309 88.3994C18.1108 88.5195 18.2308 88.6997 18.3507 88.9399C18.5306 89.3603 18.4707 89.8408 18.2907 90.2012C18.1708 90.3814 18.1708 90.6216 18.1708 90.8018C18.2308 91.2222 19.0704 91.2823 19.5501 91.2222C19.9099 91.1622 20.2098 91.1622 20.5097 91.1622C20.9294 91.1622 21.2893 91.1021 21.6491 91.042C22.3088 90.982 22.9684 90.7417 23.5082 90.4414C24.8275 89.6606 25.967 88.5796 26.7466 87.2583C28.1259 85.0961 28.9055 83.3543 28.9055 81.1321C28.9055 80.9519 29.0255 80.7718 29.2654 80.7117C29.8651 80.4715 30.2249 79.8709 30.1649 79.2703C30.045 77.1081 27.4662 76.2673 25.967 76.0871C23.5082 75.7868 20.9894 76.2072 18.7705 77.3483C18.1108 77.6486 17.5711 78.1291 17.1513 78.7297C17.0913 78.7898 17.0913 78.9099 17.0314 79.03L17.1513 78.97Z"
          fill="#231F20"
        />
        <path
          d="M13.6132 105.036C13.6132 105.036 8.39576 115.727 8.75558 118.97C9.11541 122.213 19.9701 120.952 19.9701 120.952L19.3704 104.736L13.6132 105.036Z"
          fill="white"
        />
        <path
          d="M15.5921 121.612C12.4137 121.612 8.63554 121.132 8.39566 118.97C7.97586 115.606 13.0734 105.216 13.2533 104.796C13.3132 104.676 13.4332 104.555 13.6131 104.555L19.4303 104.255C19.5502 104.255 19.6701 104.315 19.7301 104.375C19.7901 104.435 19.85 104.555 19.85 104.676L20.3898 120.892C20.3898 121.132 20.2099 121.312 20.03 121.312C18.5907 121.552 17.0914 121.612 15.5921 121.612ZM13.913 105.396C13.1933 106.958 8.87542 116.027 9.23525 118.85C9.47513 120.892 15.5921 120.952 19.6102 120.531L19.0704 105.156L13.913 105.396Z"
          fill="#231F20"
        />
        <path
          d="M34.4829 172.483L34.7828 179.39C34.7828 179.39 47.7364 179.511 47.0168 178.55C46.2971 177.589 40.42 173.444 40.42 173.444L39.4605 168.339L34.4829 172.483Z"
          fill="white"
        />
        <path
          d="M36.5818 179.871H34.7826C34.5428 179.871 34.3628 179.691 34.3628 179.45L34.063 172.543C34.063 172.423 34.123 172.303 34.1829 172.183L39.1005 167.979C39.2205 167.859 39.4004 167.859 39.5203 167.919C39.6403 167.979 39.7602 168.099 39.7602 168.219L40.6598 173.144C41.6793 173.865 46.4769 177.348 47.2565 178.249C47.3765 178.429 47.4364 178.61 47.3165 178.79C47.3165 179.15 46.9567 179.871 36.5818 179.871ZM35.2024 179.03C39.9401 179.03 44.9776 178.91 46.4169 178.549C45.2775 177.468 41.6193 174.826 40.18 173.805C40.12 173.745 40.06 173.625 40.0001 173.564L39.1605 169.18L34.9625 172.784L35.2024 179.03Z"
          fill="#231F20"
        />
        <path
          d="M36.8815 123.835L41.919 174.406L33.9429 173.865L26.2667 138.85L20.9893 174.886L11.334 174.406C11.334 174.406 12.1736 151.342 13.0731 143.655C13.9727 135.967 13.9727 125.757 13.9727 125.757L36.8815 123.835Z"
          fill="#231F20"
        />
        <path
          d="M30.645 84.4353C30.645 84.4353 32.8639 86.9578 32.5641 87.5584C32.2642 88.159 30.9449 87.9789 30.9449 87.9789"
          fill="white"
        />
        <path
          d="M31.3643 88.4595C31.1844 88.4595 31.0045 88.4595 30.8246 88.3994C30.5847 88.3394 30.4648 88.1592 30.4648 87.9189C30.5247 87.6787 30.7046 87.5586 30.9445 87.5586C31.3643 87.6186 32.024 87.5586 32.084 87.3784C32.084 87.0781 31.2444 85.8168 30.2249 84.6757C30.045 84.4955 30.1049 84.2553 30.2249 84.0751C30.4048 83.8949 30.6447 83.955 30.8246 84.1352C31.9041 85.3964 33.1634 87.018 32.8036 87.7388C32.5637 88.2793 32.024 88.5195 31.3643 88.4595Z"
          fill="#231F20"
        />
        <path
          d="M29.7453 80.8318C31.0647 81.6126 31.0047 83.6546 31.1846 84.9759C31.4245 86.8378 31.6044 88.6396 31.7844 90.5015C31.9043 91.6426 28.9058 92.1231 28.3061 91.8228L29.4455 96.3873L22.7888 94.8258L22.249 89.4804C22.249 89.4804 24.2281 89.2402 25.0676 86.6576C25.9072 84.075 25.3675 80.4714 25.3675 80.4714C25.3675 80.4714 28.486 80.051 29.7453 80.8318Z"
          fill="white"
        />
        <path
          d="M29.3853 96.8078C29.3253 96.8078 29.3253 96.8078 29.2654 96.8078L22.6086 95.3063C22.4287 95.2463 22.3088 95.1262 22.3088 94.946L21.769 89.6006C21.769 89.3604 21.889 89.1802 22.1289 89.1202C22.1888 89.1202 23.868 88.8199 24.6476 86.5976C25.4272 84.1952 24.8875 80.6517 24.8875 80.5916C24.8875 80.4715 24.8875 80.3514 24.9475 80.2913C25.0075 80.2313 25.1274 80.1712 25.2473 80.1111C25.6072 80.0511 28.6057 79.6307 29.985 80.4715C31.2444 81.2523 31.4243 82.8739 31.5442 84.1952C31.5442 84.4355 31.6042 84.6757 31.6042 84.916C31.8441 86.7778 32.084 88.6397 32.2039 90.4415C32.2639 90.922 32.024 91.3424 31.6042 91.5826C30.7646 92.0631 29.8051 92.3033 28.8456 92.3033L29.8051 96.2673C29.8651 96.3874 29.8051 96.5676 29.6852 96.6877C29.5652 96.8078 29.4453 96.8078 29.3853 96.8078ZM23.0884 94.5256L28.7856 95.8469L27.8261 92.003C27.7661 91.8229 27.8261 91.7027 27.946 91.5826C28.066 91.4625 28.2459 91.4625 28.3658 91.5226C29.2654 91.6427 30.2249 91.4024 31.0045 90.982C31.1844 90.8619 31.2444 90.7418 31.2444 90.6217C31.0645 88.7598 30.8846 86.958 30.6447 85.0961C30.6447 84.8559 30.5847 84.6157 30.5847 84.3754C30.4648 83.2343 30.3448 81.7928 29.4453 81.2523C28.5457 80.7118 26.7466 80.8319 25.6671 80.952C25.7871 82.0331 26.027 84.7958 25.3073 86.8979C24.9475 88.2793 23.868 89.4205 22.5487 89.9009L23.0884 94.5256Z"
          fill="#231F20"
        />
        <path
          d="M43.1785 106.658C43.1785 106.658 35.7421 98.7898 32.8035 95.8469C30.0449 93.0841 22.1288 90.6216 18.4706 94.5255C18.4706 94.5255 12.4735 102.874 11.394 107.679L14.8723 109.781C14.0927 115.487 13.6729 121.252 13.553 127.078C20.1498 132.303 36.9415 123.835 36.9415 123.835L35.4423 111.943L38.1409 114.646L43.1785 106.658Z"
          fill="#3F9FFD"
        />
        <path
          d="M31.484 89.1802C30.8843 89.7207 29.9247 89.6607 29.325 89.0601C29.2651 89 29.2051 88.9399 29.2051 88.8799"
          fill="white"
        />
        <path
          d="M30.4048 89.961C30.3449 89.961 30.2849 89.961 30.165 89.961C29.6252 89.9009 29.1455 89.5405 28.8456 89.1201C28.7257 88.9399 28.7856 88.6396 28.9655 88.5195C29.1455 88.3994 29.4453 88.4595 29.5653 88.6396C29.7452 88.8799 29.985 89.0601 30.2849 89.1201C30.6447 89.1201 30.9446 89.0601 31.2444 88.8198C31.4243 88.6997 31.6642 88.6997 31.8441 88.8799C32.024 89.0601 31.9641 89.3003 31.7842 89.4805C31.4243 89.7808 30.9446 89.961 30.4048 89.961Z"
          fill="#231F20"
        />
        <path
          d="M27.2868 86.1171C27.1668 85.6366 26.9269 85.2162 26.5671 84.9159C26.3272 84.6156 26.0873 84.3753 25.7875 84.1951C25.4876 84.015 25.1278 83.9549 24.768 84.015C24.4681 84.1351 24.2283 84.3753 24.1083 84.6756C23.9884 84.9759 23.9284 85.2762 23.9884 85.6366C24.1083 87.078 25.0079 88.2792 26.2673 88.8798L26.1473 88.7597"
          fill="white"
        />
        <path
          d="M26.3271 89.3003C26.2671 89.3003 26.2072 89.3003 26.1472 89.2402C24.7079 88.5795 23.7484 87.1982 23.5684 85.6366C23.5085 85.2762 23.5684 84.8558 23.6884 84.4955C23.8083 84.075 24.1682 83.7747 24.528 83.5946C25.0077 83.4144 25.4875 83.5345 25.9073 83.7747C26.2671 84.015 26.507 84.2552 26.8069 84.6156C27.1667 84.9759 27.4665 85.4564 27.5865 85.997C27.5865 86.2372 27.4665 86.4174 27.2267 86.4775C26.9868 86.4775 26.8069 86.3573 26.7469 86.1771C26.6269 85.8168 26.447 85.4564 26.1472 85.2162C25.9673 84.9759 25.7274 84.7357 25.4275 84.5555C25.2476 84.4354 25.0077 84.3753 24.7679 84.4354C24.5879 84.4955 24.468 84.6757 24.3481 84.8558C24.2881 85.0961 24.2281 85.3363 24.2881 85.6366C24.408 86.7777 25.0677 87.7988 26.0872 88.3994C26.1472 88.3994 26.2671 88.4594 26.3271 88.4594L26.447 88.5796C26.6269 88.6997 26.6269 89 26.507 89.1802C26.567 89.2402 26.447 89.3003 26.3271 89.3003Z"
          fill="#231F20"
        />
        <path
          d="M28.2458 91.8828C27.2863 91.7027 26.4467 91.2822 25.7271 90.6216C25.7271 90.6216 26.2068 93.3844 28.7256 93.8648L28.2458 91.8828Z"
          fill="#231F20"
        />
        <path
          d="M28.7258 94.3453H28.6659C25.9072 93.8048 25.3675 90.8618 25.3075 90.7417C25.2476 90.5015 25.4275 90.3213 25.6673 90.2612C25.7873 90.2612 25.9672 90.2612 26.0272 90.3813C26.6868 90.9819 27.4665 91.3423 28.3061 91.4624C28.486 91.4624 28.6059 91.5826 28.6659 91.7627L29.1456 93.8048C29.2056 93.9249 29.1456 94.1051 29.0257 94.1651C28.9657 94.2853 28.8458 94.3453 28.7258 94.3453ZM26.5669 91.7627C26.9267 92.4234 27.4665 92.9639 28.1861 93.2642L27.9462 92.2432C27.4665 92.1231 27.0467 92.003 26.5669 91.7627Z"
          fill="#231F20"
        />
        <path
          d="M35.5626 112.964C35.4427 112.964 35.3227 112.904 35.2628 112.844C33.3437 110.862 31.6046 108.82 29.9254 106.658C29.8054 106.478 29.8054 106.237 29.9854 106.057C30.1653 105.937 30.4052 105.937 30.5851 106.117C32.2043 108.219 34.0034 110.261 35.8625 112.183C36.0424 112.363 36.0424 112.604 35.8625 112.784C35.7425 112.964 35.6826 112.964 35.5626 112.964Z"
          fill="#231F20"
        />
        <path
          d="M29.6126 83.3246C29.6227 82.9599 29.5236 82.6612 29.3911 82.6576C29.2587 82.6539 29.1432 82.9466 29.1331 83.3113C29.1229 83.676 29.2221 83.9747 29.3545 83.9783C29.487 83.982 29.6025 83.6893 29.6126 83.3246Z"
          fill="#231F20"
        />
        <path
          d="M14.7523 108.52H14.6923C14.4525 108.46 14.3325 108.279 14.3325 108.039C14.3925 107.679 15.7718 99.0902 17.3311 96.2674C17.451 96.0872 17.6909 95.967 17.9308 96.0872C18.1107 96.2073 18.2306 96.4475 18.1107 96.6878C16.6114 99.4505 15.1721 108.099 15.1721 108.219C15.1121 108.399 14.9322 108.52 14.7523 108.52Z"
          fill="#231F20"
        />
      </g>
      <defs>
        <clipPath id="clip0_8267_656797">
          <rect width="247" height="180" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
