export const BEESWAX_MEDIA_PLATFORM_ID = 1;
export const TTD_MEDIA_PLATFORM_ID = 2;
export const FACEBOOK_MEDIA_PLATFORM_ID = 3;
export const GOOGLE_DV360_MEDIA_PLATFORM_ID = 4;
export const TIKTOK_MEDIA_PLATFORM_ID = 5;
export const AMAZON_DSP_MEDIA_PLATFORM_ID = 6;
export const VIANT_MEDIA_PLATFORM_ID = 7;
export const GOOGLE_SEARCH_MEDIA_PLATFORM_ID = 8;
export const YOUTUBE_MEDIA_PLATFORM_ID = 9;
export const SIMPLIFI_MEDIA_PLATFORM_ID = 10;
export const BING_MEDIA_PLATFORM_ID = 11;
export const SNAPCHAT_MEDIA_PLATFORM_ID = 12;
export const VISTAR_MEDIA_PLATFORM_ID = 13;
export const LINKEDIN_MEDIA_PLATFORM_ID = 14;
export const PINTEREST_MEDIA_PLATFORM_ID = 15;
export const GOOGLE_ADS_OTHER_MEDIA_PLATFORM_ID = 16;
export const MATCHCRAFT_MEDIA_PLATFORM_ID = 118;
export const X_TWITTER_MEDIA_PLATFORM_ID = 119;
export const AUDIOGO_MEDIA_PLATFORM_ID = 120;
export const SPOTIFY_MEDIA_PLATFORM_ID = 121;
export const NATIVO_MEDIA_PLATFORM_ID = 122;
export const SITE_IMPACT_MEDIA_PLATFORM_ID = 123;
export const CASTIRON_FIREBRAND_MEDIA_PLATFORM_ID = 124;
export const SPACEBACK_MEDIA_PLATFORM_ID = 125;
export const BEESWAX_TEST_ADVERTISER_ID = 90;

export function getPlatformName(id: string | number | undefined) {
  if (!id) return 'Unknown';
  switch (Number(id)) {
    case BEESWAX_MEDIA_PLATFORM_ID:
      return 'Beeswax';
    case TTD_MEDIA_PLATFORM_ID:
      return 'Trade Desk';
    case FACEBOOK_MEDIA_PLATFORM_ID:
      return 'Facebook';
    case GOOGLE_DV360_MEDIA_PLATFORM_ID:
      return 'Google DV360';
    case TIKTOK_MEDIA_PLATFORM_ID:
      return 'TikTok';
    case AMAZON_DSP_MEDIA_PLATFORM_ID:
      return 'Amazon DSP';
    case VIANT_MEDIA_PLATFORM_ID:
      return 'Viant';
    case GOOGLE_SEARCH_MEDIA_PLATFORM_ID:
      return 'Google Search';
    case YOUTUBE_MEDIA_PLATFORM_ID:
      return 'YouTube';
    case SIMPLIFI_MEDIA_PLATFORM_ID:
      return 'SimpliFi';
    case BING_MEDIA_PLATFORM_ID:
      return 'Bing';
    case SNAPCHAT_MEDIA_PLATFORM_ID:
      return 'Snapchat';
    case VISTAR_MEDIA_PLATFORM_ID:
      return 'Vistar';
    case LINKEDIN_MEDIA_PLATFORM_ID:
      return 'LinkedIn';
    case PINTEREST_MEDIA_PLATFORM_ID:
      return 'Pinterest';
    case GOOGLE_ADS_OTHER_MEDIA_PLATFORM_ID:
      return 'Google Ads Other';
    case MATCHCRAFT_MEDIA_PLATFORM_ID:
      return 'MatchCraft';
    case X_TWITTER_MEDIA_PLATFORM_ID:
      return 'X (Twitter)';
    case AUDIOGO_MEDIA_PLATFORM_ID:
      return 'AudioGo';
    case SPOTIFY_MEDIA_PLATFORM_ID:
      return 'Spotify';
    case NATIVO_MEDIA_PLATFORM_ID:
      return 'Nativo';
    case SITE_IMPACT_MEDIA_PLATFORM_ID:
      return 'Site Impact';
    case CASTIRON_FIREBRAND_MEDIA_PLATFORM_ID:
      return 'CastIron/Firebrand';
    case SPACEBACK_MEDIA_PLATFORM_ID:
      return 'Spaceback';
    case BEESWAX_TEST_ADVERTISER_ID:
      return 'Beeswax Test';
    default:
      return 'Unknown';
  }
}
export const MEDIA_PLATFORM_IDS = {
  [BEESWAX_MEDIA_PLATFORM_ID]: 'Beeswax',
  [TTD_MEDIA_PLATFORM_ID]: 'TTD',
  [FACEBOOK_MEDIA_PLATFORM_ID]: 'Facebook',
  [GOOGLE_DV360_MEDIA_PLATFORM_ID]: 'Google DV360',
  [TIKTOK_MEDIA_PLATFORM_ID]: 'Tiktok',
  [AMAZON_DSP_MEDIA_PLATFORM_ID]: 'Amazon DSP',
  [VIANT_MEDIA_PLATFORM_ID]: 'Viant',
  [GOOGLE_SEARCH_MEDIA_PLATFORM_ID]: 'Google Search',
  [YOUTUBE_MEDIA_PLATFORM_ID]: 'Youtube',
  [SIMPLIFI_MEDIA_PLATFORM_ID]: 'SimpliFi',
  [BING_MEDIA_PLATFORM_ID]: 'Bing',
  [SNAPCHAT_MEDIA_PLATFORM_ID]: 'Snapchat',
  [VISTAR_MEDIA_PLATFORM_ID]: 'Vistar',
  [LINKEDIN_MEDIA_PLATFORM_ID]: 'LinkedIn',
  [PINTEREST_MEDIA_PLATFORM_ID]: 'Pinterest',
  [GOOGLE_ADS_OTHER_MEDIA_PLATFORM_ID]: 'Google Ads Other',
  [MATCHCRAFT_MEDIA_PLATFORM_ID]: 'MatchCraft',
  [X_TWITTER_MEDIA_PLATFORM_ID]: 'X (Twitter)',
  [AUDIOGO_MEDIA_PLATFORM_ID]: 'AudioGo',
  [SPOTIFY_MEDIA_PLATFORM_ID]: 'Spotify',
  [NATIVO_MEDIA_PLATFORM_ID]: 'Nativo',
  [SITE_IMPACT_MEDIA_PLATFORM_ID]: 'Site Impact',
  [CASTIRON_FIREBRAND_MEDIA_PLATFORM_ID]: 'CastIron/Firebrand',
  [SPACEBACK_MEDIA_PLATFORM_ID]: 'Spaceback',
  [BEESWAX_TEST_ADVERTISER_ID]: 'Beeswax Test'
};

export function getPlatformEntityType(id: string | number | undefined) {
  if (!id) return '';
  switch (Number(id)) {
    case BEESWAX_MEDIA_PLATFORM_ID:
      return 'Line Item';
    case TTD_MEDIA_PLATFORM_ID:
      return 'Ad Group';
    case FACEBOOK_MEDIA_PLATFORM_ID:
      return 'Campaign';
    case GOOGLE_DV360_MEDIA_PLATFORM_ID:
      return 'Insertion Order';
    case TIKTOK_MEDIA_PLATFORM_ID:
      return 'Campaign';
    case AMAZON_DSP_MEDIA_PLATFORM_ID:
      return 'Order';
    case VIANT_MEDIA_PLATFORM_ID:
      return 'Campaign';
    case GOOGLE_SEARCH_MEDIA_PLATFORM_ID:
      return 'Campaign Budget';
    case YOUTUBE_MEDIA_PLATFORM_ID:
      return 'Campaign Budget';
    case SIMPLIFI_MEDIA_PLATFORM_ID:
      return 'Campaign';
    case BING_MEDIA_PLATFORM_ID:
      return 'Budget';
    case SNAPCHAT_MEDIA_PLATFORM_ID:
      return 'Ad Set';
    case VISTAR_MEDIA_PLATFORM_ID:
      return 'Campaign';
    case LINKEDIN_MEDIA_PLATFORM_ID:
      return 'Campaign';
    case PINTEREST_MEDIA_PLATFORM_ID:
      return 'Campaign';
    case GOOGLE_ADS_OTHER_MEDIA_PLATFORM_ID:
      return 'Campaign Budget';
    case MATCHCRAFT_MEDIA_PLATFORM_ID:
      return '';
    case X_TWITTER_MEDIA_PLATFORM_ID:
      return '';
    case AUDIOGO_MEDIA_PLATFORM_ID:
      return '';
    case SPOTIFY_MEDIA_PLATFORM_ID:
      return '';
    case NATIVO_MEDIA_PLATFORM_ID:
      return '';
    case SITE_IMPACT_MEDIA_PLATFORM_ID:
      return '';
    case CASTIRON_FIREBRAND_MEDIA_PLATFORM_ID:
      return '';
    case SPACEBACK_MEDIA_PLATFORM_ID:
      return '';
    case BEESWAX_TEST_ADVERTISER_ID:
      return 'Line Item';
    default:
      return '';
  }
}
