import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button } from './button';
import clsx from 'clsx';

type Props = {
  icon: React.ReactNode;
  title: string;
  submitSearch: (searchText: string) => unknown;
  defaultQuery?: string;
  placeholder?: string;
};

export function SearchRow({ defaultQuery, icon, title, submitSearch, placeholder }: Props) {
  return (
    <div className="mb-4 mt-8 flex items-center justify-between rounded-l-3xl rounded-r-xl bg-[#F9FAFB] py-2 pr-2">
      <div className="border-r-2 border-[#E5E7EB] px-6">
        <div className="h-8 w-8">{icon}</div>
      </div>
      <div className="pl-2 text-2xl font-bold">{title}</div>
      <div className="flex-1" />
      <SearchBar
        defaultQuery={defaultQuery}
        submitSearch={submitSearch}
        placeholder={placeholder}
      />
    </div>
  );
}

// remove type fo
type SearchBarProps = Omit<Props, 'icon' | 'title'>;

export function SearchBar({ submitSearch, placeholder, defaultQuery = '' }: SearchBarProps) {
  const [searchText, setSearchText] = useState<string>(defaultQuery);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setSearchText(defaultQuery);
  }, [defaultQuery]);

  function onSubmit() {
    submitSearch(searchText);
  }

  return (
    <form id="search-bar" className="flex" onSubmit={e => e.preventDefault()}>
      <div className="relative w-full min-w-[525px] rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
          <FontAwesomeIcon
            icon={searchText.length === 0 ? faSearch : faXmark}
            className={clsx('h-4 w-4 text-gray-400', searchText.length > 0 && 'cursor-pointer')}
            onClick={() => {
              searchText.length > 0 && setSearchText('');
              inputRef.current?.focus();
            }}
          />
        </div>

        <input
          id="search"
          ref={inputRef}
          name="search"
          value={searchText}
          onKeyDown={e => e.key === 'Enter' && onSubmit()}
          onChange={e => setSearchText(e.target.value)}
          type="text"
          placeholder={placeholder}
          className="block w-full overflow-ellipsis rounded-md border-0 py-1 pl-10 pr-[100px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
        />

        <div className="absolute inset-y-0 right-0 flex items-center pr-1">
          <Button onClick={onSubmit} disabled={false}>
            <div className="flex h-2 items-center">Search</div>
          </Button>
        </div>
      </div>
    </form>
  );
}
