import { Channel, Platform, UnitPriceType } from './line-item-channels';

// TODO[mk] - We use this to generate the SQL inserts for the channel/tactics/rates
//  If you are adding a new channel/tactics/rate, update here and use the channel-tactic-rates.test.ts to generate the SQL inserts

const beeswax: Platform = { id: 1, name: 'Beeswax' };
const tradeDesk: Platform = { id: 2, name: 'Trade Desk' };
const facebook: Platform = { id: 3, name: 'Facebook' };
const googleDv360: Platform = { id: 4, name: 'Google DV360' };
const tikTok: Platform = { id: 5, name: 'TikTok' };
const amazonDsp: Platform = { id: 6, name: 'Amazon DSP' };
const viant: Platform = { id: 7, name: 'Viant' };
const googleSearch: Platform = { id: 8, name: 'Google Search' };
const youtube: Platform = { id: 9, name: 'YouTube' };
const simpliFi: Platform = { id: 10, name: 'SimpliFi' };
const bing: Platform = { id: 11, name: 'Bing' };
const snapchat: Platform = { id: 12, name: 'Snapchat' };
const vistar: Platform = { id: 13, name: 'Vistar' };
const linkedIn: Platform = { id: 14, name: 'LinkedIn' };
const pinterest: Platform = { id: 15, name: 'Pinterest' };
const googleAdsOther: Platform = { id: 16, name: 'Google Ads Other' };
const matchCraft = { id: 118, name: 'MatchCraft' };
const xTwitter = { id: 119, name: 'X (Twitter)' };
const audiogo = { id: 120, name: 'AudioGo' };
const spotify = { id: 121, name: 'Spotify' };
const nativo = { id: 122, name: 'Nativo' };
const siteImpact = { id: 123, name: 'Site Impact' };
const castIron = { id: 124, name: 'CastIron/Firebrand' };
const disney = { id: 125, name: 'Disney/hulu' };
const spaceback = { id: 125, name: 'Spaceback' };

export const platforms = [
  beeswax,
  tradeDesk,
  facebook,
  googleDv360,
  tikTok,
  amazonDsp,
  googleSearch,
  youtube,
  simpliFi,
  bing,
  snapchat,
  vistar,
  linkedIn,
  pinterest,
  googleAdsOther,
  viant,
  matchCraft,
  xTwitter,
  audiogo,
  spotify,
  nativo,
  siteImpact,
  castIron,
  disney,
  spaceback
];

export const cpm: UnitPriceType = { id: 1, name: 'CPM' };
export const cpc: UnitPriceType = { id: 2, name: 'CPC' };
export const cpv: UnitPriceType = { id: 3, name: 'CPV' };
export const cpcv: UnitPriceType = { id: 4, name: 'CPCV' };
export const cpa: UnitPriceType = { id: 5, name: 'CPA' };
export const cpl: UnitPriceType = { id: 6, name: 'CPL' };
export const flatFee: UnitPriceType = { id: 7, name: 'Flat Fee' };

export const unitPriceTypes = [cpm, cpc, cpv, cpcv, cpa, cpl, flatFee];

const audio: Channel = {
  id: 1,
  name: 'Audio',
  tactics: [
    {
      id: 1,
      name: 'Programmatic Audio',
      rates: [{ unitPriceType: cpm, targetMargin: 0.6, unitPrice: 0.035 }],
      platforms: [tradeDesk, viant]
    },

    {
      id: 2,
      name: 'Pandora | AudioGo',
      rates: [{ unitPriceType: cpm, targetMargin: 0.6, unitPrice: 0.045 }],
      platforms: [audiogo]
    },

    {
      id: 3,
      name: 'Spotify',
      rates: [{ unitPriceType: cpm, targetMargin: 0.6, unitPrice: 0.05 }],
      platforms: [spotify]
    }
  ]
};

const video: Channel = {
  id: 2,
  name: 'Video',
  tactics: [
    {
      id: 4,
      name: 'CTV/OTT',
      rates: [
        { unitPriceType: cpm, targetMargin: 0.65, unitPrice: 0.035 },
        { unitPriceType: cpcv, targetMargin: 0.65, unitPrice: 0.0 }
      ],
      platforms: [beeswax, tradeDesk, googleDv360, amazonDsp, simpliFi, viant, castIron]
    },
    {
      id: 5,
      name: 'Social CTV/OTT',
      rates: [{ unitPriceType: cpm, targetMargin: 0.65, unitPrice: 0.04495 }],
      platforms: [facebook, tikTok, snapchat, linkedIn, pinterest, spaceback] // TODO[mk] - verify
    },
    {
      id: 6,
      name: 'Digital Out of Home',
      rates: [{ unitPriceType: cpm, targetMargin: 0.6, unitPrice: 0.03 }],
      platforms: [vistar, tradeDesk, viant]
    },
    {
      id: 7,
      name: 'Online Video',
      rates: [
        { unitPriceType: cpm, targetMargin: 0.75, unitPrice: 0.02295 },
        { unitPriceType: cpcv, targetMargin: 0.75, unitPrice: 0.0 }
      ],
      platforms: [beeswax, tradeDesk, googleDv360, amazonDsp, simpliFi, viant, castIron]
    },
    {
      id: 8,
      name: 'Social Online Video',
      rates: [{ unitPriceType: cpm, targetMargin: 0.75, unitPrice: 0.02495 }],
      platforms: [simpliFi, googleDv360, tradeDesk, viant, beeswax, spaceback]
    },
    {
      id: 9,
      name: 'Hulu',
      rates: [{ unitPriceType: cpm, targetMargin: 0.75, unitPrice: 0.088 }],
      platforms: [disney]
    }
  ]
};

const display: Channel = {
  id: 3,
  name: 'Display',
  tactics: [
    {
      id: 10,
      name: 'Display',
      rates: [{ unitPriceType: cpm, targetMargin: 0.77, unitPrice: 0.0065 }],
      platforms: [simpliFi, googleDv360, tradeDesk, viant, beeswax]
    },
    {
      id: 11,
      name: 'Amazon Display',
      rates: [{ unitPriceType: cpm, targetMargin: 0.77, unitPrice: 0.0065 }],
      platforms: [amazonDsp]
    },
    {
      id: 12,
      name: 'Display CRM',
      rates: [{ unitPriceType: cpm, targetMargin: 0.75, unitPrice: 0.00995 }],
      platforms: [simpliFi, googleDv360, tradeDesk, viant, beeswax]
    },
    {
      id: 13,
      name: 'Social Display',
      rates: [{ unitPriceType: cpm, targetMargin: 0.75, unitPrice: 0.00995 }],
      platforms: [simpliFi, googleDv360, tradeDesk, viant, beeswax, spaceback]
    },
    {
      id: 14,
      name: 'Social In-Banner Video',
      rates: [{ unitPriceType: cpm, targetMargin: 0.75, unitPrice: 0.01495 }],
      platforms: [simpliFi, googleDv360, tradeDesk, viant, beeswax, spaceback]
    },
    {
      id: 15,
      name: 'Addressable Geofence or Curation',
      rates: [{ unitPriceType: cpm, targetMargin: 0.75, unitPrice: 0.01195 }],
      platforms: [simpliFi, googleDv360, tradeDesk, viant, beeswax] // TODO[mk] - verify
    },
    {
      id: 16,
      name: 'Event Retargeting',
      rates: [{ unitPriceType: cpm, targetMargin: 0.75, unitPrice: 0.01495 }],
      platforms: [simpliFi, googleDv360, tradeDesk, viant, beeswax] // TODO[mk] - verify
    },
    {
      id: 17,
      name: 'Geofence',
      rates: [{ unitPriceType: cpm, targetMargin: 0.75, unitPrice: 0.00795 }],
      platforms: [simpliFi, googleDv360, tradeDesk, viant, beeswax] // TODO[mk] - verify
    }
  ]
};

const emailMarketing: Channel = {
  id: 4,
  name: 'Email Marketing',
  tactics: [
    {
      id: 18,
      name: 'B2C Email Marketing',
      rates: [{ unitPriceType: cpm, targetMargin: 0.7, unitPrice: 0.035 }],
      platforms: [siteImpact]
    },
    {
      id: 19,
      name: 'B2B Email Marketing',
      rates: [{ unitPriceType: cpm, targetMargin: 0.7, unitPrice: 0.067 }],
      platforms: [siteImpact]
    },
    {
      id: 20,
      name: 'Email Marketing Redeployment',
      rates: [{ unitPriceType: flatFee, targetMargin: 0.7, unitPrice: 835 }],
      platforms: [siteImpact]
    },
    {
      id: 21,
      name: 'Email Marketing List Purchase: Matching Postal',
      rates: [{ unitPriceType: cpm, targetMargin: 0.7, unitPrice: 0.017 }],
      platforms: [siteImpact]
    },
    {
      id: 22,
      name: 'Email Marketing List Purchase: Mailing Postal',
      rates: [{ unitPriceType: cpm, targetMargin: 0.7, unitPrice: 0.05 }],
      platforms: [siteImpact]
    },
    {
      id: 23,
      name: 'Email Marketing List Purchase: Hashed Emails',
      rates: [{ unitPriceType: cpm, targetMargin: 0.7, unitPrice: 0.01 }],
      platforms: [siteImpact]
    },
    {
      id: 24,
      name: 'Email Marketing Matchback Reporting',
      rates: [{ unitPriceType: flatFee, targetMargin: 0.7, unitPrice: 150.0 }],
      platforms: [siteImpact]
    }
  ]
};

const native: Channel = {
  id: 5,
  name: 'Native',
  tactics: [
    {
      id: 25,
      name: 'Native',
      rates: [{ unitPriceType: cpm, targetMargin: 0.75, unitPrice: 0.00995 }],
      platforms: [tradeDesk, beeswax, viant]
    },
    {
      id: 26,
      name: 'Native Sponsored Content',
      rates: [{ unitPriceType: cpm, targetMargin: 0.75, unitPrice: 0.00995 }],
      platforms: [nativo]
    }
  ]
};

const social: Channel = {
  id: 6,
  name: 'Social',
  tactics: [
    {
      id: 27,
      name: 'Meta',
      rates: [
        { unitPriceType: cpm, targetMargin: 0.7, unitPrice: 0.01395 },
        { unitPriceType: cpv, targetMargin: 0.7, unitPrice: 0 },
        { unitPriceType: cpl, targetMargin: 0.7, unitPrice: 0 },
        { unitPriceType: cpc, targetMargin: 0.7, unitPrice: 0 }
      ],
      platforms: [facebook]
    },
    {
      id: 28,
      name: 'LinkedIn',
      rates: [{ unitPriceType: cpm, targetMargin: 0.7, unitPrice: 0.035 }],
      platforms: [linkedIn]
    },
    {
      id: 29,
      name: 'Pinterest',
      rates: [{ unitPriceType: cpm, targetMargin: 0.7, unitPrice: 0.015 }],
      platforms: [pinterest]
    },
    {
      id: 30,
      name: 'Snapchat',
      rates: [{ unitPriceType: cpm, targetMargin: 0.7, unitPrice: 0.0085 }],
      platforms: [snapchat]
    },
    {
      id: 31,
      name: 'TikTok',
      rates: [{ unitPriceType: cpm, targetMargin: 0.7, unitPrice: 0.01295 }],
      platforms: [tikTok]
    },
    {
      id: 32,
      name: 'X',
      rates: [{ unitPriceType: cpm, targetMargin: 0.7, unitPrice: 0.035 }],
      platforms: [xTwitter]
    }
  ]
};

const semAndYoutube: Channel = {
  id: 7,
  name: 'SEM & YouTube',
  tactics: [
    {
      id: 33,
      name: 'SEM',
      rates: [{ unitPriceType: cpc, targetMargin: 0.7, unitPrice: 0 }],
      platforms: [googleSearch, bing]
    },
    {
      id: 34,
      name: 'YouTube Bumper Ads',
      rates: [{ unitPriceType: cpm, targetMargin: 0.8, unitPrice: 0.01995 }],
      platforms: [youtube]
    },
    {
      id: 35,
      name: 'YouTube Trueview',
      rates: [{ unitPriceType: cpv, targetMargin: 0.85, unitPrice: 0.18 }],
      platforms: [youtube]
    }
  ]
};

export const channels = [audio, video, display, emailMarketing, native, social, semAndYoutube];
