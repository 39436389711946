import { z } from 'zod';
import { dateToUTC } from 'shared/src/zod-utils';
import { AgDateFilter, AgNumberFilter, AgSetFilter } from './ag-grid-filter-types';

export const MediaPlatformEntity = z.object({
  id: z.string(),
  media_platform_id: z.number(),
  entity_id: z.string(),
  raw_entity: z.object({}).catchall(z.any()).optional(),
  entity_type: z.string(),
  entity_name: z.string()
});

export type MediaPlatformEntity = z.infer<typeof MediaPlatformEntity>;

export const PlatformBuy = z.object({
  id: z.string(),
  name: z.string(),
  media_platform_id: z.number(),
  media_platform_entity: MediaPlatformEntity,
  start_date: dateToUTC.nullable(),
  end_date: dateToUTC.nullable(),
  budget: z.coerce.number().nullable(),
  campaign_numbers: z.string().array().optional()
});

export type PlatformBuy = z.infer<typeof PlatformBuy>;

export const MediaPlan = z.object({
  id: z.string(),
  name: z.string().nullable(),
  budget: z.coerce.number(),
  target_unit_cost: z.coerce.number(),
  media_buy: PlatformBuy.optional().nullable(),
  is_deleted: z.boolean()
});
export type MediaPlan = z.infer<typeof MediaPlan>;

export const MediaPlanRecord = MediaPlan.omit({ media_buy: true }).partial().extend({
  media_buy_id: z.string().nullable()
});
export type MediaPlanRecord = z.infer<typeof MediaPlanRecord>;

export const NewMediaPlan = MediaPlan.extend({
  line_item_id: z.string()
});
export type NewMediaPlan = z.infer<typeof NewMediaPlan>;

// TODO[mk] - used when creating Beeswax platform buy
export const CreateMediaPlan = z.object({
  id: z.string(),
  line_item_id: z.string(),
  media_buy_id: z.string()
});
export type CreateMediaPlan = z.infer<typeof CreateMediaPlan>;

export const MediaPlanUpdate = MediaPlan.omit({ id: true }).partial().extend({
  id: z.string(),
  line_item_id: z.string()
});
export type MediaPlanUpdate = z.infer<typeof MediaPlanUpdate>;

export const SyncedPlatformBuyImpression = z.object({
  platform_id: z.number(),
  platform_buy_id: z.string(),
  on_date: dateToUTC,
  impressions: z.number(),
  clicks: z.number(),
  conversions: z.number(),
  cost: z.coerce.number(),
  total_spend: z.coerce.number(),
  bids: z.number().optional(),
  updated_at: dateToUTC
});

export type SyncedPlatformBuyImpression = z.infer<typeof SyncedPlatformBuyImpression>;

export const SyncedPlatformBuyVideo = z.object({
  platform_id: z.number(),
  platform_buy_id: z.string(),
  on_date: dateToUTC,
  video_views: z.number(),
  video_views_2s: z.number().optional(),
  video_views_p25: z.number(),
  video_views_p50: z.number(),
  video_views_p75: z.number(),
  video_completes: z.number(),
  updated_at: dateToUTC
});

export type SyncedPlatformBuyVideo = z.infer<typeof SyncedPlatformBuyVideo>;

export const SyncedPlatformEntity = z.object({
  media_platform_id: z.number(),
  raw_entity: z.object({}).catchall(z.any()).optional(),
  updated_at: dateToUTC.optional(),
  entity_id: z.string(),
  entity_type: z.string(),
  entity_name: z.string(),
  advertiser_id: z.string().optional(),
  fetched_at: dateToUTC.optional()
});

export type SyncedPlatformEntity = z.infer<typeof SyncedPlatformEntity>;

export const SyncedPlatformBuy = PlatformBuy.omit({
  media_platform_entity: true
}).extend({
  updated_at: dateToUTC.optional(),
  entity_id: z.string(),
  entity_type: z.string(),
  advertiser_id: z.string().nullable(),
  advertiser_type: z.string(),
  campaign_numbers: z.string().array(),
  spend: z.coerce.number()
});

export type SyncedPlatformBuy = z.infer<typeof SyncedPlatformBuy>;

export const PlatformBuyTableRow = PlatformBuy.omit({ media_platform_entity: true }).extend({
  num_line_items: z.number().optional(),
  spend: z.coerce.number().optional(),
  updated_at: dateToUTC.optional()
});

export type PlatformBuyTableRow = z.infer<typeof PlatformBuyTableRow>;

export const PlatformBuySearchRow = PlatformBuy.omit({
  media_platform_entity: true,
  budget: true,
  campaign_numbers: true
});

export type PlatformBuySearchRow = z.infer<typeof PlatformBuySearchRow>;

export const PlatformBuyAvailability = z.object({
  status: z.union([
    z.literal('added'),
    z.literal('available'),
    z.literal('unavailable'),
    z.literal('invalid_dates'),
    z.literal('incompatible_platform'),
    z.literal('unavailable_platform')
  ]),
  assigned_line_item_id: z.string().optional(),
  assigned_campaign_id: z.string().optional()
});

export const PlatformBuySearchRecord = PlatformBuy.omit({
  media_platform_entity: true,
  budget: true,
  campaign_numbers: true
}).extend({
  availability: PlatformBuyAvailability
});
export type PlatformBuySearchRecord = z.infer<typeof PlatformBuySearchRecord>;

export type PlatformBuyAvailabilityStatus =
  | 'added'
  | 'available'
  | 'unavailable_platform'
  | 'unavailable'
  | 'incompatible_platform'
  | 'invalid_dates';

export const NewPlatformBuy = PlatformBuy.omit({
  media_platform_entity: true
}).extend({
  line_item_id: z.string()
});
export type NewPlatformBuy = z.infer<typeof NewPlatformBuy>;

export const MediaPlanChanges = z.union([
  z.object({
    type: z.literal('new'),
    data: NewMediaPlan
  }),
  z.object({
    type: z.literal('update'),
    data: MediaPlanUpdate
  })
]);
export type MediaPlanChanges = z.infer<typeof MediaPlanChanges>;

export const PlatformBuySortColumn = z.union([
  z.literal('name'),
  z.literal('media_platform_id'),
  z.literal('start_date'),
  z.literal('end_date'),
  z.literal('budget'),
  z.literal('updated_at'),
  z.literal('campaign_numbers'),
  z.literal('num_line_items'),
  z.literal('spend')
]);
export type PlatformBuySortColumn = z.infer<typeof PlatformBuySortColumn>;
export const PlatformBuySortColumns = PlatformBuySortColumn.options.map(option => option.value);

export const SortDirection = z.union([z.literal('asc'), z.literal('desc')]);

export const PlatformBuySort = z.object({
  column: PlatformBuySortColumn,
  direction: SortDirection
});
export type PlatformBuySort = z.infer<typeof PlatformBuySort>;

export const PlatformBuysFilterTypes = z.object({
  media_platform_id: AgSetFilter.optional(),
  budget: AgNumberFilter.optional(),
  spend: AgNumberFilter.optional(),
  start_date: AgDateFilter.optional(),
  end_date: AgDateFilter.optional(),
  campaign_id: AgSetFilter.optional(),
  advertiser_id: AgSetFilter.optional(),
  advertiser_name: AgSetFilter.optional(),
  campaign_name: AgSetFilter.optional()
});
export type PlatformBuysFilterTypes = z.infer<typeof PlatformBuysFilterTypes>;

export const PlatformBuyListRequest = z.object({
  page: z.number(),
  search: z.string().optional(),
  sort: PlatformBuySort.optional(),
  filters: PlatformBuysFilterTypes
});

export type PlatformBuyListRequest = z.infer<typeof PlatformBuyListRequest>;

export const PlatformBuySearchMediaPlans = z.object({
  line_item_id: z.string(),
  platform_buy_ids: z.array(z.string())
});

export type PlatformBuySearchMediaPlans = z.infer<typeof PlatformBuySearchMediaPlans>;

export const PlatformBuySearchRequest = z.object({
  page: z.number(),
  search: z.string(),
  line_item_id: z.string(),
  pending_media_plans: z.array(PlatformBuySearchMediaPlans).optional()
});

export type PlatformBuySearchRequest = z.infer<typeof PlatformBuySearchRequest>;

export const PlatformBuyListResult = z.object({
  media_buys: z.array(PlatformBuyTableRow),
  total: z.number()
});

export type PlatformBuyListResult = z.infer<typeof PlatformBuyListResult>;

export const PlatformBuySearchResult = z.object({
  media_buys: z.array(PlatformBuySearchRecord),
  total: z.number(),
  platform_count: z.number()
});

export type PlatformBuySearchResult = z.infer<typeof PlatformBuySearchResult>;

export const ActiveMediaPlan = z.object({
  id: z.string(),
  line_item_id: z.string(),
  media_buy_id: z.string(),
  start_date: dateToUTC,
  end_date: dateToUTC
});
export type ActiveMediaPlan = z.infer<typeof ActiveMediaPlan>;
