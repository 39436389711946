import {
  Changeset,
  DBChangeset,
  DBLineItemDelta,
  MediaBuyChange
} from 'shared/src/changeset-types';
import {
  HistoryLineItem,
  HistoryLineItemUpdate,
  HistoryMediaBuy,
  HistoryMediaPlanUpdate
} from '../line-items/new-line-item';
import { CombinedStrategy } from '../../store/strategy-combiner';

export function getNewLineItems(changeset: Changeset): HistoryLineItem[] {
  const { line_items } = changeset.changes;
  if (!line_items) return [];
  return line_items
    .filter(line_item => line_item.delta.name?.old === null)
    .map(line_item => ({
      name: line_item.delta.name?.new!,
      budget: line_item.delta.price?.new!,
      audience: line_item.delta.audience?.new!,
      ad_formats: line_item.delta.ad_formats?.new!,
      tactic_id: line_item.delta.tactic_id?.new!,
      start_date: line_item.delta.start_date?.new!,
      end_date: line_item.delta.end_date?.new!,
      media_traders: line_item.delta.media_traders?.new!
    }));
}

export function getNewMediaBuys(changeset: Changeset): HistoryMediaBuy[] {
  const { media_buys } = changeset.changes;
  if (!media_buys) return [];
  return media_buys
    .filter(media_buy => media_buy.delta.name?.old === null)
    .map(media_buy => ({
      name: media_buy.delta.name?.new!,
      budget: media_buy.delta.budget?.new!,
      start_date: media_buy.delta.start_date?.new!,
      end_date: media_buy.delta.end_date?.new!
    }));
}

export function getUpdatedLineItems(
  changeset: Changeset,
  strategy: CombinedStrategy
): HistoryLineItemUpdate[] {
  // TODO - [mk] Only returning the fields we allow editing for now
  const { line_items } = changeset.changes;
  if (!line_items) return [];
  return line_items
    .filter(line_item => anyPropertiesChanged(line_item.delta))
    .filter(line_item => strategy.line_items.some(li => li.id === line_item.id))
    .map(line_item => ({
      lineItem: strategy.line_items.find(li => li.id === line_item.id)!,
      delta: {
        name: line_item.delta.name?.new
          ? { old: line_item.delta.name.old!, new: line_item.delta.name.new! }
          : undefined,
        channel_id: line_item.delta.channel_id?.new
          ? { old: line_item.delta.channel_id.old!, new: line_item.delta.channel_id.new! }
          : undefined,
        tactic_id: line_item.delta.tactic_id?.new
          ? { old: line_item.delta.tactic_id.old!, new: line_item.delta.tactic_id.new! }
          : undefined,
        unit_price_type_id: line_item.delta.unit_price_type_id?.new
          ? {
              old: line_item.delta.unit_price_type_id.old!,
              new: line_item.delta.unit_price_type_id.new!
            }
          : undefined,
        geo: line_item.delta.geo?.new
          ? { old: line_item.delta.geo.old!, new: line_item.delta.geo.new! }
          : undefined,
        targeting: line_item.delta.targeting?.new
          ? { old: line_item.delta.targeting.old!, new: line_item.delta.targeting.new! }
          : undefined,
        audience: line_item.delta.audience?.new
          ? { old: line_item.delta.audience.old!, new: line_item.delta.audience.new! }
          : undefined,
        price: line_item.delta.price?.new
          ? { old: line_item.delta.price.old!, new: line_item.delta.price.new! }
          : undefined,
        media_traders: line_item.delta.media_traders?.new
          ? {
              old: line_item.delta.media_traders.old!,
              new: line_item.delta.media_traders.new!
            }
          : undefined,
        start_date: line_item.delta.start_date?.new
          ? { old: line_item.delta.start_date.old!, new: line_item.delta.start_date.new! }
          : undefined,
        end_date: line_item.delta.end_date?.new
          ? { old: line_item.delta.end_date.old!, new: line_item.delta.end_date.new! }
          : undefined,
        is_deleted: line_item.delta.is_deleted?.new
          ? { old: line_item.delta.is_deleted.old!, new: line_item.delta.is_deleted.new! }
          : undefined
      }
    }));
}

export function getUpdatedMediaBuys(
  changeset: DBChangeset,
  strategy: CombinedStrategy
): HistoryMediaPlanUpdate[] {
  // TODO - [mk] Only returning the fields we allow editing for now
  const { media_buys } = changeset.changes;
  if (!media_buys) return [];
  return media_buys
    .filter(mediaBuy => anyPropertiesChanged(mediaBuy.delta))
    .filter(mediaBuy => findMediaPlan(strategy, mediaBuy) !== undefined)
    .map(mediaBuy => ({
      mediaPlan: findMediaPlan(strategy, mediaBuy)!,
      delta: {
        budget: mediaBuy.delta.budget?.new
          ? { old: mediaBuy.delta.budget.old!, new: mediaBuy.delta.budget.new! }
          : undefined,
        start_date: mediaBuy.delta.start_date?.new
          ? { old: mediaBuy.delta.start_date.old!, new: mediaBuy.delta.start_date.new! }
          : undefined,
        end_date: mediaBuy.delta.end_date?.new
          ? { old: mediaBuy.delta.end_date.old!, new: mediaBuy.delta.end_date.new! }
          : undefined
      }
    }));
}

// TODO[mk] - MEDIA_PLANS - history needs to be updated to reflect the new structure
function findMediaPlan(strategy: CombinedStrategy, mediaBuy: MediaBuyChange) {
  return strategy.line_items
    .flatMap(li => li.media_plans)
    .find(mb => mb.media_buy?.id === mediaBuy.id);
}

function anyPropertiesChanged(delta: DBLineItemDelta) {
  return Object.values(delta).some(value => value?.old !== value?.new && value.old !== null);
}
