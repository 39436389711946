import {
  AMAZON_DSP_MEDIA_PLATFORM_ID,
  BEESWAX_MEDIA_PLATFORM_ID,
  BING_MEDIA_PLATFORM_ID,
  FACEBOOK_MEDIA_PLATFORM_ID,
  GOOGLE_DV360_MEDIA_PLATFORM_ID,
  GOOGLE_SEARCH_MEDIA_PLATFORM_ID,
  LINKEDIN_MEDIA_PLATFORM_ID,
  PINTEREST_MEDIA_PLATFORM_ID,
  SIMPLIFI_MEDIA_PLATFORM_ID,
  SNAPCHAT_MEDIA_PLATFORM_ID,
  TIKTOK_MEDIA_PLATFORM_ID,
  TTD_MEDIA_PLATFORM_ID,
  VIANT_MEDIA_PLATFORM_ID,
  VISTAR_MEDIA_PLATFORM_ID,
  YOUTUBE_MEDIA_PLATFORM_ID
} from 'shared/src/media-platforms';
import { PlatformBuy } from 'shared/src/media-buy-types';
import { WrappedBeeswaxLineItemV1 } from 'shared/src/beeswax/beeswax-types';
import { UnitPriceType } from 'shared/src/line-item-channels';

export function generateDeeplink(platformBuy: PlatformBuy) {
  switch (platformBuy.media_platform_id) {
    case BEESWAX_MEDIA_PLATFORM_ID:
      return getBeeswaxPlatformLink(platformBuy);
    case TTD_MEDIA_PLATFORM_ID:
      return getTradedeskPlatformLink(platformBuy);
    case VIANT_MEDIA_PLATFORM_ID:
      return getViantPlatformLink(platformBuy);
    case LINKEDIN_MEDIA_PLATFORM_ID:
      return getLinkedinPlatformLink(platformBuy);
    case PINTEREST_MEDIA_PLATFORM_ID:
      return getPintrestPlatformLink(platformBuy);
    case FACEBOOK_MEDIA_PLATFORM_ID:
      return getFacebookPlatformLink(platformBuy);
    case TIKTOK_MEDIA_PLATFORM_ID:
      return getTiktokPlatformLink(platformBuy);
    case YOUTUBE_MEDIA_PLATFORM_ID:
      return getYoutubePlatformLink(platformBuy);
    case VISTAR_MEDIA_PLATFORM_ID:
      return getVistarPlatformLink(platformBuy);
    case GOOGLE_DV360_MEDIA_PLATFORM_ID:
      return getDV360PlatformLink(platformBuy);
    case GOOGLE_SEARCH_MEDIA_PLATFORM_ID:
      return getGoogleSearchPlatformLink(platformBuy);
    case BING_MEDIA_PLATFORM_ID:
      return getBingPlatformLink();
    case SNAPCHAT_MEDIA_PLATFORM_ID:
      return getSnapchatPlatformLink();
    case AMAZON_DSP_MEDIA_PLATFORM_ID:
      return getAmazonDspPlatformLink();
    case SIMPLIFI_MEDIA_PLATFORM_ID:
      return getSimpliFiPlatformLink();
    default:
      return undefined;
  }
}

function getBeeswaxPlatformLink(platformBuy: PlatformBuy) {
  const { raw_entity } = platformBuy.media_platform_entity;
  if (raw_entity) {
    const wrappedLineItem = WrappedBeeswaxLineItemV1.parse(raw_entity);
    const { advertiser_id, campaign_id, line_item_id } = wrappedLineItem.data;
    if (advertiser_id && campaign_id && line_item_id) {
      return `https://brkthru.beeswax.com/advertisers/${advertiser_id}/campaigns/${campaign_id}/line_items/${line_item_id}/edit?tab=general&subtabs=essential`;
    }
  }

  return 'https://brkthru.beeswax.com/line_items';
}

function getTradedeskPlatformLink(platformBuy: PlatformBuy) {
  const campaignId = platformBuy.media_platform_entity?.raw_entity?.data?.CampaignId;
  if (campaignId) {
    return `https://desk.thetradedesk.com/app/partner/fdu9suq/buy/campaign/${campaignId}/details`;
  }
  return 'https://desk.thetradedesk.com/app/partner/fdu9suq';
}

function getViantPlatformLink(platformBuy: PlatformBuy) {
  if (platformBuy.media_platform_entity?.entity_id) {
    return `https://dsp.viantinc.com/campaigns/order-details/${platformBuy.media_platform_entity.entity_id}/`;
  }
  return 'https://dsp.viantinc.com/campaigns/';
}

function getLinkedinPlatformLink(platformBuy: PlatformBuy) {
  const account = platformBuy.media_platform_entity?.raw_entity?.data?.account;
  const campaignGroup = platformBuy.media_platform_entity?.raw_entity?.data?.campaignGroup;
  if (account && campaignGroup) {
    const accountId = account.split(':').pop();
    const campaignGroupId = campaignGroup.split(':').pop();
    return `https://www.linkedin.com/campaignmanager/accounts/${accountId}/creatives?campaignGroupIds=%5B${campaignGroupId}%5D`;
  }
  return 'https://www.linkedin.com/campaignmanager/accounts';
}

function getPintrestPlatformLink(platformBuy: PlatformBuy) {
  const data = platformBuy.media_platform_entity?.raw_entity?.data;
  const pinAdAccountId = data?.ad_account_id;
  const pinId = data?.id;
  if (pinAdAccountId && pinId) {
    return `https://ads.pinterest.com/advertiser/${pinAdAccountId}/reporting/ads/?adGroupIds=[${pinId}]`;
  }
  return 'https://ads.pinterest.com/';
}

function getFacebookPlatformLink(platformBuy: PlatformBuy) {
  const data = platformBuy.media_platform_entity?.raw_entity?.data;
  const fbAccountId = data?.account_id;
  const fbCampaignId = data?.id;
  if (fbAccountId && fbCampaignId) {
    return `https://adsmanager.facebook.com/adsmanager/manage/ads?act=${fbAccountId}&selected_campaign_ids=${fbCampaignId}`;
  }
  return 'https://adsmanager.facebook.com/adsmanager/manage/ads';
}

function getTiktokPlatformLink(platformBuy: PlatformBuy) {
  const ttAdId = platformBuy.media_platform_entity?.raw_entity?.data?.advertiser_id;
  if (ttAdId) {
    return `https://ads.tiktok.com/i18n/perf/adgroup?aadvid=${ttAdId}&search_type=2`;
  }
  return 'https://ads.tiktok.com/';
}

function getYoutubePlatformLink(platformBuy: PlatformBuy) {
  const data = platformBuy.media_platform_entity?.raw_entity?.data;
  const campaign = data?.campaign;
  const baseAdGroup = data?.base_ad_group;
  if (campaign && baseAdGroup) {
    const campaignId = campaign.split('/').pop();
    const baseAdGroupId = baseAdGroup.split('/').pop();
    return `https://ads.google.com/aw/ads?campaignId=${campaignId}&adGroupId=${baseAdGroupId}`;
  }
  return 'https://ads.google.com/aw/ads';
}

function getVistarPlatformLink(platformBuy: PlatformBuy) {
  const data = platformBuy.media_platform_entity?.raw_entity?.data;
  const ioid = data?.insertion_order_id;
  if (ioid) {
    return `https://trafficking.vistarmedia.com/#/insertion_order/${ioid}/campaigns`;
  }
  return 'https://trafficking.vistarmedia.com/';
}

function getDV360PlatformLink(platformBuy: PlatformBuy) {
  const data = platformBuy.media_platform_entity?.raw_entity?.data;
  const advertiserId = data?.advertiserId;
  const campaignId = data?.campaignId;
  const insertionOrderId = data?.insertionOrderId;
  if (advertiserId && campaignId) {
    return `https://displayvideo.google.com/ng_nav/p/3488452/a/${advertiserId}/c/${campaignId}/io/${insertionOrderId}/explorerlis`;
  }
  return 'https://displayvideo.google.com/ng_nav/p/3488452/a/881408796/c/52242307/io/28895452/explorerlis';
}

function getGoogleSearchPlatformLink(platformBuy: PlatformBuy) {
  const resourceName = platformBuy.media_platform_entity?.raw_entity?.data?.resource_name;
  if (resourceName) {
    const campaignId = resourceName.split('/')[1];
    return `https://ads.google.com/aw/ads?campaignId=${campaignId}`;
  }
  return 'https://ads.google.com/aw/ads';
}

function getBingPlatformLink() {
  return 'https://ui.ads.microsoft.com/campaign/vnext/accounts/performance';
}

function getSnapchatPlatformLink() {
  return 'https://ads.snapchat.com/';
}

function getAmazonDspPlatformLink() {
  return 'https://advertising.amazon.com/';
}

function getSimpliFiPlatformLink() {
  return 'https://app.simpli.fi/';
}

export function calcAdjustedTargetUnitCost(
  unitPriceType: UnitPriceType['name'] | undefined,
  targetUnitCost: number
) {
  return unitPriceType === 'CPM' ? targetUnitCost * 1000 : targetUnitCost;
}
