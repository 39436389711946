import { CombinedStrategy } from '../../store/strategy-combiner';
import { DraftStrategyChanges, FinalisedStrategyChanges } from 'shared/src/types';
import { FinalisedLineItemChange, LineItemChangeDraft } from 'shared/src/line-item-types';
import { mapValues } from 'lodash';

export function changesCount(strategy: CombinedStrategy) {
  const newLineItemsCnt = strategy.line_items.filter(li => li.state.type === 'new').length;
  const updatedLineItemsCnt = strategy.line_items
    .map(li => (li.state.type === 'update' ? Object.keys(li.state.dirty).length : 0))
    .reduce((a, b) => a + b, 0);

  const newMediaPlans = strategy.line_items
    .flatMap(lineItem => lineItem.media_plans)
    .filter(mediaPlan => mediaPlan.state.type === 'new');

  // TODO[mk] - I'm wondering if for a new media plan, we just say it's new, as opposed to saying the number
  // of updates
  const newMediaPlansCnt = newMediaPlans
    .map(
      mbp =>
        [mbp.name, mbp.budget, mbp.target_unit_cost, mbp.media_buy].filter(
          value => value !== undefined
        ).length
    )
    .reduce((a, b) => a + b, 0);

  const updatedMediaPlans = strategy.line_items
    .flatMap(lineItem => lineItem.media_plans)
    .filter(mediaPlan => mediaPlan.state.type === 'update');

  const updatedMediaPlansCnt = updatedMediaPlans
    .map(mbp => (mbp.state.type === 'update' ? Object.keys(mbp.state.dirty).length : 0))
    .reduce((a, b) => a + b, 0);

  return newLineItemsCnt + updatedLineItemsCnt + newMediaPlansCnt + updatedMediaPlansCnt;
}

export function changesAmount(strategy: CombinedStrategy) {
  const newSpend = strategy.line_items
    .filter(lineItem => lineItem.state.type === 'new')
    .map(item => item.price)
    .reduce((a, b) => (a || 0) + (b || 0), 0);
  const updatedSpend = strategy.line_items
    .map(li => (li.state.type === 'update' ? li.state.dirty : null))
    .filter(Boolean)
    .filter(dirty => dirty.price != null)
    .map(dirty => dirty.price?.new! - dirty.price?.old!)
    .reduce((a, b) => a + b, 0);
  return (newSpend || 0) + updatedSpend;
}

export function convertStrategyChanges(changes: DraftStrategyChanges): FinalisedStrategyChanges {
  const { line_items, media_plans } = changes;
  return {
    media_plans,
    line_items: mapValues(line_items, convertLineItem)
  };
}

function convertLineItem(lineItem: LineItemChangeDraft): FinalisedLineItemChange {
  // Line item should already have been validated at this point
  return FinalisedLineItemChange.parse(lineItem);
}
