import { z } from 'zod';
import { dateToUTC } from 'shared/src/zod-utils';

export const AgDateFilter = z.object({
  dateFrom: dateToUTC.nullable(),
  dateTo: dateToUTC.nullable(),
  filterType: z.literal('date'),
  type: z.union([
    z.literal('equals'),
    z.literal('lessThan'),
    z.literal('greaterThan'),
    z.literal('inRange')
  ])
});

export type AgDateFilter = z.infer<typeof AgDateFilter>;

export const AgNumberFilter = z.object({
  filter: z.number().optional(),
  filterTo: z.number().optional(),
  filterType: z.literal('number'),
  type: z.union([
    z.literal('equals'),
    z.literal('greaterThan'),
    z.literal('lessThan'),
    z.literal('inRange')
  ])
});

export type AgNumberFilter = z.infer<typeof AgNumberFilter>;

export const AgSetFilter = z.object({
  filterType: z.literal('set'),
  values: z.array(z.union([z.string(), z.number()]))
});

export type AgSetFilter = z.infer<typeof AgSetFilter>;

export const AgTextFilter = z.object({
  filterType: z.literal('text'),
  type: z.union([z.literal('contains'), z.literal('notContains')]),
  filter: z.string().optional()
});

export type AgTextFilter = z.infer<typeof AgTextFilter>;

export const AgFilter = z.union([AgDateFilter, AgNumberFilter, AgSetFilter, AgTextFilter]);
export type AgFilter = z.infer<typeof AgFilter>;
