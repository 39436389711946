import React from 'react';
import Modal from '../../components/modal';
import { AlertButton, SecondaryButton } from '../../components/button';
import { setIsDeleted } from './line-items-update-helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { CombinedLineItem } from '../../store/strategy-combiner';

type Props = {
  lineItemsToDelete: CombinedLineItem[];
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};
export function LineItemDeleteModal({ lineItemsToDelete, open, setOpen }: Props) {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      header={
        <div className="flex flex-col gap-3 rounded-t-2xl bg-red-100 py-6 text-center text-2xl font-semibold text-red-600">
          <FontAwesomeIcon icon={faTrashCan} />
          Are you sure you want to delete?
        </div>
      }
      content={
        <div className="p-4 text-sm text-gray-700">
          <Message lineItems={lineItemsToDelete} />
        </div>
      }
      footer={<Footer lineItems={lineItemsToDelete} onClose={() => setOpen(false)} />}
    />
  );
}

function Footer({ lineItems, onClose }: { lineItems: CombinedLineItem[]; onClose: () => void }) {
  function handleUpdate() {
    lineItems.forEach(item => setIsDeleted(item));
    onClose();
  }

  return (
    <div className="flex gap-4 p-4">
      <SecondaryButton className="grow justify-around" onClick={onClose}>
        Cancel
      </SecondaryButton>
      <AlertButton className="grow justify-around" onClick={handleUpdate}>
        Confirm Delete
      </AlertButton>
    </div>
  );
}

function Message({ lineItems }: { lineItems: CombinedLineItem[] }) {
  if (lineItems.length === 1) {
    return (
      <div>
        <div className="font-semibold">{`Delete line item [${lineItems[0].name || 'No name entered'}].`}</div>
        <div>Confirm you want to delete this line item.</div>
      </div>
    );
  }
  if (lineItems.length < 10) {
    return (
      <div className="text-wrap break-all">
        <div className="font-semibold">{`Delete ${lineItems.length} line items.`}</div>
        <ul className="list-disc pl-6">
          {lineItems.map(item => (
            <li key={item.id}>{item.name || '[No name entered]'}</li>
          ))}
        </ul>
        <div>Confirm you want to delete these line items.</div>
      </div>
    );
  }
  if (lineItems.length >= 10) {
    return (
      <div>
        <div className="font-semibold">{`Delete ${lineItems.length} line items.`}</div>
        <div>Confirm you want to delete these line items.</div>
      </div>
    );
  }
  return '';
}
