import {
  CombinedLineItem,
  CombinedMediaPlan,
  CombinedStrategy
} from '../../store/strategy-combiner';
import { DialogTitle } from '@headlessui/react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useStore } from '../../store/store';
import { trpc } from '../../utils/trpc-client';
import { changesAmount, changesCount, convertStrategyChanges } from './pending-changes-utils';
import { formatMoney } from 'shared/src/money-utils';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { NewLineItem, NewMediaPlan } from '../line-items/new-line-item';
import { hasLineItemErrors, LineItemErrors } from '../line-items/line-item-errors';
import { LineItemDiffs, MediaPlanDeleted, MediaPlanDiffs } from './pending-changes-components';
import { faArrowDown, faArrowRightFromLine } from '@fortawesome/pro-light-svg-icons';
import { IntercomIcon } from '../../components/intercom-icon';
import { useIntercom } from 'react-use-intercom';
import { useStrategyContext } from '../line-items/strategy-context';
import { PendingChangeContainer } from './pending-change-container';
import { Button, TextButton } from '../../components/button';
import clsx from 'clsx';

type FormData = {
  comment: string;
};

type Props = {
  strategy: CombinedStrategy;
  refetch: () => Promise<unknown>;
  setOpen: (open: boolean) => unknown;
};

export function Hr() {
  return <hr className="h-[2px] rounded border-0 bg-gray-200" />;
}

export function CloseButton(props: { onClick: () => void }) {
  return (
    <div className="ml-3 flex h-7 items-center">
      <button
        type="button"
        onClick={props.onClick}
        className="relative -m-2 p-2 text-gray-400 hover:text-gray-500">
        <span className="absolute -inset-0.5" />
        <span className="sr-only">Close panel</span>
        <FontAwesomeIcon icon={faClose} className="h-6 w-6" />
      </button>
    </div>
  );
}

export function PendingChanges({ strategy, refetch, setOpen }: Props) {
  const count = changesCount(strategy);
  const budgetChange = changesAmount(strategy);
  const invalidLineItems = strategy.line_items.some(hasLineItemErrors);

  return (
    <div className="flex h-full w-[650px] flex-col bg-white p-8 shadow-xl">
      <div className="pb-6">
        <div className="flex flex-col gap-2">
          <TextButton onClick={() => setOpen(false)} className="grow-0 gap-2">
            <FontAwesomeIcon icon={faArrowRightFromLine} />
            Collapse
          </TextButton>
          <DialogTitle className="text-lg font-bold text-gray-900">
            {count} Pending change{count === 1 ? '' : 's'}
          </DialogTitle>
        </div>
      </div>
      <Hr />
      <div className="mt-4 flex-1 overflow-y-auto">
        {strategy.line_items.filter(hasChanges).map(lineItem => (
          <LineItemChanges key={lineItem.id} lineItem={lineItem} />
        ))}
      </div>
      {invalidLineItems && (
        <div className="flex items-center justify-between pb-2">
          <div className="mt-2 flex w-full flex-col rounded-md bg-red-100 p-2 px-2 font-light">
            <div className="text-sm font-bold">Unable to Save</div>
            <div className="text-sm font-light">
              Please fix the errors above in order to publish
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center justify-between pb-2">
        <div className="text-sm text-gray-500">Budget Change</div>
        {budgetChange === 0 && <div>No Change</div>}
        {budgetChange > 0 && <div className="text-green-700">+{formatMoney(budgetChange)}</div>}
        {budgetChange < 0 && <div className="text-red-500">{formatMoney(budgetChange)}</div>}
      </div>
      <Hr />
      <SubmitSection
        strategy={strategy}
        refetch={refetch}
        setOpen={setOpen}
        invalidChanges={invalidLineItems}
      />
    </div>
  );
}

function hasChanges(lineItem: CombinedLineItem) {
  return (
    lineItem.state.type !== 'unchanged' ||
    lineItem.media_plans.some(plan => plan.state.type !== 'unchanged')
  );
}

function LineItemChanges({ lineItem }: { lineItem: CombinedLineItem }) {
  const updatedMediaPlans = lineItem.media_plans.filter(plan => plan.state.type !== 'unchanged');

  return (
    <>
      {lineItem.state.type === 'new' && <NewLineItem lineItem={lineItem} />}
      {lineItem.state.type === 'update' && <UpdatedLineItem lineItem={lineItem} />}
      {updatedMediaPlans.length > 0 && <MediaPlanChangeTitle lineItem={lineItem} />}
      {updatedMediaPlans.map(plan =>
        plan.state.type === 'new' ? (
          <NewMediaPlan key={plan.id} lineItem={lineItem} mediaPlan={plan} />
        ) : (
          <UpdatedMediaPlan key={plan.id} lineItem={lineItem} mediaPlan={plan} />
        )
      )}
    </>
  );
}

function MediaPlanChangeTitle({ lineItem }: { lineItem: CombinedLineItem }) {
  return (
    <div className="mb-2 flex items-center justify-between rounded bg-gray-100 px-1 py-0.5 text-gray-600">
      <div className="font-bold">Media Plan changes</div>
      <div className="text-sm font-medium">Line Item: {lineItem.name}</div>
    </div>
  );
}

function UpdatedLineItem({ lineItem }: { lineItem: CombinedLineItem }) {
  const removeEntities = useStore(state => state.removeEntities);
  if (lineItem.state.type !== 'update') return null;
  const numUpdates = Object.keys(lineItem.state.dirty).length;
  return (
    <div key={lineItem.id} className="mb-10 px-6">
      <div className="mb-4 flex items-center">
        <div className="mr-4 font-bold">{lineItem.name}</div>
        <div className="rounded-lg bg-gray-100 px-2 py-[3px] text-xs font-light text-gray-500">
          {numUpdates} Update{numUpdates > 1 ? 's' : ''}
        </div>
        <div className="flex-1" />
        <div
          className="text-sm font-light text-red-500 hover:cursor-pointer hover:underline"
          onClick={() => removeEntities(lineItem.strategy_id, { lineItemIds: [lineItem.id] })}>
          Discard
        </div>
      </div>
      <LineItemDiffs lineItem={lineItem} />
      <LineItemErrors lineItem={lineItem} />
    </div>
  );
}

type UpdatedMediaPlanProps = {
  mediaPlan: CombinedMediaPlan;
  lineItem: CombinedLineItem;
};

function UpdatedMediaPlan({ lineItem, mediaPlan }: UpdatedMediaPlanProps) {
  const { strategy } = useStrategyContext();
  const removeEntities = useStore(state => state.removeEntities);
  if (mediaPlan.state.type !== 'update') return null;
  const numUpdates = Object.keys(mediaPlan.state.dirty).length;
  return (
    <PendingChangeContainer
      name={mediaPlan.name || 'Unnamed Plan'}
      numChanges={numUpdates}
      numErrors={0}
      discardFn={() => removeEntities(strategy.id, { mediaPlanIds: [mediaPlan.id] })}
      content={
        mediaPlan.is_deleted ? (
          <MediaPlanDeleted mediaPlan={mediaPlan} lineItem={lineItem} />
        ) : (
          <MediaPlanDiffs mediaPlan={mediaPlan} lineItem={lineItem} />
        )
      }
      tooltipContent={
        <div className="capitalize">
          {mediaPlan.is_deleted ? (
            'Deleted'
          ) : (
            <>
              {Object.keys(mediaPlan.state.dirty).map(field => (
                <div key={field}>{field}</div>
              ))}
            </>
          )}
        </div>
      }
      tooltipId={`pending-media-plan-${mediaPlan.id}`}
      type="update"
    />
  );
}

type SubmitSectionProps = {
  strategy: CombinedStrategy;
  refetch: () => Promise<unknown>;
  setOpen: (open: boolean) => unknown;
  invalidChanges: boolean;
};

function SubmitSection({ strategy, refetch, setOpen, invalidChanges }: SubmitSectionProps) {
  const resetChanges = useStore(state => state.resetChanges);
  const { mutateAsync, isPending, isError } = trpc.updateStrategy.useMutation({
    onSuccess: async () => {
      resetChanges(strategy.id);
      await refetch();
      setOpen(false);
    }
  });
  const { register, handleSubmit, formState } = useForm<FormData>();
  const { errors } = formState;

  async function publishChanges(data: FormData) {
    await mutateAsync({
      strategy_id: strategy.id,
      description: data.comment,
      changes: convertStrategyChanges(strategy.changes)
    });
  }

  return (
    <div className="pt-4">
      <form onSubmit={handleSubmit(publishChanges)} className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="text-sm text-gray-600">Comment</div>
          <textarea
            {...register('comment', { required: true })}
            placeholder="Comment on your changes"
            className={clsx(
              'w-full resize-none rounded-md px-4 py-3 font-light placeholder:text-gray-400',
              errors.comment ? 'border-red-400' : 'border-gray-300'
            )}
            rows={1}
          />
          <div className={clsx('text-sm', errors.comment ? 'text-red-500' : 'text-gray-500')}>
            Required
          </div>
        </div>
        <Hr />
        {isError && <PublishError />}
        <Button
          className="w-full justify-around py-3"
          disabled={isPending || invalidChanges}
          type="submit">
          {isPending ? 'Publishing' : 'Publish Changes'}
          {isPending && (
            <motion.div
              animate={{ rotate: 360 }}
              className="ml-2"
              transition={{
                ease: 'linear',
                duration: 2,
                repeat: Infinity
              }}>
              <FontAwesomeIcon icon={faSpinner} />
            </motion.div>
          )}
        </Button>
      </form>
    </div>
  );
}

function PublishError() {
  const { showNewMessage: showNewIntercomMessage } = useIntercom();

  return (
    <div className="flex gap-4 rounded bg-red-600 p-2 pl-4">
      <div className="flex flex-col gap-1 text-white">
        <div className="text-lg font-semibold">Publish failed.</div>
        <div className="text-sm">
          <div>Your work isn’t lost. It’s stored on your computer until it’s Published.</div>
          <div className="font-semibold">You can try again, or...</div>
        </div>
      </div>
      <div className="flex basis-[300px] gap-3 rounded bg-white py-2 pl-4 pr-2">
        <div className="flex items-center text-sm text-gray-700">
          If the issue continues, send a message using Intercom at the bottom right of your screen.
        </div>
        <div
          onClick={() => showNewIntercomMessage()}
          className="flex cursor-pointer flex-col items-center justify-between rounded rounded-t-3xl bg-[#0171B2] p-2 pt-3">
          <IntercomIcon />
          <FontAwesomeIcon color="white" icon={faArrowDown} className="h-6 w-6" />
        </div>
      </div>
    </div>
  );
}
