import { PlatformBuyMetric } from 'shared/src/metrics-types';
import { CombinedLineItem, CombinedMediaPlan } from '../../store/strategy-combiner';
import { fromPairs, groupBy } from 'lodash';
import { DisplayMode } from '../../components/charts/display-mode-selector';
import { UTCDate } from '@date-fns/utc';
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  isAfter,
  isBefore,
  max,
  min,
  startOfDay,
  startOfISOWeek,
  startOfMonth
} from 'date-fns';
import { calcEstimatedUnits, calcMediaBudget } from 'shared/src/line-item-utils';
import { PlatformBuyGraphData } from '../../metrics/metrics-types';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { Granularity } from '../../components/charts/chart-type-selector';
import { diffInDays } from '../../metrics/calculate-metrics';

export function convertMetrics(
  data: PlatformBuyMetric[],
  lineItem: CombinedLineItem,
  mediaPlans: CombinedMediaPlan[],
  displayMode: DisplayMode,
  chartType: 'spend' | 'delivery',
  granularity: Granularity
): PlatformBuyGraphData[] {
  const { start_date, end_date } = lineItem;
  if (data.length === 0 || !start_date || !end_date) return [];
  const byPlatformBuy = groupMetrics(data);
  return byPlatformBuy.map(metrics => {
    const mediaPlan = mediaPlans.find(plan => plan.media_buy?.id === metrics[0].MEDIA_BUY_ID);
    const planned = generatePlatformBuyPlanned(
      displayMode,
      chartType,
      lineItem,
      mediaPlan,
      granularity
    );

    return {
      id: mediaPlan?.media_buy?.id || 'Unknown',
      name: mediaPlan?.name || 'Unknown',
      chartType,
      displayMode,
      unitPrice: lineItem.unit_price || 0,
      priceType: lineItem.unit_price_type?.name || 'CPM',
      metrics: generateMetrics(metrics, displayMode, chartType, planned),
      planned
    };
  });
}

function groupMetrics(metrics: PlatformBuyMetric[]) {
  const result = groupBy(metrics, 'MEDIA_BUY_ID');
  return Object.values(result);
}

function generateMetrics(
  data: PlatformBuyMetric[],
  displayMode: DisplayMode,
  chartType: 'spend' | 'delivery',
  planned: { date: UTCDate; value: number }[]
) {
  const fn = accessorFn(chartType, displayMode);
  return data.map((d, idx) => ({
    date: d.BUCKET,
    value: fn(d),
    spend: d.ACTUAL_MEDIA_SPEND,
    spendCumulative: d.ACTUAL_MEDIA_SPEND_CUMULATIVE,
    target: planned[idx]?.value || 0,
    unitCostIncurred: d.UNIT_COST_INCURRED || 0,
    unitCostIncurredCumulative: d.UNIT_COST_INCURRED_CUMULATIVE || 0
  }));
}

function generatePlatformBuyPlanned(
  displayMode: DisplayMode,
  chartType: 'spend' | 'delivery',
  lineItem: CombinedLineItem,
  mediaPlan: CombinedMediaPlan | undefined,
  granularity: Granularity
) {
  if (!mediaPlan || !lineItem.start_date || !lineItem.end_date) return [];

  const estimatedUnits = calcEstimatedUnits(lineItem);
  const lineItemBudget = calcMediaBudget(lineItem);

  const { start_date, end_date } = lineItem;

  if (!estimatedUnits || !lineItemBudget) return [];
  const mediaBuyBudgetPercentage = mediaPlan.budget / lineItemBudget;
  const numPeriods = getNumPeriods(start_date, end_date, granularity);

  const expectedUnits =
    (chartType === 'spend' ? lineItemBudget : estimatedUnits) * mediaBuyBudgetPercentage;

  const planned = Array.from({ length: numPeriods }, (_, i) => ({
    date: getDateForPeriod(start_date, i, granularity),
    value: getValueForPeriod(
      start_date,
      end_date,
      i,
      granularity,
      mediaBuyBudgetPercentage,
      expectedUnits,
      lineItem
    )
  }));

  if (displayMode === 'discrete') return planned;

  let sum = 0;
  return planned.map(d => ({ ...d, value: (sum += d.value) }));
}

export function getNumPeriods(utcStart: UTCDate, utcEnd: UTCDate, granularity: Granularity) {
  switch (granularity) {
    case 'day':
      return differenceInCalendarDays(utcEnd, utcStart) + 1;
    case 'week':
      return differenceInCalendarWeeks(utcEnd, utcStart) + 1;
    case 'month':
      return differenceInCalendarMonths(utcEnd, utcStart) + 1;
  }
}

function getDateForPeriod(utcStart: UTCDate, period: number, granularity: Granularity) {
  switch (granularity) {
    case 'day':
      return new UTCDate(addDays(utcStart, period));
    case 'week':
      return new UTCDate(addWeeks(startOfISOWeek(utcStart), period));
    case 'month':
      return new UTCDate(addMonths(startOfMonth(utcStart), period));
  }
}

export function daysInPeriod(
  utcStart: UTCDate,
  utcEnd: UTCDate,
  period: number,
  granularity: Granularity
) {
  let periodStart = getDateForPeriod(utcStart, period, granularity);
  let periodEnd = getDateForPeriod(utcStart, period + 1, granularity);
  //Don't start before the start of the period
  if (isBefore(periodStart, utcStart)) {
    periodStart = startOfDay(utcStart);
  }
  //Don't end after the end of the period
  if (isAfter(periodEnd, utcEnd)) {
    //Add 1 to utcEnd because utcEnd is inclusive and periodEnd should be exclusive
    periodEnd = new UTCDate(addDays(startOfDay(utcEnd), 1));
  }

  // Subtract 1 because the 'periodEnd' date is exclusive (actually start of next period)
  return diffInDays(periodStart, periodEnd) - 1;
}

export function getValueForPeriod(
  utcStart: UTCDate,
  utcEnd: UTCDate,
  period: number,
  granularity: Granularity,
  mediaBuyBudgetPercentage: number,
  expectedUnits: number,
  lineItem: CombinedLineItem
) {
  const periodStart = getDateForPeriod(utcStart, period, granularity);

  if (lineItem.pacing_type === 'custom' && lineItem.pacing_details) {
    // If the pacing type is custom, we want to use the pacing details to calculate the value.
    // Add up all the prices for blocks that overlap the period
    let price = 0;
    const periodEnd = addDays(getDateForPeriod(utcStart, period + 1, granularity), -1);
    lineItem.pacing_details.blocks.forEach(block => {
      const periodDaysInBlock = diffInDays(
        max([periodStart, block.start_date]),
        min([periodEnd, block.end_date])
      );
      if (periodDaysInBlock > 0) {
        const totalDaysInBlock = diffInDays(block.start_date, block.end_date);
        price += block.price * Math.min(periodDaysInBlock / totalDaysInBlock, 1);
      }
    });
    return price * mediaBuyBudgetPercentage;
  } else {
    const days = daysInPeriod(utcStart, utcEnd, period, granularity);
    const totalDays = diffInDays(utcStart, utcEnd);
    const unitsPerDay = expectedUnits / totalDays;
    return unitsPerDay * days;
  }
}

function accessorFn(chartType: 'spend' | 'delivery', displayMode: DisplayMode) {
  if (chartType === 'spend' && displayMode === 'discrete') {
    return (d: PlatformBuyMetric) => d.ACTUAL_MEDIA_SPEND;
  } else if (chartType === 'spend' && displayMode === 'cumulative') {
    return (d: PlatformBuyMetric) => d.ACTUAL_MEDIA_SPEND_CUMULATIVE;
  } else if (chartType === 'delivery' && displayMode === 'discrete') {
    return (d: PlatformBuyMetric) => d.ACTUAL_DELIVERED_UNITS;
  } else {
    return (d: PlatformBuyMetric) => d.ACTUAL_DELIVERED_UNITS_CUMULATIVE;
  }
}

export function generateColorMap(graphData: { id: string }[]) {
  const colors = graphData.map((d, i) => [d.id, schemeCategory10[i % schemeCategory10.length]]);
  return fromPairs(colors);
}
