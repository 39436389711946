import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Location, useLocation } from 'react-router-dom';

type PreviousLocationContextType = {
  previousLocation: string | null;
};

const PreviousLocationContext = createContext<PreviousLocationContextType>({
  previousLocation: null
});

type PreviousLocationProviderProps = {
  children: ReactNode;
};

export function PreviousLocationProvider({ children }: PreviousLocationProviderProps) {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState<string | null>(null);
  const [previousLocation, setPreviousLocation] = useState<string | null>(null);

  useEffect(() => {
    const locationKey = generateLocationKey(location);

    if (currentLocation === null) {
      setCurrentLocation(locationKey);
      return;
    }
    if (currentLocation !== locationKey) {
      setPreviousLocation(currentLocation);
      setCurrentLocation(locationKey);
    }
  }, [currentLocation, location]);

  return (
    <PreviousLocationContext.Provider value={{ previousLocation }}>
      {children}
    </PreviousLocationContext.Provider>
  );
}

function generateLocationKey(location: Location) {
  return `${location.pathname}${location.search}${location.hash}`;
}

export function usePreviousPage(): string | null {
  const { previousLocation } = useContext(PreviousLocationContext);
  return previousLocation;
}
