import React from 'react';
import { trpc } from '../../utils/trpc-client';
import { SyncButton } from '../../components/sync-button';

export function SyncMediaBuysButton() {
  const { mutateAsync, isPending } = trpc.syncMediaBuys.useMutation();

  async function refresh() {
    await mutateAsync();
  }

  return (
    <div className="mb-2 flex justify-center">
      <SyncButton title={'Sync Platform Buys'} isPending={isPending} refresh={refresh} />
    </div>
  );
}
