import React from 'react';
import clsx from 'clsx';

type Size = 'default' | 'fitGridCell' | 'text';
type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => unknown;
  className?: string;
  disabled?: boolean;
  size?: Size;
  type?: 'button' | 'submit' | 'reset';
};

export function Button(props: ButtonProps) {
  return (
    <BaseButton
      {...props}
      className={`bg-blue-600 font-medium text-white hover:bg-blue-700 focus:ring-sky-500 disabled:bg-gray-400 disabled:text-gray-200 ${props.className}`}
    />
  );
}

export function SecondaryButton(props: ButtonProps) {
  return (
    <BaseButton
      {...props}
      className={`border-blue-600 bg-white font-medium text-blue-600 hover:bg-blue-50 focus:ring-sky-500 disabled:text-gray-200 ${props.className}`}
    />
  );
}

export function AlertButton(props: ButtonProps) {
  return (
    <BaseButton
      {...props}
      className={`bg-red-600 font-medium text-white hover:bg-red-400 focus:ring-red-400 active:bg-red-800 disabled:bg-gray-400 disabled:text-gray-200 ${props.className}`}
    />
  );
}

export function TextButton(props: ButtonProps) {
  return <BaseButton {...props} className={`border-none ${props.className}`} size="text" />;
}

type BaseButtonProps = ButtonProps & { className?: string };

function BaseButton({
  children,
  disabled,
  onClick,
  className,
  size = 'default',
  type = 'button',
  ...rest
}: BaseButtonProps) {
  return (
    <button
      disabled={disabled}
      type={type}
      className={clsx(
        'inline-flex items-center rounded-md border text-sm focus:outline-none focus:ring-2 focus:ring-offset-2',
        getSizeStyles(size),
        className
      )}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
}

function getSizeStyles(size: Size) {
  switch (size) {
    case 'default':
      return 'px-4 py-2';
    case 'fitGridCell':
      return 'px-2 py-1';
    case 'text':
      return 'p-0';
  }
}
