import { DraftStrategyChanges, Strategy } from 'shared/src/types';
import { useStore } from './store';

export function removeInvalidItems(strategy: Strategy, changes: DraftStrategyChanges) {
  // Find line item changes that are marked as new, but already exist in the strategy
  const publishedLineItemIds = strategy.line_items.map(lineItem => lineItem.id);
  const invalidLineItemIds = Object.values(changes.line_items)
    .filter(lineItem => lineItem.type === 'new')
    .filter(lineItem => publishedLineItemIds.includes(lineItem.data.id))
    .map(lineItem => lineItem.data.id);

  // Find media plan changes that are marked as new, but already exist in the strategy
  const publishedMediaPlanIds = strategy.line_items
    .flatMap(lineItem => lineItem.media_plans)
    .flatMap(mediaPlan => mediaPlan.id);
  const invalidMediaPlanIds = Object.values(changes.media_plans)
    .filter(mediaPlan => mediaPlan.type === 'new')
    .filter(mediaPlan => publishedMediaPlanIds.includes(mediaPlan.data.id))
    .map(mediaPlan => mediaPlan.data.id);

  // If we found any invalid line items or media buys remove them from the store
  if (invalidLineItemIds.length > 0 || invalidMediaPlanIds.length > 0) {
    useStore.getState().removeEntities(strategy.id, {
      ...(invalidLineItemIds.length > 0 ? { lineItemIds: invalidLineItemIds } : {}),
      ...(invalidMediaPlanIds.length > 0 ? { mediaPlanIds: invalidMediaPlanIds } : {})
    });
  }
}
