import { NewValueParams } from '@ag-grid-community/core';
import { useStore } from '../../store/store';
import { MediaPlansTableData } from './media-plans-table';
import { CombinedLineItem, CombinedMediaPlan } from '../../store/strategy-combiner';
import { MediaPlanUpdate, PlatformBuy } from 'shared/src/media-buy-types';

type Update = Omit<MediaPlanUpdate, 'id' | 'line_item_id'>;

export function updateName(
  update: NewValueParams<MediaPlansTableData, string>,
  lineItem: CombinedLineItem
) {
  const { oldValue, newValue, data } = update;
  if (newValue !== oldValue) updateMediaPlan(data, { name: newValue || undefined }, lineItem);
}

export function updateBudget(
  update: NewValueParams<MediaPlansTableData, number>,
  lineItem: CombinedLineItem
) {
  const { oldValue, newValue, data } = update;
  updateMediaPlan(data, { budget: newValue && newValue !== oldValue ? newValue : 0 }, lineItem);
}

export function updateTargetUnitCost(
  update: NewValueParams<MediaPlansTableData, number>,
  lineItem: CombinedLineItem
) {
  const { oldValue, newValue, data } = update;
  updateMediaPlan(
    data,
    { target_unit_cost: newValue && newValue !== oldValue ? newValue : 0 },
    lineItem
  );
}

export function linkPlatformBuy(
  data: CombinedMediaPlan,
  platformBuy: PlatformBuy,
  lineItem: CombinedLineItem
) {
  updateMediaPlan(data, { media_buy: platformBuy }, lineItem);
}

export function unlinkPlatformBuy(data: CombinedMediaPlan, lineItem: CombinedLineItem) {
  if (data.media_buy) updateMediaPlan(data, { media_buy: null }, lineItem);
}

export function setIsDeleted(data: CombinedMediaPlan, lineItem: CombinedLineItem) {
  useStore.getState().removeEntities(lineItem.strategy_id, { mediaPlanIds: [data.id] });
  if (data.state.type !== 'new') {
    updateMediaPlan(data, { is_deleted: true, media_buy: null }, lineItem);
  }
}

function updateMediaPlan(data: CombinedMediaPlan, update: Update, lineItem: CombinedLineItem) {
  const { id, state } = data;
  useStore
    .getState()
    .updateMediaPlan(
      { id, line_item_id: lineItem.id, ...update },
      lineItem,
      state.type === 'update' ? state.original : undefined
    );
}
