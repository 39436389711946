import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { CreateMediaPlanOptions, DraftStrategyChanges } from 'shared/src/types';
import { immer } from 'zustand/middleware/immer';
import {
  addLineItem,
  addMediaPlan,
  duplicateLineItems,
  removeEntities,
  resetChanges,
  updateLineItem,
  updateMediaPlan
} from './state-fns';
import { LineItem, PartialLineItem } from 'shared/src/line-item-types';
import { CombinedLineItem } from './strategy-combiner';
import { createTestStorage, isTestMode } from './test-storage';
import { MediaPlan, MediaPlanUpdate } from 'shared/src/media-buy-types';

type LineItemPacingState = {
  open: boolean;
  lineItemId: string;
};

export type State = {
  campaigns: Record<string, DraftStrategyChanges>;
  lineItemPacing: LineItemPacingState | null;
  highlightedGraph: string | null;
};

export type ToRemove = {
  mediaPlanIds?: string[];
  lineItemIds?: string[];
};

export type Actions = {
  addLineItem: (lineItem: PartialLineItem) => unknown;
  updateLineItem: (update: PartialLineItem, original?: LineItem) => unknown;
  duplicateLineItems: (lineItems: CombinedLineItem[], idMapping: Record<string, string>) => unknown;
  resetChanges: (strategyId: string) => unknown;
  addMediaPlan: (options: CreateMediaPlanOptions) => unknown;
  updateMediaPlan: (
    update: MediaPlanUpdate,
    lineItem: CombinedLineItem,
    original?: MediaPlan
  ) => unknown;
  removeEntities: (strategyId: string, toRemove: ToRemove) => unknown;
  setLineItemPacing: (lineItemPacing: LineItemPacingState | null) => unknown;
  setHighlightedGraph: (graphId: string | null | undefined) => unknown;
};

export type StoreState = State & Actions;

export const useStore = create<
  StoreState,
  [['zustand/persist', never], ['zustand/devtools', never], ['zustand/immer', never]]
>(
  persist(
    devtools(
      immer((set, get) => ({
        campaigns: {},
        lineItemPacing: null,
        highlightedGraph: null,
        addLineItem: lineItem => set(state => void addLineItem(state, lineItem)),
        updateLineItem: (update, original) =>
          set(state => void updateLineItem(state, update, original)),
        duplicateLineItems: (lineItems: CombinedLineItem[], idMapping: Record<string, string>) =>
          set(state => void duplicateLineItems(state, lineItems, idMapping)),
        addMediaPlan: (options: CreateMediaPlanOptions) =>
          set(state => addMediaPlan(state, options)),
        updateMediaPlan: (update, lineItem, original) =>
          set(state => void updateMediaPlan(state, update, lineItem, original)),
        resetChanges: (strategyId: string) => set(state => void resetChanges(state, strategyId)),
        removeEntities: (strategyId, toRemove) =>
          set(state => void removeEntities(state, strategyId, toRemove)),
        setLineItemPacing: (lineItemPacing: LineItemPacingState | null) =>
          set(state => void (state.lineItemPacing = lineItemPacing)),
        setHighlightedGraph: (highlightedGraph: string | null | undefined) =>
          set(state => void (state.highlightedGraph = highlightedGraph || null))
      }))
    ),
    {
      name: 'media-tool-pending',
      version: 2,
      storage: createJSONStorage(() => (isTestMode() ? createTestStorage() : localStorage))
    }
  )
);
