import React from 'react';
import { CustomCellRendererProps, CustomHeaderProps } from '@ag-grid-community/react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { formatMetricPercentage } from '../../components/hero-metrics';
import { formatCurrency } from '../../components/table-utils';
import { getPerformanceBackground, getPerformanceColor } from '../../utils/color-utils';
import { CalculatedValue } from '../line-items/line-item-table-cell-components';
import { MediaPlansTableData } from './media-plans-table';
import { getPlatformEntityType, getPlatformName } from 'shared/src/media-platforms';
import { Button } from '../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { CombinedMediaPlan } from '../../store/strategy-combiner';
import { equalToTwoPlaces } from 'shared/src/number-utils';

export type CellRendererProps<T> = CustomCellRendererProps<MediaPlansTableData, T>;
type HeaderRendererProps<T> = CustomHeaderProps<MediaPlansTableData, T>;

export function HeaderComponent({ displayName }: HeaderRendererProps<string>) {
  return <div className="capitalize">{displayName}</div>;
}

export function ViewButtonCellRenderer({
  colorMap,
  data
}: CellRendererProps<string> & { colorMap: Record<string, string> }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (!data || data.isTotal || !data.media_buy?.id) return null;

  const color = colorMap[data.media_buy.id];

  // TODO[mk] - this link is not correct, need to decide what a media plan page looks like
  return (
    <div className="flex h-full items-center">
      <div className="mr-1 h-[24px] w-[6px] rounded-full" style={{ background: color }} />
      <button
        className="mr-2 flex h-[28px] flex-1 items-center justify-center rounded border border-[#3B6FEC] px-2 text-sm text-[#3B6FEC]"
        onClick={() => navigate(`${pathname}/../mediaplans/${data.id}/overview`)}>
        View
      </button>
    </div>
  );
}

export function PlatformEntityRenderer({
  data,
  value
}: CustomCellRendererProps<MediaPlansTableData>) {
  return data?.isTotal ? (
    <TotalsRowCell
      value={data.numPlatforms.toLocaleString()}
      unit={`Platform${data.numPlatforms !== 1 ? 's' : ''}`}
    />
  ) : (
    `${getPlatformName(value)} ${getPlatformEntityType(value)}`
  );
}

export function MetricCellRenderer(props: CellRendererProps<number>) {
  const { node, value, column, data } = props;

  if (value == null || column == null || (!node.group && data == null)) return '--';

  const colorValue =
    column.getColId() === 'margin'
      ? node.group
        ? node.aggData.marginPerformance
        : data?.marginPerformance
      : value;
  const background = getPerformanceBackground(colorValue);
  const color = getPerformanceColor(colorValue);

  return (
    <div className={clsx('h-full p-1')}>
      <div
        className={clsx(
          'flex h-full w-full items-center justify-center overflow-x-clip rounded  px-2',
          node.group && 'font-semibold'
        )}
        style={{ background, color }}>
        {formatMetricPercentage(value)}
      </div>
    </div>
  );
}

export function MediaPlanBudgetRenderer({
  data,
  valueFormatted,
  lineItemBudget,
  value
}: CellRendererProps<number> & { lineItemBudget: number }) {
  if (data?.isTotal) {
    return (
      <TotalsRowCell
        value={valueFormatted}
        isAlarm={(value || 0).toFixed(2) !== lineItemBudget.toFixed(2)}
      />
    );
  }

  return valueFormatted;
}

type TargetUnitCostRendererProps = CellRendererProps<number> & {
  lineItemTarget?: number;
};

export function TargetUnitCostRenderer(props: TargetUnitCostRendererProps) {
  const { data, value, lineItemTarget } = props;
  if (value == null) return '--';
  const adjustedValue = data?.unit_price_type?.name === 'CPM' ? value * 1000 : value;
  const valueFormatted = formatCurrency(adjustedValue);
  return data?.isTotal ? (
    <TotalsRowCell
      value={valueFormatted}
      isAlarm={!equalToTwoPlaces(adjustedValue, lineItemTarget)}
    />
  ) : (
    valueFormatted
  );
}

type TargetMarginRendererProps = CellRendererProps<number> & {
  lineItemTargetMargin?: number;
};

export function MediaPlanTargetMarginRenderer(props: TargetMarginRendererProps) {
  const { data, value, valueFormatted, lineItemTargetMargin } = props;
  return data?.isTotal ? (
    <TotalsRowCell
      value={valueFormatted}
      isAlarm={!equalToTwoPlaces(value, lineItemTargetMargin)}
    />
  ) : (
    <CalculatedValue disabled={false} value={valueFormatted} />
  );
}

type TargetUnitsRendererProps = CellRendererProps<number> & {
  lineItemTargetUnits?: number;
};

export function TargetUnitsRenderer(params: TargetUnitsRendererProps) {
  const { data, valueFormatted, value, lineItemTargetUnits } = params;
  if (data?.isTotal) {
    return (
      <TotalsRowCell
        value={valueFormatted}
        isAlarm={!equalToTwoPlaces(value, lineItemTargetUnits)}
      />
    );
  }

  return <CalculatedValue disabled={false} value={valueFormatted} />;
}

export function DeliveredUnitsRenderer({ node, valueFormatted }: CellRendererProps<string>) {
  if (node.group) {
    return <TotalsRowCell value={valueFormatted} />;
  }

  return valueFormatted;
}

export function MediaSpendRenderer({ node, value }: CellRendererProps<number>) {
  if (node.group) {
    return <TotalsRowCell value={formatCurrency(value || 0)} />;
  }

  return formatCurrency(value || 0);
}

export function PlatformBuyNameRenderer({
  data,
  onAddClick,
  value
}: CellRendererProps<string> & { onAddClick: (plan: CombinedMediaPlan) => void }) {
  if (!data || data.isTotal) return null;

  if (value) {
    const lastPipeIdx = value.lastIndexOf('|');
    const firstPart = value.substring(0, lastPipeIdx);
    const lastPart = value.substring(lastPipeIdx);

    return (
      <div className="flex h-full w-full items-center py-1">
        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">{firstPart}</div>
        <div>{lastPart}</div>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full items-center py-2">
      <Button size="fitGridCell" onClick={() => onAddClick(data)} className="gap-2">
        <FontAwesomeIcon icon={faPlusCircle} />
        Add Platform Buy
      </Button>
    </div>
  );
}

type TotalsRowCellProps = {
  value: string | null | undefined;
  unit?: string;
  isAlarm?: boolean;
};

export function TotalsRowCell({ value, unit, isAlarm }: TotalsRowCellProps) {
  return (
    <div className={clsx(isAlarm && 'text-red-500')}>
      <span className="font-bold">{value || '--'}</span>
      {unit && <span>{` ${unit}`}</span>}
    </div>
  );
}
