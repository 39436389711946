import { formatDate } from 'shared/src/date-utils';
import { formatMoney } from 'shared/src/money-utils';
import { differenceInDays, format, isAfter } from 'date-fns';
import React, { ReactNode } from 'react';
import { CombinedLineItem, CombinedMediaPlan } from '../../store/strategy-combiner';
import { PacingSchedule } from 'shared/src/line-item-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faLinkSlash, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { calcAdjustedTargetUnitCost } from '../media-buys/platform-buy-utils';
import clsx from 'clsx';
import { PlatformBuy } from 'shared/src/media-buy-types';

export type MoneyDiff = {
  label: string;
  oldValue: number;
  newValue: number | undefined;
};

export type NumberDiff = {
  label: string;
  oldValue: number;
  newValue: number | undefined;
};

export type DateDiff = {
  label: string;
  oldValue: Date;
  newValue: Date | undefined;
};

export type PacingDetailsDiff = {
  oldValue: PacingSchedule | null;
  newValue: PacingSchedule | null | undefined;
};

export function LineItemDiffs({ lineItem }: { lineItem: CombinedLineItem }) {
  if (lineItem.state.type !== 'update') return null;
  const { dirty } = lineItem.state;

  return (
    <>
      {dirty.is_deleted && dirty.is_deleted.new === true && <DeletedChange />}
      {dirty.name && (
        <StringChange label="Name" change={{ old: dirty.name.old, new: dirty.name.new }} />
      )}
      {dirty.price && (
        <MoneyChange label="Price" oldValue={dirty.price.old} newValue={dirty.price.new} />
      )}
      {dirty.channel && (
        <StringChange
          label="Channel"
          change={{ old: dirty.channel.old.name, new: dirty.channel.new?.name }}
        />
      )}
      {dirty.tactic && (
        <StringChange
          label="Tactic"
          change={{ old: dirty.tactic.old.name, new: dirty.tactic.new?.name }}
        />
      )}
      {dirty.unit_price_type && (
        <StringChange
          label="Unit Price Type"
          change={{ old: dirty.unit_price_type.old.name, new: dirty.unit_price_type.new?.name }}
        />
      )}
      {dirty.geo && (
        <StringChange label="Geo" change={{ old: dirty.geo.old, new: dirty.geo.new }} />
      )}
      {dirty.targeting && (
        <StringChange
          label="Targeting"
          change={{ old: dirty.targeting.old, new: dirty.targeting.new }}
        />
      )}
      {dirty.start_date && (
        <DateChange
          label="Start Date"
          oldValue={dirty.start_date.old}
          newValue={dirty.start_date.new}
        />
      )}
      {dirty.end_date && (
        <DateChange label="End Date" oldValue={dirty.end_date.old} newValue={dirty.end_date.new} />
      )}
      {dirty.unit_price && (
        <MoneyChange
          label="Unit Price"
          oldValue={dirty.unit_price.old}
          newValue={dirty.unit_price.new}
        />
      )}
      {dirty.target_margin && (
        <NumberChangeCmp
          label="Target Margin"
          oldValue={dirty.target_margin.old}
          newValue={dirty.target_margin.new}
        />
      )}
      {dirty.audience && (
        <StringChange
          label="Audience"
          change={{ old: dirty.audience.old, new: dirty.audience.new }}
        />
      )}
      {dirty.ad_formats && (
        <StringChange
          label="Ad Formats"
          change={{ old: dirty.ad_formats.old, new: dirty.ad_formats.new }}
        />
      )}
      {dirty.pacing_type && (
        <StringChange
          label="Pacing Type"
          change={{ old: dirty.pacing_type.old, new: dirty.pacing_type.new }}
        />
      )}
      {dirty.pacing_details && (
        <PacingDetailsCmp oldValue={dirty.pacing_details.old} newValue={dirty.pacing_details.new} />
      )}
      {dirty.media_traders && (
        <StringChange
          label={'Media Traders'}
          change={{
            old: dirty.media_traders.old.map(u => u.name).join(','),
            new: dirty.media_traders.new?.map(u => u.name).join(',')
          }}
        />
      )}
      {dirty.media_platforms && (
        <StringChange
          label="Media Platforms"
          change={{
            old: dirty.media_platforms.old.map(p => p.name).join(','),
            new: dirty.media_platforms.new?.map(p => p.name).join(',')
          }}
        />
      )}
    </>
  );
}

type MediaPlanUpdateDiffProps = {
  mediaPlan: CombinedMediaPlan;
  lineItem: CombinedLineItem;
};

export function MediaPlanDiffs({ mediaPlan, lineItem }: MediaPlanUpdateDiffProps) {
  if (mediaPlan.state.type !== 'update') return null;
  const { dirty } = mediaPlan.state;

  return (
    <ChangeContainer>
      {dirty.name && (
        <StringChangeV2 label="Name" change={{ old: dirty.name.old, new: dirty.name.new }} />
      )}
      {dirty.budget && (
        <MoneyChangeV2 label="Budget" oldValue={dirty.budget.old} newValue={dirty.budget.new} />
      )}
      {dirty.target_unit_cost && (
        <MoneyChangeV2
          label="Target Unit Cost"
          oldValue={calcAdjustedTargetUnitCost(
            lineItem.unit_price_type?.name,
            dirty.target_unit_cost?.old
          )}
          newValue={calcAdjustedTargetUnitCost(
            lineItem.unit_price_type?.name,
            dirty.target_unit_cost?.new || 0
          )}
        />
      )}
      {dirty.media_buy && <PlatformBuyChange platformBuy={dirty.media_buy} />}
    </ChangeContainer>
  );
}

export function ChangeContainer({ children }: { children: ReactNode }) {
  return <div className="flex flex-col gap-0.5">{children}</div>;
}

type PlatformBuyChangeProps = {
  platformBuy: { old: PlatformBuy | undefined | null; new: PlatformBuy | undefined | null };
};

export function PlatformBuyChange({ platformBuy }: PlatformBuyChangeProps) {
  return (
    <>
      {platformBuy.old && <UpdatedPlatformBuy name={platformBuy.old.name} unlinked />}
      {platformBuy.new && <UpdatedPlatformBuy name={platformBuy.new.name} />}
    </>
  );
}

export function NewPlatformBuy({ name }: { name: string }) {
  return (
    <div className="flex flex-col gap-0.5 rounded bg-blue-50 py-1 pl-4 pr-1 text-sm">
      <div className="flex items-center gap-2 font-medium">
        Platform Buy linked
        <LinkIcon />
      </div>
      <FieldValue type={'new'}>
        <div className="overflow-scroll text-nowrap text-gray-600">{name}</div>
      </FieldValue>
    </div>
  );
}

export function UpdatedPlatformBuy({
  name,
  unlinked = false
}: {
  name: string;
  unlinked?: boolean;
}) {
  return (
    <div className="flex flex-col gap-0.5 rounded bg-orange-50 py-1 pl-4 pr-1 text-sm">
      <div className="flex items-center gap-2 font-medium">
        Platform Buy {unlinked ? 'unlinked' : 'linked'}
        <FontAwesomeIcon icon={unlinked ? faLinkSlash : faLink} />
      </div>
      <FieldValue type={'update'}>
        <div
          className={clsx(
            'overflow-scroll text-nowrap text-gray-600',
            unlinked ? 'line-through' : ''
          )}>
          {name}
        </div>
      </FieldValue>
    </div>
  );
}

export function FieldContainer({ children }: { children: ReactNode }) {
  return <div className="flex items-center justify-between pl-4 text-sm">{children}</div>;
}

export function FieldLabel({ children }: { children: ReactNode }) {
  return <div className="basis-32 font-medium">{children}</div>;
}

export function FieldValue({ type, children }: { type: 'new' | 'update'; children: ReactNode }) {
  return (
    <div
      className={clsx(
        'flex grow items-center rounded px-2 py-1.5 font-light',
        type === 'update' ? 'bg-orange-100' : 'bg-blue-100'
      )}>
      {children}
    </div>
  );
}

function StringChangeV2({ label, change }: StringChangeProps) {
  return (
    <FieldContainer>
      <FieldLabel>{label}</FieldLabel>
      <FieldValue type="update">
        <div className="w-1/2 text-[#6B7280] line-through">{change.old || 'Empty'}</div>
        <div className="flex w-1/2 items-center">
          <div className="mr-2">→</div>
          <div>{change.new || ''}</div>
        </div>
      </FieldValue>
    </FieldContainer>
  );
}

function MoneyChangeV2({ oldValue, newValue, label }: MoneyDiff) {
  if (newValue == null) return null;
  return (
    <FieldContainer>
      <FieldLabel>{label}</FieldLabel>
      <FieldValue type="update">
        <div className="w-1/2 text-[#6B7280] line-through">{formatMoney(oldValue)}</div>
        <div className="flex w-1/2 items-center">
          <div className="mr-2">→</div>
          <div>{formatMoney(newValue)}</div>
        </div>
      </FieldValue>
    </FieldContainer>
  );
}

function LinkIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon" clipPath="url(#clip0_9989_15778)">
        <path
          id="Vector"
          d="M14.7242 10.5749L12.6992 8.5499C11.6742 7.5249 10.0742 7.4249 8.92422 8.2499L7.72422 7.0499C8.07422 6.5749 8.27422 5.9999 8.27422 5.3749C8.27422 4.5999 7.97422 3.8499 7.42422 3.2999L5.39922 1.2749C4.27422 0.149902 2.42422 0.149902 1.27422 1.2749C0.749219 1.8499 0.449219 2.5749 0.449219 3.3499C0.449219 4.1249 0.749219 4.8499 1.29922 5.3999L3.29922 7.4249C3.87422 7.9999 4.62422 8.2749 5.34922 8.2749C5.89922 8.2749 6.42422 8.1249 6.89922 7.8249L8.14922 9.0749C7.84922 9.5249 7.69922 10.0749 7.69922 10.6249C7.69922 11.3999 7.99922 12.1249 8.54922 12.6749L10.5492 14.6999C11.1242 15.2749 11.8742 15.5499 12.5992 15.5499C13.3492 15.5499 14.0992 15.2749 14.6492 14.6999C15.1992 14.1499 15.4992 13.4249 15.4992 12.6499C15.5742 11.8749 15.2742 11.1499 14.7242 10.5749ZM4.09922 6.6249L2.09922 4.5999C1.74922 4.2499 1.57422 3.7999 1.57422 3.3249C1.57422 2.8499 1.74922 2.3999 2.09922 2.0499C2.44922 1.6999 2.89922 1.5249 3.37422 1.5249C3.82422 1.5249 4.29922 1.6999 4.64922 2.0499L6.67422 4.0499C6.99922 4.3999 7.19922 4.8499 7.19922 5.3249C7.19922 5.6499 7.12422 5.9249 6.97422 6.1999L6.34922 5.5749C6.12422 5.3499 5.77422 5.3499 5.54922 5.5749C5.32422 5.7999 5.32422 6.1499 5.54922 6.3749L6.12422 6.9499C5.42422 7.2999 4.62422 7.1749 4.09922 6.6249ZM13.8992 13.8999C13.1992 14.5999 12.0492 14.5999 11.3742 13.8999L9.37422 11.8749C9.02422 11.5249 8.84922 11.0749 8.84922 10.5999C8.84922 10.3499 8.89922 10.0999 8.99922 9.8749L9.57422 10.4249C9.67422 10.5249 9.82422 10.5999 9.97422 10.5999C10.1242 10.5999 10.2742 10.5499 10.3742 10.4249C10.5992 10.1999 10.5992 9.8499 10.3742 9.6249L9.74922 9.0749C10.0242 8.9249 10.3242 8.8499 10.6242 8.8499C11.0742 8.8499 11.5492 9.0249 11.8992 9.3749L13.9242 11.3749C14.2492 11.7249 14.4492 12.1749 14.4492 12.6499C14.4242 13.1249 14.2492 13.5749 13.8992 13.8999Z"
          fill="#111928"
        />
      </g>
      <defs>
        <clipPath id="clip0_9989_15778">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MediaPlanDeleted({ mediaPlan, lineItem }: MediaPlanUpdateDiffProps) {
  if (mediaPlan.state.type !== 'update' && !mediaPlan.is_deleted) return null;

  return (
    <div className="flex flex-col font-[#374151] text-sm">
      <div className="mb-2  flex rounded bg-[#FFEDD5] p-2">
        <div className="mr-2">Media Plan Deleted</div>
        <div>
          <FontAwesomeIcon icon={faTrashCan} />
        </div>
      </div>
      <DeletedProperty label="Name" value={mediaPlan.name || 'Unnamed Plan'} />
      <DeletedProperty label="Plan Budget" value={formatMoney(mediaPlan.budget)} />
      <DeletedProperty
        label="Plan Unit Cost"
        value={formatMoney(
          calcAdjustedTargetUnitCost(
            lineItem.unit_price_type?.name,
            mediaPlan.target_unit_cost || 0
          )
        )}
      />
      {mediaPlan.state.type === 'update' && mediaPlan.state.dirty.media_buy?.old && (
        <PlatformBuyChange platformBuy={mediaPlan.state.dirty.media_buy} />
      )}
    </div>
  );
}

function DeletedProperty({ label, value }: { label: string; value: string | number }) {
  return (
    <div className="mb-1 flex items-center">
      <div className="w-48 pl-2">{label}</div>
      <div className="flex-1 rounded bg-gray-200 px-2 py-2 pr-2 line-through">{value}</div>
    </div>
  );
}

function DeletedChange() {
  return (
    <div className="flex items-center">
      <FontAwesomeIcon icon={faTrashCan} className="mr-2 text-red-500" />
      <div className="text-red-500">Deleted</div>
    </div>
  );
}

function MoneyChange({ oldValue, newValue, label }: MoneyDiff) {
  if (newValue == null) return null;
  const isBigger = newValue > oldValue;
  return (
    <div className="mb-1 ml-2 flex items-center font-light">
      <div>
        {isBigger ? 'Increased ' : 'Decreased '}
        {label}
      </div>
      <div className={`${isBigger ? 'text-green-700' : 'text-red-500'} ml-2 text-sm`}>
        {isBigger ? '+' : '-'}
        {formatMoney(Math.abs((newValue || 0) - oldValue))}
      </div>
      <div className="flex-1" />
      <div>
        <span className="text-gray-400">{formatMoney(oldValue)}</span> →{' '}
        <span className="text-gray-700">{formatMoney(newValue)}</span>
      </div>
    </div>
  );
}

function DateChange({ label, oldValue, newValue }: DateDiff) {
  if (newValue == null) return null;
  const delayed = isAfter(newValue, oldValue);
  const difference = differenceInDays(oldValue, newValue);
  return (
    <div className="mb-1 ml-2 flex items-center font-light">
      <div>
        {delayed ? 'Delayed' : 'Moved up'} {label}
      </div>
      <div className={`${!delayed ? 'text-green-700' : 'text-red-500'} ml-2 text-sm`}>
        {!delayed && '+'}
        {difference} days
      </div>
      <div className="flex-1" />
      <div>
        <span className="text-gray-400">{format(oldValue, 'MMM do yyyy')}</span> →{' '}
        <span className="text-gray-700">{format(newValue, 'MMM do yyyy')}</span>
      </div>
    </div>
  );
}

type StringChangeProps = {
  label: string | null;
  change: { old: string | null; new: string | null | undefined };
};

function StringChange({ label, change }: StringChangeProps) {
  return (
    <div>
      <div>{label}</div>
      <div>
        <span className="text-gray-400">{change.old || ''}</span> →{' '}
        <span className="text-gray-700">{change.new || ''}</span>
      </div>
    </div>
  );
}

function NumberChangeCmp({ label, oldValue, newValue }: NumberDiff) {
  return (
    <div className="mb-1 ml-2 flex font-light">
      <div className="mr-2">{label}:</div>
      <div>
        <span className="text-gray-400">{oldValue}</span> →{' '}
        <span className="text-gray-700">{newValue}</span>
      </div>
    </div>
  );
}

function PacingDetailsCmp({ oldValue, newValue }: PacingDetailsDiff) {
  return (
    <div>
      <div>Pacing Details</div>
      <div className="flex text-sm">
        {oldValue && (
          <div className="flex flex-col text-gray-400">
            {oldValue.blocks.map((block, idx) => (
              <div key={idx} className="flex">
                <div className="mr-2">
                  {formatDate(block.start_date)} to {formatDate(block.end_date)}:
                </div>
                <div>{formatMoney(block.price)}</div>
              </div>
            ))}
          </div>
        )}
        {newValue && oldValue && <div className="flex flex-1 items-center justify-center">→</div>}
        {newValue && (
          <div className="flex flex-col text-gray-700">
            {newValue.blocks.map((block, idx) => (
              <div key={idx} className="flex">
                <div className="mr-2">
                  {formatDate(block.start_date)} to {formatDate(block.end_date)}:
                </div>
                <div>{formatMoney(block.price)}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
